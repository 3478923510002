import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import RegisterForm from '../../form/register-form'
import PublicHalfLayout from '../../layout/public-half-layout'
import DigiModalContext from '../../context/digi-modal-context'

import Loading from '../../component/loading.js'

import { requestSignUp, requestSignUpAcknowledgments } from '../../actions/user'

import Modal from 'react-modal'
import ModalLogo from '../../component/modal-logo'
import { modalStyle } from '../../utils/modal-style'

import sanitizeHtml from 'sanitize-html'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../../conf/constants'

class Register extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      submited: false,
      email: '',
    }

    this.handleRegister = this.handleRegister.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    this.props.requestSignUpAcknowledgments().then(() => {
      this.props.requestSignUpAcknowledgments()
    })
  }

  toggleModal(description = '') {
    this.setState({
      modal: !this.state.modal,
      description: description,
    })
  }

  handleRegister(values) {
    this.setState({
      submitting: true,
    })
    this.props
      .requestSignUp(values)
      .then((data) => {
        if (data.validation) {
          this.setState({
            submited: true,
            submitting: false,
            email: values.username,
          })
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        })
      })
  }

  render() {
    return (
      <PublicHalfLayout>
        <Modal
          appElement={document.getElementById('root')}
          style={modalStyle}
          isOpen={this.state.modal}
        >
          <ModalLogo />
          <button className="button-close" onClick={this.toggleModal}>
            zavřít
          </button>

          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(this.state.description, {
                allowedTags: ALLOWED_TAGS,
                allowedAttributes: ALLOWED_ATTRIBUTES,
              }),
            }}
          />
        </Modal>
        {this.state.submitting && <Loading />}
        {!this.state.submited && (
          <RegisterForm
            content={this.props.content}
            onSubmit={this.handleRegister}
            acknowledgments={this.props.acknowledgments}
            submitting={this.state.submitting}
            toggleModal={this.toggleModal}
          />
        )}
        {this.state.submited && (
          <div className={'modal__body'}>
            <div className={'modal__title'}>Děkujeme</div>
            <p className={'modal__subtitle modal__subtitle--blue'}>
              Na e-mail "{this.state.email}" jsme vám zaslali přihlašovací údaje
              a potvrzení o registraci. Prosím autorizujte svou e-mailovou adresu
              kliknutím na odkaz v e-mailové zprávě.
            </p>
            <p>
              Pokud nemůžete e-mail s potvrzením najít, zkontrolujte prosím
              složku nevyžádané pošty (Spam, Junk).
            </p>
            <p>
              Služby Telly si můžete objednat po ověření e-mailové adresy a po
              přihlášení do portálu.
            </p>

            <div className={'modal__actions'}>
              <Link to={'/'} className="btn btn--primary">
                Pokračovat na přihlášení
              </Link>
            </div>
          </div>
        )}
      </PublicHalfLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.translation.content,
    acknowledgments: state.user.acknowledgments,
  }
}

export default connect(mapStateToProps, {
  requestSignUp,
  requestSignUpAcknowledgments,
})(Register)
