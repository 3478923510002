import React from 'react'
import { Link } from 'react-router-dom'
import Countdown from './countdown'

export default function Reward(props) {
  const {
    reward,
    selected,
    disabled,
    link,
    externalLink = false,
    image,
    description,
    bigLayoutSize,
    buttonText = undefined,
  } = props
  const isSelected = selected === reward.id
  const isDisabled = selected !== null && disabled

  return (
    <div
      className={`reward${isSelected ? ' selected' : ''}${
        isDisabled ? ' disabled' : ''
      }
      `}
    >
      <img src={image} alt="Obrázek kolekce" />
      <div className={`${bigLayoutSize ? 'reward__big' : ''}`}>
        <h2>{reward.title}</h2>
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        )}
      </div>
      {bigLayoutSize && (
        <div className="reward__countdown">
          <Countdown
            subtitle="Zbývá už jen"
            date={reward.endDate}
            dateFormat="YYYY-MM-DD"
          />
        </div>
      )}
      {link !== undefined && externalLink === false && (
        <Link
          to={link}
          className={`btn${
            reward.options.length ? ' btn--reward' : ' btn--primary'
          }`}
        >
          {reward.options.length
            ? 'Vyberte si odměnu'
            : buttonText !== undefined
            ? buttonText
            : 'Zjistit více'}
        </Link>
      )}
      {link !== undefined && externalLink === true && (
        <a
          href={link}
          className={`btn${
            reward.options.length ? ' btn--reward' : ' btn--primary'
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {reward.options.length
            ? 'Vyberte si odměnu'
            : buttonText !== undefined
            ? buttonText
            : 'Zjistit více'}
        </a>
      )}
      {link === undefined && (
        <button
          className={`btn${
            reward.options.length ? ' btn--reward' : ' btn--primary'
          }`}
        >
          {reward.options.length
            ? 'Vyberte si odměnu'
            : buttonText !== undefined
            ? buttonText
            : 'Zjistit více'}
        </button>
      )}
    </div>
  )
}
