import React, { useState } from 'react'
import Loading from '../../component/loading'
//import { requestPin } from '../../actions/app';

const RemoveContactModal = ({ close, confirmDelete }) => {
  const [loading, setLoading] = useState(false)

  return (
    <div className="intro__text">
      {loading && <Loading />}

      <React.Fragment>
        <div className="row">
          <div className="col">
            <p>Opravdu si přejete odstranit tento kontakt?</p>
          </div>
        </div>
        <div className="row">
          <div className="col text--center">
            <button onClick={close} className="btn btn--secondary btn--prev">
              Ne
            </button>{' '}
            <button
              //onClick={confirmDelete}
              onClick={() => {
                setLoading(true)
                confirmDelete().catch((response) => {
                  setLoading(false)
                  return Promise.reject(response)
                })
              }}
              className="btn btn--primary btn--next"
            >
              Ano
            </button>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

export default RemoveContactModal

/*
export default reduxForm({
    form: 'form_profile_contact_remove',
    touchOnBlur: false
})(RemoveContactModal);
*/

//export default RemoveContactModal;
