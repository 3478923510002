import React, { Component } from 'react'
import Countdown from 'react-countdown';

import ButtonMoreInfo from '../button-more-info'
import { formatCurrency } from '../../utils/functions'
import { utcToZonedTime } from 'date-fns-tz'

class PackageSuppl extends Component {
  render() {
    const { item, toggleModal, channels, isPpv, content, children } = this.props

    const countDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return '';
      } else {
        return <span><strong>{days}</strong> dní <strong>{hours}</strong> hodin <strong>{minutes}</strong> minut <strong>{seconds}</strong> sekund</span>;
      }
    };
    const reg = /<img.*?src=["|'](.*?)["|']/gm;
    const ppvBackgroundImage = content ? reg.exec(content[`content.ppv.${item.group}.background`]) : null;

    return (
      <div
        className={`
        supplementary-packages__item
        ${item.active ? 'supplementary-packages__item--disabled' : ''}
        ${item.selected ? 'supplementary-packages__item--selected' : ''}
        ${isPpv ? 'supplementary-packages__item--is-ppv' : ''}
        `}
        style={isPpv && ppvBackgroundImage && { backgroundImage: `url(${ppvBackgroundImage[1]})` }}
      >
        <div className={'supplementary-packages__item__inner'}>
          <label className={'checkbox'}>
            {children}
            <span className={'supplementary-packages__item__name'}>
              {isPpv && item.event ? item.event.name : item.name}
            </span>
          </label>
          <div className={'supplementary-packages__item__info'}>
            {isPpv && item.event &&
              <div>{item.event.description}</div>
            }
            <ButtonMoreInfo
              toggleModal={toggleModal}
              description={item.description}
            />
          </div>
          {channels && (
            <div
              className="supplementary-packages__channels"
              dangerouslySetInnerHTML={{ __html: channels }}
            />
          )}
        </div>
        <div className="supplementary-packages__item__price-wrapper">
          <div className={'supplementary-packages__item__price__outer'}>
            {item.active && (
              <div className={'supplementary-packages__item__price'}>Aktivní</div>
            )}
            {!item.active && (
              <>
                <div
                  className={`supplementary-packages__item__price ${
                    item.discountVat > 0 &&
                    'supplementary-packages__item__price--discounted'
                  }`}
                >
                  +&nbsp;
                  {formatCurrency(item.priceOriginalVat, 0)} / {isPpv ? 'turnaj' : 'měsíc'}
                </div>
                {item.discountVat > 0 && (
                  <div className={'supplementary-packages__item__discount'}>
                  <span>
                    +&nbsp;
                    {formatCurrency(item.priceVat, 0)}
                  </span>
                  </div>
                )}
              </>
            )}
          </div>
          {isPpv && !item.active &&
            <div >
              <Countdown
                date={utcToZonedTime(new Date(item.event?.end), 'Europe/Berlin').getTime()}
                renderer={countDownRenderer}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default PackageSuppl
