import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import sanitizeHtml from 'sanitize-html'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'

import IntroHeader from '../component/intro-header'
import AckSC from '../component/AckSC'

import {
  renderField,
  renderFieldWithHelp,
  renderTooglePassword,
  required,
  emailValidation,
  phoneValidation,
  renderCheckbox,
  renderPhoneFieldWithHelp,
} from '../utils/form'

const RegisterForm = (props) => {
  const {
    handleSubmit,
    submitting,
    content,
    acknowledgments,
    toggleModal,
  } = props

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <IntroHeader label={content && content['content.signup.user.title']} />
        <div
          dangerouslySetInnerHTML={{
            __html:
              content &&
              sanitizeHtml(content['content.signup.user.description'], {
                allowedTags: ALLOWED_TAGS,
                allowedAttributes: ALLOWED_ATTRIBUTES,
              }),
          }}
        />
        <div className="form__row form__row--register">
          <div className="form__item__group">
            <div className="form__item">
              <label htmlFor="firstname">Jméno</label>
            </div>

            <Field
              id="firstname"
              name="firstname"
              component={renderField}
              type="text"
              validate={[required]}
            />
          </div>
          <div className="form__item__group">
            <div className="form__item">
              <label htmlFor="lastname">Příjmení</label>
            </div>

            <Field
              id="lastname"
              name="lastname"
              component={renderField}
              type="text"
              validate={[required]}
            />
          </div>
        </div>
        <div className="form__row form__row--register">
          <div className="form__item__group">
            <div className="form__item">
              <label htmlFor="phone">Telefon</label>
            </div>
            <Field
              id="phone"
              name="phone"
              component={renderPhoneFieldWithHelp}
              mask="+42"
              type="text"
              label="+42_ ___ ___ ___"
              validate={[phoneValidation]}
              toggle={() =>
                toggleModal(content['content.signup.user.phone.help'])
              }
            />
          </div>
          <div className="form__item__group">
            <div className="form__item">
              <label htmlFor="username">E-mail</label>
            </div>
            <Field
              id="username"
              name="username"
              component={renderFieldWithHelp}
              type="text"
              validate={[emailValidation]}
              toggle={() =>
                toggleModal(content['content.signup.user.email.help'])
              }
            />
          </div>
        </div>
        <div className="form__row form__row--register">
          <div className="form__item__group">
            <div className="form__item">
              <label htmlFor="password">Heslo</label>
            </div>
            <Field
              id="password"
              name="password"
              component={renderTooglePassword}
              type="password"
            />
            <div className="form__item">
              <span className="form__password__hint">
                Heslo musí obsahovat 8 znaků, číslici, velké a malé písmeno.
              </span>
            </div>
          </div>
          <div className="form__item__group">
            <div className="form__item">
              <label htmlFor="password">Heslo (znovu)</label>
            </div>
            <Field
              id="password2"
              name="password2"
              component={renderTooglePassword}
              type="password"
            />
          </div>
        </div>
        <div className="form__row form__row--register form__row--gdpr">
          <div className="form__item__group form__item__group--checkbox">
            {acknowledgments &&
              acknowledgments.map((ack) => {
                if (ack.required && ack.selected) {
                  props.change(`acknowledgments[${ack.id}]`, true)
                }
                return (
                  <div className="form__item" key={ack.id}>
                    <AckSC ack={ack}>
                      <Field
                        id={`acknowledgments[${ack.id}]`}
                        name={`acknowledgments[${ack.id}]`}
                        component={renderCheckbox}
                        // type="checkbox"
                        validate={[]}
                        checked={ack.required && ack.selected}
                        disabled={ack.required && ack.selected}
                        label={ack.description}
                      />
                    </AckSC>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="intro__buttons intro__buttons--register">
          <button
            disabled={submitting}
            className="btn btn--primary"
            type="submit"
          >
            Registrovat
          </button>
          <Link className="link-primary black link-registered" to={'/'}>
            Jsem již registrovaný
          </Link>
        </div>
      </form>
    </React.Fragment>
  )
}

function validate(values) {
  const errors = {}

  if (!values.firstname) {
    errors.firstname = 'Prosím, vyplňte jméno.'
  }

  if (!values.lastname) {
    errors.lastname = 'Prosím, vyplňte příjmení.'
  }

  if (!values.phone) {
    errors.phone = 'Prosím, vyplňte telefon.'
  }

  if (!values.username) {
    errors.username = 'Prosím, vyplňte e-mail.'
  }

  if (!values.password) {
    errors.password = 'Prosím, vyplňte heslo.'
  }

  if (values.password !== values.password2) {
    errors.password2 = 'Hesla se neshodují.'
  }

  return errors
}

export default reduxForm({
  form: 'form_register',
  touchOnBlur: false,
  validate,
})(RegisterForm)
