import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { renderField, deliveryEmailValidation } from '../../utils/form'

let PaymentSetupEmailForm = (props) => {
  const { handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <ul className="list list--action align-center list--modal">
        <li className="list__row">
          <div className="list__label list__col">
            <label htmlFor="invoiceEmail">Fakturační e-mail</label>
          </div>
          <div className="list__value list__col">
            <Field
              id="invoiceEmail"
              name="invoiceEmail"
              component={renderField}
              type="text"
              placeholder=""
              validate={[deliveryEmailValidation]}
            />
          </div>
        </li>
      </ul>

      <div className="">
        <button
          className="btn btn--primary btn--next btn--block--xs"
          type="submit"
        >
          OK
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'form_payment_setup_email',
  //fields,
  touchOnBlur: false,
  //validate,
  enableReinitialize: true,
})(PaymentSetupEmailForm)
