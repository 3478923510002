import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loading from './loading'

import { getContractLiveTV } from '../actions/admin.js'

class LiveTvButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.openLiveTv = this.openLiveTv.bind(this)
  }

  openLiveTv() {
    this.setState({ loading: true })
    this.props
      .getContractLiveTV(this.props.contract_id)
      .then((url) => {
        this.setState({ loading: false })
        window.open(url, 'telly-live-tv')
      })
      .catch((error) => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <button
        type="button"
        onClick={() => this.openLiveTv()}
        className={this.props.className}
      >
        {this.state.loading && (
          <Loading
            className="global-loading"
            text="přesměrování na Online TV"
          />
        )}

        {this.props.children}
      </button>
    )
  }
}

export default connect(
  null,
  {
    getContractLiveTV,
  }
)(LiveTvButton)
