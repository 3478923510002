import {connect} from 'react-redux'
import {compose} from 'redux'
import React, {Component} from 'react'

import { requestRewards } from '../actions/loyalty'

let LoyaltyHoc = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: false,
      }
    }

    componentDidMount() {
      this.props.requestRewards()
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loyalty={this.props.loyalty}
        />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loyalty: state.loyalty,
  }
}

export default compose(
  connect(mapStateToProps, {
    requestRewards,
  }),
  LoyaltyHoc,
)
