import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import tellyLogo from '../assets/images/logo.svg'
import ContractNrSelect from './contract-nr-select'

import LiveTvButton from './live-tv.js'
// import contractHoc from '../hoc/contract-hoc'
import { isUpsellEnabled } from '../utils/functions'
import LoyaltyHoc from "../hoc/loyalty-hoc";
import { hasPermissions, OttTypes } from '../utils/permissions'
import { Status } from '../data/contract';
import { getContractFromState } from '../utils/contract'

class AdminNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMenuSection: null,
      openMainNav: false, // otevirani zavirani navigace na mobilnich telefonech
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidUpdate(prevProp, prevState) {
    if (
      prevState.openMainNav &&
      ((this.props.location &&
        this.props.location.pathname !== prevProp.location.pathname) ||
        (this.props.contract_nr &&
          this.props.contract_nr.selected !== prevProp.contract_nr.selected))
    ) {
      this.setState({
        openMainNav: false,
      })
    }
  }

  toggleMenu() {
    this.setState({
      openMainNav: !this.state.openMainNav,
    })
    if (this.state.openMainNav) {
      document.body.classList.remove('modal-open')
    } else {
      document.body.classList.add('modal-open')
    }
  }

  render() {
    //const { unreadMessages } = this.props.inbox.meta.new;
    //console.log(this.props)
    let loyaltyAvailable = false
    try {
      loyaltyAvailable = this.props.contract.pricelist.includes('TELLY')
    } catch (error) {
      loyaltyAvailable = false
    }

    let nav = [
          {
            title: <React.Fragment>Moje služby</React.Fragment>,
            link: '/registrace/vyber-sluzby',
            class: 'services',
            nav: [
              {
                title: 'Aktivace nových služeb',
                link: '/registrace/vyber-sluzby',
              },
            ],
          },
          {
            title: <React.Fragment>Můj profil</React.Fragment>,
            link: '/ucet/profil',
            class: 'profile',
            nav: [
              {
                title: 'Osobní údaje',
                link: '/ucet/profil',
              },
              {
                title: 'Nastavení',
                link: '/ucet/profil/nastaveni',
              },
            ],
          },
        ]

    if(this.props.hasContracts && hasPermissions(this.props.contract)) {
      nav = [
        {
          title: <React.Fragment>Moje služby</React.Fragment>,
          link: '/ucet/moje-sluzby/prehled-sluzeb',
          class: 'services',
          nav: [
            {
              title: 'Přehled služeb',
              link: '/ucet/moje-sluzby/prehled-sluzeb',
            },
          ],
        },
        {
          title: <React.Fragment>Faktury a platby</React.Fragment>,
          link: '/ucet/fakturace-a-platby',
          class: 'invoicing',
          nav: [
            {
              title: 'Seznam faktur',
              link: '/ucet/fakturace-a-platby',
            },
          ],
        },
        {
          title: <React.Fragment>Můj profil</React.Fragment>,
          link: '/ucet/profil',
          class: 'profile',
          nav: [
            {
              title: 'Osobní údaje',
              link: '/ucet/profil',
            },
            {
              title: 'Nastavení',
              link: '/ucet/profil/nastaveni',
            },
            // {
            //   title: `Schránka zpráv (${this.props.inbox.meta.new})`,
            //   link: '/ucet/profil/zpravy',
            // },
            {
              title: 'Přehled dokumentů',
              link: '/ucet/profil/dokumenty',
            },
          ],
        },
      ];

      if(hasPermissions(this.props.contract, [OttTypes.CLASSIC])) {
        nav[1].nav.push({
          title: 'Nastavení plateb',
          link: '/ucet/fakturace-a-platby/nastaveni-plateb',
        });
      }

      if (isUpsellEnabled() && this.props.contract && this.props.contract.upsell && this.props.contract.status === Status.ACTIVE && hasPermissions(this.props.contract, [OttTypes.CLASSIC])) {
        nav[0].nav.push({
          title: 'Změna služeb',
          link: '/ucet/moje-sluzby',
        })
      }
    }

    try {
      if (loyaltyAvailable) {
        const loyaltyMenuItem = {
          title: <React.Fragment>Věrnostní program</React.Fragment>,
          link: '/ucet/vernostni-program/prehled',
          class: 'loyalty',
          nav: [
            {
              title: 'Přehled',
              link: '/ucet/vernostni-program/prehled',
            },
          ],
        }
        if (
          this.props.loyalty.rewards.available.length ||
          this.props.loyalty.rewards.used.length
        ) {
          loyaltyMenuItem.nav.push({
            title: `Moje odměny (${this.props.loyalty.rewards.available.length})`,
            link: '/ucet/vernostni-program/moje-odmeny',
          })
        }

        nav.push(loyaltyMenuItem)
      }
    } catch (e) {}

    //const contract = contracts[this.props.contract_id];

    return (
      <React.Fragment>
        <button
          id="admin__menu__button"
          className="admin__menu__button"
          onClick={this.toggleMenu}
        >
          Menu
        </button>

        <div
          id="admin__menu"
          className={`admin__menu ${this.state.openMainNav ? 'active' : ''}`}
        >
          <button className="admin__menu__closer" onClick={this.toggleMenu} />
          <Link to={'/ucet/nastenka'} onClick={this.toggleMenu}>
            <img className="logo" src={tellyLogo} alt="" />
          </Link>
          <nav>
            <ul>
              <li className="menu__first">
                <ContractNrSelect />
              </li>

              {nav.map((item, i) => {
                const activeClass =
                  window.location.pathname.indexOf(item.link) >= 0 ||
                  item.link.indexOf(window.location.pathname) >= 0
                    ? ' active'
                    : ''
                return (
                  <li key={i} className={`menu__first${activeClass}`}>
                    <NavLink
                      className={`subnav-title ${item.class}`}
                      to={item.link}
                    >
                      {item.title}
                    </NavLink>
                    <ul>
                      {item.nav.map((item, j) => {
                        return (
                          <li key={j}>
                            <NavLink
                              exact
                              to={item.link}
                              onClick={this.toggleMenu}
                            >
                              {item.title}
                            </NavLink>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}

              {this.props.contract &&
                hasPermissions(this.props.contract) &&
                this.props.contract.live_tv &&
                this.props.contract.live_tv_status && (
                  <li className="menu__first">
                    <LiveTvButton
                      className="tv link subnav-title"
                      contract_id={this.props.contract.id}
                    >
                      Sledovat <br />
                      TV online
                    </LiveTvButton>
                  </li>
                )}
            </ul>
          </nav>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    inbox: state.inbox,
    hasContracts: state.contract.ids.length > 0,
    contract: getContractFromState(state),
    contract_nr: state.contract_nr
  }
}

const enhance = compose(
  connect(mapStateToProps, {
    //requestCustomerInbox
  }),
  LoyaltyHoc,
)

export default enhance(AdminNav)

//export default ContractHoc( AdminNav );
//export default ContractHoc(AdminNav);
