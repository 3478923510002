import axios from 'axios'
import { apiOverrideEnabled, getCookie } from '../utils/functions'

const API_ENV = getCookie('API_ENV')
let baseURL = process.env.REACT_APP_API_ENDPOINT

if (apiOverrideEnabled() && API_ENV === 'prod') {
  console.log('!! PRODUCTION API !!')
  baseURL = 'https://lara.telly.cz/'
}

console.log(baseURL)

let instance = axios.create({
  baseURL,
  withCredentials: true, // cookie
})

// const cancelTokenAll = axios.CancelToken.source()
// instance.defaults.cancelToken = cancelTokenAll.token

export { instance }
