import {
  RECEIVE_INBOX,
  RECEIVE_INBOX_FAILED,
  REQUEST_INBOX,
  RECEIVE_INBOX_MESSAGE,
  RECEIVE_INBOX_MESSAGE_FAILED,
  REQUEST_INBOX_MESSAGE,
  RECEIVE_INBOX_OPENED,
  RECEIVE_INBOX_NEW,
} from '../actions/types'
//import { access } from 'fs';

const INITIAL_STATE = {
  opened: {},
  new: {},
  all: {},
  pages: {},
  items: {},
  pagination: {},
  loading: false,
  detail: {},
  meta: {},
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_INBOX:
    case REQUEST_INBOX_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_INBOX:
      const messages_all = action.payload.data.reduce(
        function(acc, cur, i) {
          acc.items[cur.id] = cur
          acc.ids.push(cur.id)
          return acc
        },
        { ids: [], items: {} }
      )

      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.meta.pagination.current_page]: messages_all.ids,
        },
        items: {
          ...state.items,
          ...messages_all.items,
        },
        pagination: action.payload.meta.pagination,
        loading: false,
        meta: action.payload.meta,
      }
    case RECEIVE_INBOX_OPENED:
      const messages_opened = action.payload.data.reduce(
        function(acc, cur, i) {
          acc.items[cur.id] = cur
          acc.ids.push(cur.id)
          return acc
        },
        { ids: [], items: {} }
      )

      return {
        ...state,
        opened: {
          ...state.opened,
          [action.payload.meta.pagination.current_page]: messages_opened.ids,
        },
        items: {
          ...state.items,
          ...messages_opened.items,
        },
        pagination: action.payload.meta.pagination,
        loading: false,
        //meta: action.payload.meta
      }
    case RECEIVE_INBOX_NEW:
      const messages_new = action.payload.data.reduce(
        function(acc, cur, i) {
          acc.items[cur.id] = cur
          acc.ids.push(cur.id)
          return acc
        },
        { ids: [], items: {} }
      )

      return {
        ...state,
        new: {
          ...state.new,
          [action.payload.meta.pagination.current_page]: messages_new.ids,
        },
        items: {
          ...state.items,
          ...messages_new.items,
        },
        pagination: action.payload.meta.pagination,
        loading: false,
        meta: action.payload.meta,
      }
    case RECEIVE_INBOX_MESSAGE:
      return {
        ...state,
        detail: {
          [action.payload.data.id]: action.payload.data,
        },
        loading: false,
      }
    case RECEIVE_INBOX_FAILED:
    case RECEIVE_INBOX_MESSAGE_FAILED:
      return {
        ...state,
        loading: false,
      }

    // no default
  }
  return state
}
