import React, { Component } from 'react'
//import ReactDOM from 'react-dom';
import {
  //Link,
  //Route,
  //Switch,
  Redirect,
  //withRouter
} from 'react-router-dom'
import { connect } from 'react-redux'
import { clearRedirect } from '../actions/app'

class AppRedirect extends Component {
  componentDidMount() {
    this.props.clearRedirect()
  }

  componentDidUpdate() {
    if (window.location.pathname === this.props.app.redirect) {
      this.props.clearRedirect()
    }
  }

  render() {
    if (this.props.app.redirect) {
      return <Redirect to={this.props.app.redirect} />
    }

    return null
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
  }
}

export default connect(
  mapStateToProps,
  {
    clearRedirect,
  }
)(AppRedirect)
