import React, { Component } from 'react'
import { connect } from 'react-redux'

import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../../conf/constants'

import Device from './device'
import {
  incDeviceTotal,
  decDeviceTotal,
  incDeviceTotalUpsell,
  decDeviceTotalUpsell,
  resetDeviceTotal,
} from '../../actions/services'
import {parseContent} from "../../utils/functions";
import {Field} from "redux-form";
import {renderError} from "../../utils/form";

class DevicesWrapper extends Component {
  constructor(props) {
    super(props);
    let devices = 0
    if(this.props.upsellOptionsDevices)
    {
      for(let i = 0; i < Object.values(this.props.upsellOptionsDevices).length; i++) {
        devices = devices + Object.values(this.props.upsellOptionsDevices)[i].activeCount
      }
    }
  }


  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     total:
  //       props.values && Object.values(props.values).length > 0
  //         ? Object.values(props.values).reduce((a, b) => a + b)
  //         : 0,
  //     devices: {},
  //   }

  //   this.inc = this.inc.bind(this)
  //   this.dec = this.dec.bind(this)
  //   this.handleChange = this.handleChange.bind(this)
  // }

  // inc() {
  //   if (this.props.deviceTotal < this.props.deviceMax) {
  //     this.props.incDeviceTotal()
  //     return true
  //   }
  //   return false
  // }

  // dec() {
  //   if (this.props.deviceTotal > 0) {
  //     this.props.decDeviceTotal()
  //     return true
  //   }
  //   return false
  // }

  // handleChange(device, qty) {
  //   this.setState({
  //     devices: {
  //       ...this.state.devices,
  //       [device]: qty,
  //     },
  //   })
  // }
  render() {
    const {
      devices,
      toggleModal,
      content,
      portfolioKey,
      change,
      deviceMax,
      deviceMin,
      deviceTotalNew,
      incDeviceTotal,
      incDeviceTotalUpsell,
      decDeviceTotalUpsell,
      decDeviceTotal,
      resetDeviceTotal,
      selectedPortfolio,
      isUpsell,
      deviceTotalUpsell,
      installations,
      handleInstallation,
      selectedInstallation,
      upsellOptionsDevices
    } = this.props

    let deviceCount = 0;
    if (upsellOptionsDevices) {
      for(let i = 0; i < Object.values(upsellOptionsDevices).length; i++) {
        deviceCount += Object.values(upsellOptionsDevices)[i].activeCount
      }
    }

    return deviceCount < deviceMax && (
      <React.Fragment>
        <hr />
        <h3 className={'portfolio__content__text'}>
          {parseContent(
            content[
              `content.upsell.contract.${portfolioKey}.devices.title`
              ],
            {
              html: true,
              allowedTags: [],
              allowedAttributes: [],
            }
          )}
        </h3>
        <p className={'portfolio__content__subtitle'}>
          {parseContent(
            content[
              `content.upsell.contract.${portfolioKey}.devices.description`
              ],
            {
              html: true,
              allowedTags: [],
              allowedAttributes: [],
            }
          )}
        </p>
        <Field name="device_e" component={renderError} />
        {devices.map((item) => (
          <Device
            selectedPackage={
              isUpsell ? (this.props.formData && this.props.formData.service_upsell_form_u && this.props.formData.service_upsell_form_u.values && this.props.formData.service_upsell_form_u.values.package_core) : undefined}
            key={`${item.group}`}
            device={item}
            inc={isUpsell ? incDeviceTotalUpsell : incDeviceTotal}
            dec={isUpsell ? decDeviceTotalUpsell : decDeviceTotal}
            resetDeviceTotal={resetDeviceTotal}
            deviceTotal={isUpsell ? deviceTotalUpsell : deviceTotalNew}
            deviceMax={deviceMax}
            deviceMin={deviceMin}
            upsellOptionsDevices
            prevStep={this.props.contractUpsell.prevStep}
            presetValueUpsell={this.props.formData && this.props.formData.service_upsell_form_u && this.props.formData.service_upsell_form_u.values && this.props.formData.service_upsell_form_u.values.device && this.props.formData.service_upsell_form_u.values.device[item.group]}
            presetValue={this.props.formData && this.props.formData.service_upsell_form && this.props.formData.service_upsell_form.values && this.props.formData.service_upsell_form.values.device && this.props.formData.service_upsell_form.values.device[selectedPortfolio] && this.props.formData.service_upsell_form.values.device[selectedPortfolio][item.group]}
            // handleChange={this.handleChange}
            toggleModal={() =>
              toggleModal(
                sanitizeHtml(
                  content[
                    `content.new.contract.${portfolioKey}.${item.group}.description`
                  ],
                  {
                    allowedTags: ALLOWED_TAGS,
                    allowedAttributes: ALLOWED_ATTRIBUTES,
                  }
                )
              )
            }
            change={change}
            selectedPortfolio={selectedPortfolio}
            value={item.activeCount}
            minValue={item.activeCount}
            isUpsell={isUpsell}
            currentMinDevices={deviceCount}
            currentThisDeviceTotal = {item.activeCount}
          />
        ))}
        {
          this.props.formData &&
          this.props.formData.service_upsell_form_u &&
          this.props.formData.service_upsell_form_u.values &&
          this.props.formData.service_upsell_form_u.values.device &&
          Object.values(this.props.formData.service_upsell_form_u.values.device).some(x => x > 0) ? (
            <React.Fragment>
              <hr />
              <h3 className={'portfolio__content__text'}>
                {parseContent(
                  content[
                    `content.upsell.contract.${portfolioKey}.installation.title`
                    ],
                  {
                    html: true,
                    allowedTags: [],
                    allowedAttributes: [],
                  }
                )}
              </h3>
              <p
                className={
                  'portfolio__content__subtitle portfolio__content__subtitle--warning'
                }
              >
                {parseContent(
                  content[
                    `content.upsell.contract.${portfolioKey}.installation.description`
                    ],
                  {
                    html: true,
                    allowedTags: [],
                    allowedAttributes: [],
                  }
                )}
              </p>
              <div className={'instalation'}>
                <Field name="installation_type_e" component={renderError} />
                {installations &&
                  installations.map((item, i) => (
                    <div key={item.id} className={'instalation__item'}>
                      <label
                        htmlFor={`installation_type_${i}`}
                        className={'radio'}
                      >
                        <Field
                          type="radio"
                          id={`installation_type_${i}`}
                          name="installation_type"
                          component="input"
                          onClick={() => handleInstallation(item.id)}
                          checked={
                            selectedInstallation
                              ? item.id === selectedInstallation
                              : item.selected
                          }
                          disabled={item.disabled}
                        />
                        <span>
                            {item.name}
                          <span
                            className="portfolio__content__item__info"
                            onClick={(e) => {
                              e.preventDefault()
                              toggleModal(
                                parseContent(
                                  content[
                                    `content.upsell.contract.${portfolioKey}.installation.${
                                      item.id
                                    }.description`
                                    ],
                                  {
                                    html: true,
                                    allowedTags: ALLOWED_TAGS,
                                    allowedAttributes: ALLOWED_ATTRIBUTES,
                                  }
                                )
                              )
                            }}
                          >
                              ?
                            </span>
                          </span>
                      </label>
                      {/* <div className={'installation__item__price'}>
                        +&nbsp;
                        {formatCurrency(item.priceVat, 0)}
                      </div> */}
                    </div>
                  ))}
              </div>
            </React.Fragment>
          ) : (<div>
              { installations &&
                installations.map((item, i) => (
                  <Field
                    type="hidden"
                    id={`installation_type_${i}`}
                    name="installation_type"
                    component="input"
                    onClick={() => handleInstallation(item.id)}
                    value={selectedInstallation
                      ? item.id === selectedInstallation
                      : item.selected}
                    disabled={item.disabled}
                  />
                ))}
          </div>)
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    deviceTotalNew: state.contract_new.deviceTotal,
    contractUpsell: state.contract_upsell,
    deviceTotalUpsell: state.contract_upsell.deviceTotal,
    selectedPortfolio: state.contract_new.selectedPortfolio,
    upsellOptionsDevices: state.contract_upsell.options && state.contract_upsell.options.packages && state.contract_upsell.options.packages.device,
    formData: state.form
  }
}

export default connect(mapStateToProps, {
  incDeviceTotal,
  decDeviceTotal,
  incDeviceTotalUpsell,
  decDeviceTotalUpsell,
  resetDeviceTotal,
})(DevicesWrapper)

// export default DevicesWrapper
