import React, { Component } from 'react'
import { reduxForm } from 'redux-form'

// import {renderField} from '../utils/form'
import { formatPhone } from '../utils/functions'

import Loading from '../component/loading'

import { mobileValidation } from '../utils/form'

class CallMeFormLogged extends Component {
  render() {
    const { handleSubmit, submitting, baseMobilePhone, content } = this.props
    const description =
      content['content.popup.callback.description'] &&
      content['content.popup.callback.description'].replace(
        '%phone%',
        formatPhone(baseMobilePhone)
      )
    //const callcentre = str_replace('%phone%', formatPhone(baseMobilePhone), content['content.popup.callback.callcentre']);

    return (
      <div>
        {submitting && <Loading />}

        <form onSubmit={handleSubmit} className="form--call-me">
          <div className="modal-body modal--call-me">
            {baseMobilePhone && (
              <div className="row row--1">
                <div
                  className="col col--is--12"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                >
                  {/*<p>Operátor Vás bude kontaktovat na zadaném telefonním čísle <strong>{baseMobilePhone ? formatPhone(baseMobilePhone) : <span>Nevyplňeno</span>}</strong></p>*/}
                </div>
              </div>
            )}
            <div className="col">
              <button
                disabled={submitting}
                className="btn btn--primary btn--block--xs"
                type="submit"
              >
                Zavolejte mi
              </button>
            </div>
            {/*
                        <div className="row row--2">
                            <div className="col">

                                    <label htmlFor="your_phone">
                                        {baseMobilePhone ?
                                            <span>Pokud si přejete, abychom Vás kontaktovali na jiném telefonním čísle, zadejte číslo zde:</span>
                                            :
                                            <span>Zadejte telefonní číslo a náš operátor Vás bude kontaktovat:</span>

                                        }
                                    </label>
                            </div>

                            <div className="col">
                                    <div className="input">
                                        <Field
                                            id="your_phone"
                                            name="phone"
                                            component={renderField}
                                            type="text"
                                            placeholder=""
                                            className="input"
                                        />
                                    </div>
                            </div>

                        </div>
                        */}
          </div>

          <div className="modal-footer">
            <div className="row row--is--bottom row--xm--bottom row--3">
              <div className="col">
                <div
                  // className="small-note"
                  dangerouslySetInnerHTML={{
                    __html: content['content.popup.callback.callcentre'],
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.baseMobilePhone || values.phone) {
    errors.phone = mobileValidation(values.phone)
  }

  return errors
}

export default reduxForm({
  form: 'form_call_me_not_logged',
  touchOnBlur: false,
  validate,
})(CallMeFormLogged)
