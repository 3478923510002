import {
  REQUEST_ACKNOWLEDGMENTS,
  RECEIVE_ACKNOWLEDGMENTS,
  TOGGLE_ACKNOWLEDGMENT_CONFIRM,
} from './types'

import { instance } from '../conf/axios'

export function getCustomerAcknowledgment() {
  return function (dispatch, getState) {
    dispatch({
      type: REQUEST_ACKNOWLEDGMENTS,
    })
    return instance
      .get(`customer/acknowledgment`)
      .then((response) => {
        dispatch({
          type: RECEIVE_ACKNOWLEDGMENTS,
          payload: response.data,
        })
        return Promise.resolve(response.data)
      })
      .catch((err) => Promise.reject(err))
  }
}

export function changeCustomerAcknowledgment(ack_id, confirmed, pin) {
  return function (dispatch, getState) {
    return instance
      .post(`customer/acknowledgment/${ack_id}`, {
        confirmed,
        validationPin: pin,
      })
      .then((response) => {
        dispatch({
          type: TOGGLE_ACKNOWLEDGMENT_CONFIRM,
          acknowledgment_id: ack_id,
          confirmed,
        })
        return Promise.resolve(response.data)
      })
      .catch((err) => Promise.reject(err))
  }
}

export function getFirstLoginAcknowledgments() {
  return function (dispatch, getState) {
    return instance
      .get(`sign/customer/acknowledgment`)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
}
