import { SET_MESSAGE, CLEAR_MESSAGE } from '../actions/types'
import {requestLogout} from "../actions/login";


const INITIAL_STATE = {
  error: null,
  active: false,
  type: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
        active: true,
        type: action.kind,
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        active: false,
      }
    // no default
  }
  return state
}
