import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import sanitizeHtml from 'sanitize-html'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import OrderSummary from '../component/services/order-summary'
import {
  renderReactAsyncSelect,
  renderSelectWithHelp,
  renderDatePicker,
  renderField,
  renderSelect,
  preBankCodeValidation,
  bankAccountValidation,
  bankCodeValidation,
  required,
} from '../utils/form'

import { findAddress } from '../actions/ruian'
import { bankCodes } from '../conf/bank-codes'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

let findAddressInterval
let withValue = false

class UpsellAgreementForm extends Component {
  constructor(props) {
    super(props)

    this.toggleSameAddress = this.toggleSameAddress.bind(this)
    this.findAddress = this.findAddress.bind(this)

    let invoiceTypeSelected
    let invoiceDeliverySelected
    const invoiceTypeSelectOptions = Object.entries(
      props.invoiceTypeOptions
    ).map((o) => {
      if (o[1].selected) invoiceTypeSelected = o[0]
      return { label: o[1].name, value: o[0] }
    })
    const invoiceDeliverySelectOptions = Object.entries(
      props.invoiceDeliveryOptions
    ).map((o) => {
      if (o[1].selected) invoiceDeliverySelected = o[0]
      return { label: o[1].name, value: o[0] }
    })

    props.change('invoice_type', invoiceTypeSelected)
    props.change('invoice_delivery', invoiceDeliverySelected)

    const td = new Date()

    this.state = {
      birthDate: new Date(td.getFullYear() - 18, td.getMonth(), td.getDay()),
      sameAddress: true,
      sameAddressShipping: true,
      invoiceTypeSelectOptions,
      invoiceDeliverySelectOptions,
    }

    props.change('address_same', this.state.sameAddress)
    props.change('address_same_shipping', this.state.sameAddressShipping)
  }

  toggleSameAddress(address) {
    this.setState({
      [address]: !this.state[address],
    })
  }

  findAddress(address, cb) {
    if (address.length < 3) return

    if (findAddressInterval !== null) {
      clearInterval(findAddressInterval)
    }
    findAddressInterval = setTimeout(() => {
      findAddress({ query: address })
        .then((resp) => {
          if (!Array.isArray(resp.data)) return
          cb(
            resp.data.map((a) => ({
              label: `${a.street} ${a.houseNumber}${
                a.orientationNumber > 0 ? '/' + a.orientationNumber : ''
              }, ${a.city}, ${a.cityPart}`,
              value: a.id,
            }))
          )
        })
        .catch((err) => {})
    }, 400)
  }

  render() {
    const {
      goToStep,
      handleSubmit,
      onSubmit,
      toggleModal,
      help,
      portfolioKey,
      content,
      selectedInvoiceType,
      isGoPay,
    } = this.props

    const codes = bankCodes.map((code) => `${code.code} (${code.name})`)

    withValue =
      this.props.invoiceTypeOptions[this.props.selectedInvoiceType] &&
      this.props.invoiceTypeOptions[this.props.selectedInvoiceType].value

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="order__agreement small-note"
      >
        <div className="form__row">
          <div className="form__item">
            <label htmlFor="dob">Datum narození</label>
          </div>
          <div className="form__item">
            <Field
              component={renderDatePicker}
              name="dob"
              id="dob"
              dateFormat={[
                'dd.MM.yyyy',
                'dd. MM. yyyy',
                'dd MM yyyy',
                'dd/MM/yyyy',
              ]}
              maxDate={this.state.birthDate}
              label="DD.MM.RRRR"
              // validate={required}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__item">
            <label htmlFor="address">Adresa trvalého pobytu</label>
          </div>
          <div className="form__item">
            <Field
              id="address"
              name="address"
              component={renderReactAsyncSelect}
              loadOptions={this.findAddress}
              // defaultOptions={[]}
              placeholder={
                'Začněte psát adresu s diakritikou (ulice, město) a zadejte alespoň 3 znaky'
              }
              cacheOptions={true}
              // validate={ruianInput}
            />
          </div>
        </div>
        <div className="form__row form__row--small">
          <div className="form__item">
            <label htmlFor="address_same" className={'checkbox'}>
              <Field
                name="address_same"
                id="address_same"
                type="checkbox"
                component="input"
                onClick={() => this.toggleSameAddress('sameAddress')}
                checked={this.state.sameAddress}
              />
              <span className={'checkbox__label'}>
                Adresa instalace je stejná jako adresa trvalého pobytu
              </span>
            </label>
          </div>
        </div>
        <SlideDown
          className={'my-dropdown-slidedown'}
          closed={this.state.sameAddress}
        >
          <div className="form__row form__row--conditional">
            <div className="form__item">
              <label htmlFor="address_installation">Adresa instalace</label>
            </div>
            <div className="form__item">
              <Field
                id="address_installation"
                name="address_installation"
                component={renderReactAsyncSelect}
                loadOptions={this.findAddress}
                placeholder={
                  'Začněte psát adresu s diakritikou (ulice, město) a zadejte alespoň 3 znaky'
                }
                cacheOptions={true}
                // validate={!this.state.sameAddress ? ruianInput : null}
              />
            </div>
          </div>
        </SlideDown>
        <div className="form__row form__row--small">
          <div className="form__item">
            <label htmlFor="address_same_shipping" className={'checkbox'}>
              <Field
                name="address_same_shipping"
                id="address_same_shipping"
                type="checkbox"
                component="input"
                onClick={() => this.toggleSameAddress('sameAddressShipping')}
                checked={this.state.sameAddressShipping}
              />
              <span className={'checkbox__label'}>
                Adresa pro doručování písemností je stejná jako adresa trvalého
                pobytu
              </span>
            </label>
          </div>
        </div>
        <SlideDown
          className={'my-dropdown-slidedown'}
          closed={this.state.sameAddressShipping}
        >
          <div className="form__row form__row--conditional ">
            <div className="form__item">
              <label htmlFor="address_shipping">
                Adresa pro doručování písemností
              </label>
            </div>
            <div className="form__item">
              <Field
                id="address_shipping"
                name="address_shipping"
                component={renderReactAsyncSelect}
                loadOptions={this.findAddress}
                placeholder={
                  'Začněte psát adresu s diakritikou (ulice, město) a zadejte alespoň 3 znaky'
                }
                cacheOptions={true}
                // validate={!this.state.sameAddressShipping ? ruianInput : null}
              />
            </div>
          </div>
        </SlideDown>

        <h2 className="h2">Způsob úhrady faktur</h2>
        <div className="form__row form__row--invoice">
          <div className="form__item">
            <Field
              name="invoice_type"
              id="invoice_type"
              component={renderSelectWithHelp}
              options={this.state.invoiceTypeSelectOptions}
              objectValue={true}
              blankOption={false}
              help={() =>
                toggleModal(
                  help[`help.new.contract.${portfolioKey}.payment.help`]
                )
              }
              onChange={(e) => {
                withValue = this.props.invoiceTypeOptions[e.currentTarget.value]
                  .value
              }}
            />
          </div>
        </div>
        {selectedInvoiceType === 'pay_gate' && (
          <img
            src="https://telly.cz/selfcare-img/logo-gopay.png"
            alt=""
            style={{ maxWidth: '75%' }}
          />
        )}
        <SlideDown className={'my-dropdown-slidedown'} closed={!withValue}>
          <div
            className={
              'form__row form__row--invoice form__invoice' +
              (selectedInvoiceType === 'inkaso' ? ' form__invoice--full' : '')
            }
          >
            <div className="form__item">
              {selectedInvoiceType === 'inkaso' && (
                <ul className="list list--action align-center list--modal list--inkaso">
                  <li className="list__row">
                    <div className="list__value list__col list__invoice">
                      <Field
                        id="pre"
                        name="bankPre"
                        component={renderField}
                        type="text"
                        label="Předčíslí účtu"
                        validate={[preBankCodeValidation]}
                      />
                      &nbsp;
                      <Field
                        id="account"
                        name="bankAccount"
                        component={renderField}
                        type="text"
                        label="Číslo účtu"
                        validate={[bankAccountValidation]}
                      />
                      &nbsp;
                      <Field
                        id="bank-code"
                        name="bankCode"
                        component={renderSelect}
                        options={codes}
                        label="Kód banky"
                        validate={[bankCodeValidation]}
                      />
                    </div>
                  </li>
                </ul>
              )}
              {selectedInvoiceType !== 'inkaso' && (
                <Field
                  name="invoice_type_value"
                  id="invoice_type_value"
                  component={renderField}
                  type="text"
                  label={sanitizeHtml(
                    content[
                      `content.new.contract.${portfolioKey}.payment.${selectedInvoiceType}`
                    ],
                    { allowedTags: [] }
                  )}
                />
              )}
            </div>
          </div>
        </SlideDown>

        <h2 className="h2">Způsob zasílání vyúčtování</h2>
        <div className="form__row">
          <div className="form__item">
            <Field
              name="invoice_delivery"
              id="invoice_delivery"
              component={renderSelectWithHelp}
              options={this.state.invoiceDeliverySelectOptions}
              objectValue={true}
              blankOption={false}
              help={() =>
                toggleModal(
                  help[
                    `help.new.contract.${portfolioKey}.payment.delivery.help`
                  ]
                )
              }
            />
          </div>
        </div>

        <OrderSummary
          toggleModal={toggleModal}
          portfolioKey={portfolioKey}
          isUpsell={false}
        />

        {isGoPay && (
          <div className="form__row form__row--conditional">
            {/* <div className="repeat_payment"> */}
            <label htmlFor="repeat_payment" className={'checkbox'}>
              <Field
                name="repeat_payment"
                id="repeat_payment"
                type="checkbox"
                component={renderField}
                validate={[required]}
                noWrap={true}
              />
              <span className={'checkbox__label'}>
                Účastník souhlasí s tím, že platby za následující měsíční
                Faktury budou hrazeny formou automatického strhávání plateb (
                <b>tzv. opakované platby</b>) z platební karty přes platební
                bránu GoPay (
                <b
                  // TODO: classa?
                  className="order__total__more__info"
                  onClick={(e) => {
                    e.preventDefault()
                    toggleModal(
                      sanitizeHtml(
                        content[
                          `content.new.contract.${portfolioKey}.payment.deposit.info.modal`
                        ],
                        {
                          allowedTags: ALLOWED_TAGS,
                          allowedAttributes: ALLOWED_ATTRIBUTES,
                        }
                      )
                    )
                  }}
                >
                  více info
                </b>
                ). Platební metodu lze v zákaznické zóně Telly kdykoliv změnit.
              </span>
            </label>
            {/* </div> */}
          </div>
        )}

        <div className="footline footline--activation footline footline--nextprev">
          <button
            className="btn btn--secondary"
            type="button"
            onClick={() => {
              goToStep(1)
            }}
          >
            Zpět
          </button>
          <button className="btn btn--primary" type="submit">
            Pokračovat na smluvní dokumenty
          </button>
        </div>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {}

  if (!values.dob) {
    errors.dob = 'Prosím, vyplňte datum.'
  }

  if (!values.address) {
    errors.address = 'Prosím, zadejte adresu.'
  }

  if (!values.address_same && !values.address_installation) {
    errors.address_installation = 'Prosím, zadejte adresu.'
  }

  if (!values.address_same_shipping && !values.address_shipping) {
    errors.address_shipping = 'Prosím, zadejte adresu.'
  }

  if (withValue && !values.invoice_type_value) {
    errors.invoice_type_value = 'Prosím, zadejte hodnotu.'
  }

  // if (withValue && values.invoice_type === 'sipo' && values.bankPre) {
  //   errors.invoice_type_value = 'Prosím, zadejte hodnotu.'
  // }

  return errors
}

UpsellAgreementForm = reduxForm({
  form: 'service_upsell_agreement_form',
  touchOnBlur: false,
  // enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
  onSubmitFail: (e) => {
    console.log(e)
    if (e && (e.hasOwnProperty('address') || e.hasOwnProperty('dob'))) {
      window.scrollTo(0, 0)
    }
  },
})(UpsellAgreementForm)

class ServicesActivationStep2 extends Component {
  render() {
    const {
      goToStep,
      requestFindAddress,
      handleSubmit,
      toggleModal,
      content,
      help,
      portfolioKey,
      invoiceType,
      invoiceDelivery,
      selectedInvoiceType,
      isGoPay,
      isUpsell,
    } = this.props
    return (
      <div className="activation activation--3 order">
        <h2 className="h2">Doplňující údaje</h2>
        <p>K uzavření smlouvy je potřebné doplnit některé detaily</p>
        <UpsellAgreementForm
          goToStep={goToStep}
          onSubmit={handleSubmit}
          requestAddress={requestFindAddress}
          toggleModal={toggleModal}
          portfolioKey={portfolioKey}
          content={content}
          help={help}
          invoiceTypeOptions={invoiceType}
          invoiceDeliveryOptions={invoiceDelivery}
          selectedInvoiceType={selectedInvoiceType}
          isGoPay={isGoPay}
          isUpsell={isUpsell}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    help: state.translation.help,
    invoiceType: state.contract_new.overview.invoiceTypeOptions || {},
    invoiceDelivery: state.contract_new.overview.invoiceDeliveryOptions || {},
    selectedInvoiceType:
      (state.form.service_upsell_agreement_form &&
        state.form.service_upsell_agreement_form.values &&
        state.form.service_upsell_agreement_form.values.invoice_type) ||
      null,
    isGoPay: state.contract_new.overview.priceVat > 0,
  }
}

export default connect(mapStateToProps, {})(ServicesActivationStep2)
