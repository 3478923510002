import React, { Component, useState } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
//import SimpleBar from 'simplebar-react';
import Select from 'react-select'
import Loading from '../component/loading.js'

import {
  requestRegion,
  requestCounty,
  requestCity,
  requestCityPart,
  requestStreet,
  requestAddress,
} from '../actions/ruian'

import {
  renderField,
  renderRadio,
  renderCheckbox,
  ruianInput,
  renderReactAsyncSelect,
} from './../utils/form'

import { setWarning, setError } from '../actions/error.js'

const RenderReactSelect = ({
  input,
  options,
  name,
  id,
  onChange,
  placeholder,
  isDisabled,
  meta: { touched, error },
}) => {
  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <Select
        {...input}
        id={id}
        name={name}
        options={options}
        placeholder={placeholder}
        onChange={(e) => {
          input.onChange(e)
        }}
        onBlur={() => {
          input.onBlur()
        }}
        isDisabled={isDisabled}
        className="nice-select"
        classNamePrefix="nice-select"
        noOptionsMessage={() => 'Žádné možnosti...'}
      />
    </div>
  )
}

class RuianAddressForm extends Component {
  constructor(props) {
    super(props)

    //console.log('Ruian constructor');
    //console.log( props.initialValues );

    this.state = {
      showAddress:
        props.initialValues && true === props.initialValues.no_address
          ? false
          : true,
      step: 1,
    }
    this.toggleAddress = this.toggleAddress.bind(this)
    this.clearDependedFields = this.clearDependedFields.bind(this)
    this.setStep = this.setStep.bind(this)
  }

  componentDidUpdate() {
    if (this.state.showAddress) {
      this.panel.style.maxHeight = this.panel.scrollHeight + 'px'
    }
  }

  setStep(step) {
    this.setState({
      step: step,
    })
  }

  clearDependedFields(inputName, fn) {
    const inputsNames = [
      'cityId',
      'cityPartId',
      'streetId',
      'houseNumber',
      'orientationNumber',
    ]

    const position = inputsNames.indexOf(inputName)

    if (-1 !== position) {
      for (let i = 0; i < inputsNames.length; i++) {
        const name = inputsNames[i]
        if (i > position) {
          this.props.dispatch(change('form_ruian_address', name, null))
        }
      }
    }

    return fn
  }

  toggleAddress() {
    if (this.state.showAddress) {
      this.panel.style.maxHeight = '0px'
    } else {
      this.panel.style.maxHeight = this.panel.scrollHeight + 'px'
    }

    this.setState({
      showAddress: !this.state.showAddress,
    })
  }

  render() {
    const {
      handleSubmit,
      submitting,
      //requestPin,
      cities = [],
      cityParts = [],
      streets = [],
      pernamentAddress = '',
      showOrientationNumber = false,
    } = this.props

    const openAddressClass = this.state.showAddress ? 'panel--open' : ''

    return (
      <div className="relative">
        {/*submitting &&
                    <Loading />
                */}

        <div className="row">
          <div className="col col--is--12">
            <form onSubmit={handleSubmit}>
              <React.Fragment>
                <ul
                  ref={(panel) => (this.panel = panel)}
                  className={`list--modal list list--action align-center panel ${openAddressClass}`}
                >
                  <li className="list__row">
                    <div className="list__label list__col">Obec:</div>
                    <div className="list__value list__col">
                      <Field
                        name="cityId"
                        component={renderReactAsyncSelect}
                        loadOptions={this.props.loadCity}
                        onChange={(e) => {
                          this.clearDependedFields(
                            'cityId',
                            this.props.onCityChange(e)
                          )
                        }}
                        placeholder={'Začněte psát...'}
                        cacheOptions={true}
                        defaultOptions={cities}
                        validate={ruianInput}
                      />
                    </div>
                  </li>

                  {cityParts.length > 0 && (
                    <li className="list__row">
                      <div className="list__label list__col">Část obce:</div>
                      <div className="list__value list__col">
                        <Field
                          name="cityPartId"
                          component={RenderReactSelect}
                          options={cityParts}
                          onChange={(e) => {
                            this.clearDependedFields(
                              'cityPartId',
                              this.props.onCityPartChange(e)
                            )
                          }}
                          placeholder={'---'}
                          validate={ruianInput}
                        />
                      </div>
                    </li>
                  )}

                  {streets.length > 0 && (
                    <li className=" list__row">
                      <div className="list__label list__col">Ulice:</div>
                      <div className="list__value list__col">
                        <Field
                          name="streetId"
                          component={RenderReactSelect}
                          options={streets}
                          onChange={(e) => {
                            this.clearDependedFields('streetId')
                            this.props.onStreetChange(e)
                          }}
                          placeholder={'---'}
                          validate={streets.length > 0 ? ruianInput : undefined}
                        />
                      </div>
                    </li>
                  )}

                  {showOrientationNumber && (
                    <li className="list__row">
                      <div className="list__label list__col">
                        Číslo popisné a orientační:
                      </div>
                      <div className="list__value list__value--number list__col">
                        <Field
                          id="houseNumber"
                          name="houseNumber"
                          component={renderField}
                          type="text"
                          placeholder=""
                        />
                        {' / '}
                        <Field
                          id="orientationNumber"
                          name="orientationNumber"
                          component={renderField}
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </li>
                  )}
                </ul>

                <ul className="list list--action align-center">
                  <li className="list__row">
                    <div className="list__col list__col--auto">
                      <Field
                        id="no_address"
                        name="no_address"
                        component={renderCheckbox}
                        type="checkbox"
                        placeholder=""
                        label={`Stejná jako adresa trvalého pobytu <span className="hightlight">(${pernamentAddress})</span>`}
                        onClick={this.toggleAddress}
                      />
                    </div>
                  </li>
                </ul>

                <div className="footline">
                  <button
                    disabled={submitting}
                    className="btn btn--primary btn--next btn--block--xs"
                    type="submit"
                  >
                    Dále
                  </button>
                </div>
              </React.Fragment>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RuianAddressForm = reduxForm({
  form: 'form_ruian_address',
  touchOnBlur: false,
})(RuianAddressForm)

let RuianAddressConfirmationForm = (props) => {
  const {
    handleSubmit,
    adresses = [],
    submitting,
    errorMessge,
    //requestPin,
  } = props

  const [step] = useState(1)

  return (
    <React.Fragment>
      <form className="address-pick" onSubmit={handleSubmit}>
        {1 === step && (
          <React.Fragment>
            <h3>Zvolte adresu</h3>

            <div className="address-pick__list">
              {/*
                                <SimpleBar
                                    style={ { height: '250px' } }
                                    data-simplebar-auto-hide="false"
                                    autoHide={ false }
                                >
                                */}
              {adresses.map((address, index) => {
                const labelCity = [address.city, address.cityPart]
                  .filter((elem, pos, arr) => arr.indexOf(elem) === pos)
                  .join(', ')
                const labelStreet =
                  address.street +
                  ' ' +
                  [address.houseNumber, address.orientationNumber]
                    .filter((item) => 0 !== item && '' !== item)
                    .join('/')
                const label = `${labelCity}, ${labelStreet}`

                return (
                  <div
                    key={address.id}
                    className={`address-pick ${0 === index % 2 ? 'even' : ''}`}
                  >
                    <Field
                      name={`ruian`}
                      component={renderRadio}
                      type="radio"
                      value={address.id.toString()}
                      placeholder=""
                      label={label}
                    />
                  </div>
                )
              })}
              {/*</SimpleBar>*/}
            </div>

            {errorMessge && errorMessge}

            <div className="footline footline--nextprev">
              <button
                className="btn btn--secondary btn--prev"
                type="button"
                onClick={props.goBack}
              >
                Zpět
              </button>

              <button
                disabled={submitting}
                className="btn btn--primary  btn--next"
                type="submit"
                /*
                                    onClick={ handleSubmit( ( values ) => {
                                        if( false === requestPin) {
                                            onSubmit(values);
                                        } else {
                                            if( values.ruian ) {
                                                requestPin().then( () => { setStep(2); } )
                                            } else {
                                                toast.error('Prosím, vyberte adresu');
                                            }
                                        }

                                    } ) }*/
              >
                Dále
              </button>
            </div>
          </React.Fragment>
        )}

        {2 === step && (
          <div className="update-address-step-2">
            <div className="pin-confirmation pin-confirmation--inline">
              <label htmlFor="value">Zadejte prosím potvrzovací kód</label>
              <Field
                id="validationPin"
                name="validationPin"
                component={renderField}
                type="text"
                placeholder=""
              />
              <button
                disabled={submitting}
                className="btn btn--primary btn--next btn--block--xs"
                type="submit"
              >
                Potvrdit
              </button>
            </div>

            <div className="mt20">
              <button
                type="button"
                className="link link--small"
                // onClick={ handleSubmit( () => requestPin() ) }
              >
                Znovu zaslat potvrzovací kód
              </button>
            </div>
          </div>
        )}
      </form>
    </React.Fragment>
  )
}

RuianAddressConfirmationForm = reduxForm({
  form: 'form_ruian_address_confirmation',
  touchOnBlur: false,
})(RuianAddressConfirmationForm)

class RuianAddressFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      regions: [],
      counties: [],
      cityParts: [],
      streets: [],
      addresses: [],
      initialValues: props.initialValues,
      loading: false,
      showOrientationNumber: false,
      confErrorMessge: false,
    }
    this.requestCounty = this.requestCounty.bind(this)
    this.requestCityPart = this.requestCityPart.bind(this)
    this.requestStreet = this.requestStreet.bind(this)
    this.requestAddress = this.requestAddress.bind(this)
    this.onCityChange = this.onCityChange.bind(this)
    this.onCityPartChange = this.onCityPartChange.bind(this)
    this.submitAddress = this.submitAddress.bind(this)
    this.goBack = this.goBack.bind(this)
    this.loadCity = this.loadCity.bind(this)
    this.onStreetChange = this.onStreetChange.bind(this)
  }

  componentDidMount() {
    this.props
      .requestRegion()
      .then((resp) => {
        this.setState({
          regions: resp.data,
        })
      })
      .catch(() => {})
  }

  requestCounty(val) {
    this.props
      .requestCounty({ regionId: val.value })
      .then((resp) => {
        this.setState({
          counties: resp.data,
          regionId: val.value,
        })
      })
      .catch(() => {})
  }

  requestCityPart(val) {
    this.props
      .requestCityPart({ cityId: val.value })
      .then((resp) => {
        this.setState({
          cityParts: resp.data,
          cityId: val.value,
        })
      })
      .catch(() => {})
  }

  requestStreet(val) {
    this.props
      .requestStreet({ cityPartId: val.value, cityId: this.state.cityId })
      .then((resp) => {
        this.setState({
          streets: resp.data,
          cityPartId: val.value,
        })
      })
      .catch(() => {})
  }

  requestAddress(values) {
    this.setState({
      loading: true,
    })

    if (values.no_address) {
      return this.props.onSubmit(values).then(() => {
        this.setState({
          loading: false,
        })
      })
    } else {
      this.props
        .requestAddress(values)
        .then((resp) => {
          this.setState({
            addresses: resp.data,
            initialValues: values,
          })

          if (typeof this.props.ruianStepCallback === 'function') {
            this.props.ruianStepCallback(2)
          }
        })
        .catch(() => {})
        .then(() => {
          this.setState({
            loading: false,
          })
        })
    }
  }

  loadCity(inputValue, callback) {
    if (inputValue.length > 1) {
      return this.props
        .requestCity({ countryId: 1, name: inputValue })
        .then((resp) => {
          const options = resp.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))

          this.setState({
            cities: options,
          })
          return options
        })
        .catch(() => {})
    } else {
      return Promise.resolve(this.state.cities)
    }
  }

  onCityChange(val) {
    if (val.value) {
      this.setState({
        loading: true,
      })
      this.props
        .requestCityPart({ cityId: val.value })
        .then((resp) => {
          const options = resp.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
          this.setState({
            cityParts: options,
            cityId: val.value,
            streets: [],
          })
        })
        .catch(() => {})
        .then(() => {
          this.setState({
            loading: false,
          })
        })
    }
  }

  onCityPartChange(val) {
    this.setState({
      cityPartId: val.value,
      loading: true,
    })

    this.props
      .requestStreet({ cityPartId: val.value, cityId: this.state.cityId })
      .then((resp) => {
        const options = resp.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        this.setState({
          streets: options,
          showOrientationNumber: true,
        })
      })
      .catch(() => {})
      .then(() => {
        this.setState({
          cityPartId: val.value,
          loading: false,
        })
      })
  }

  onStreetChange(val) {
    /*
        this.setState({
            showOrientationNumber: true
        })
        */
  }

  goBack() {
    this.setState({
      addresses: [],
    })
    if (typeof this.props.ruianStepCallback === 'function') {
      this.props.ruianStepCallback(1)
    }
  }

  submitAddress(values) {
    //console.log( 'submitAddress' );
    //console.log( values );

    this.setState({ loading: true })

    if (!values.ruian) {
      this.props.setError('Prosím, zvolte adresu.')
      return false
    }

    const address = this.state.addresses.find(
      (element) => parseInt(values.ruian) === element.id
    )

    console.log(address)

    const modifiedValues = {
      ...values,
      ...this.state.initialValues,
      ...{
        ...address,
        street: address.street ? address.street : address.cityPart,
      },
      // zip: 77777,
      //validationPin: values.validationPin
    }
    this.setState({ loading: false })
    return this.props.onSubmit(modifiedValues)
  }

  render() {
    let pernamentAddress = ''
    if (this.props.user && this.props.user.address) {
      pernamentAddress =
        this.props.user.address.street + ', ' + this.props.user.address.city
    }

    return (
      <div>
        {this.state.loading && <Loading />}

        {this.state.addresses.length < 1 && (
          <RuianAddressForm
            //requestPin={this.props.requestPin}
            initialValues={this.state.initialValues}
            onSubmit={this.requestAddress}
            loadCity={this.loadCity}
            onCityChange={this.onCityChange}
            cities={this.state.cities}
            cityParts={this.state.cityParts}
            onCityPartChange={this.onCityPartChange}
            streets={this.state.streets}
            onStreetChange={this.onStreetChange}
            showOrientationNumber={this.state.showOrientationNumber}
            addresses={this.state.addresses}
            pernamentAddress={pernamentAddress}
          />
        )}

        {this.state.addresses.length > 0 && (
          <RuianAddressConfirmationForm
            adresses={this.state.addresses}
            onSubmit={this.submitAddress}
            goBack={this.goBack}
            errorMessge={this.state.confErrorMessge}
            //requestPin={this.props.requestPin}
          />
        )}
      </div>
    )
  }
}

export default connect(null, {
  requestRegion,
  requestCounty,
  requestCity,
  requestCityPart,
  requestStreet,
  requestAddress,
  setWarning,
  setError,
  //requestPin
})(RuianAddressFormContainer)
