import React, { Component } from 'react'
//import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
// import AdminHeader from '../component/admin-header'
import { connect } from 'react-redux'
import Pagination from '../component/pagination'
import Loading from '../component/loading'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'

//import Banner from '../component/banner';

import AdminContent from '../layout/admin-content'

import { requestCustomerInbox } from '../actions/inbox'

class Inbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      opened: null,
    }
    this.paginate = this.paginate.bind(this)
  }

  componentDidMount() {
    this.props.requestCustomerInbox(1, this.props.match.params.filter_opened)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.filter_opened !==
      this.props.match.params.filter_opened
    ) {
      this.props.requestCustomerInbox(1, this.props.match.params.filter_opened)
    }
  }

  paginate(page) {
    this.props.requestCustomerInbox(page, this.props.match.params.filter_opened)
  }

  render() {
    let message_ids = []
    let empty_message = ''
    const { content, help, pagination } = this.props

    switch (this.props.match.params.filter_opened) {
      case 'prectene':
        message_ids = this.props.opened[this.props.pagination.current_page]
          ? this.props.opened[this.props.pagination.current_page]
          : []
        empty_message = 'Nemáte žádné přečtené zprávy.'
        break
      case 'nove':
        message_ids = this.props.new[this.props.pagination.current_page]
          ? this.props.new[this.props.pagination.current_page]
          : []
        empty_message = 'Nemáte žádné nové zprávy.'
        break
      default:
        message_ids = this.props.all[this.props.pagination.current_page]
          ? this.props.all[this.props.pagination.current_page]
          : []
        empty_message = 'Nemáte žádné zprávy.'
    }

    return (
      <AdminContent
        advertisementTarget="profile.messages.list.banner"
        title="Můj profil"
        subtitle={content['content.profile.messages.list.title']}
        help={help['help.profile.messages.list.help']}
        link="/ucet/profil"
      >
        <div className="row">
          <div className="col col--is--12 col--xl--12">
            <div className="inbox">
              <div className="inbox__filter">
                <div>
                  <Link
                    type="button"
                    className={
                      'btn btn--filter' +
                      ('nove' === this.props.match.params.filter_opened
                        ? ' active'
                        : '')
                    }
                    to="/ucet/profil/zpravy/nove"
                    //onclick={(e) => this.filterOpened(1)}
                  >
                    Nové ({this.props.meta.new})
                  </Link>{' '}
                  <Link
                    type="button"
                    className={
                      'btn btn--filter' +
                      ('prectene' === this.props.match.params.filter_opened
                        ? ' active'
                        : '')
                    }
                    to="/ucet/profil/zpravy/prectene"
                    //onclick={(e) => this.filterOpened(2)}
                  >
                    Přečtené
                  </Link>
                </div>

                {/*
                                    <div>
                                        <select>
                                            <option>Seřazeno od nejnovějších</option>
                                            <option>Seřazeno od nejstarších</option>
                                        </select>
                                    </div>
                                    */}
              </div>

              <div className="relative">
                {this.props.loading && <Loading />}

                {message_ids.length > 0 && (
                  <ul className="inbox__list">
                    {message_ids.map((message_id) => {
                      const message = this.props.items[message_id]
                      if (!message) return null
                      return (
                        <li key={message.id}>
                          <Link to={`/ucet/profil/zprava/${message.id}`}>
                            <div
                              className={
                                'admin__inner inbox__message' +
                                (0 === message.opened ? ' unread' : '')
                              }
                            >
                              <div className="inbox__message__header">
                                <div>
                                  <span className="inbox__message__header__title">
                                    {message.subject}
                                  </span>{' '}
                                  {message.author && (
                                    <span className="inbox__message__header__author">
                                      {' '}
                                      od {message.author}
                                    </span>
                                  )}
                                </div>
                                <div className="inbox__message__header__date">
                                  {moment(message.created_at.date).format(
                                    'L H:m'
                                  )}
                                </div>
                              </div>
                              <div className="inbox__message__body">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(message.description, {
                                      allowedTags: ALLOWED_TAGS,
                                      allowedAttributes: ALLOWED_ATTRIBUTES,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                )}

                {!this.props.loading && message_ids.length === 0 && (
                  <p className="admin__inner relative">{empty_message}</p>
                )}

                <Pagination pagination={pagination} paginate={this.paginate} />
              </div>
            </div>
          </div>
        </div>

        {/*
                    <Banner target="profile.messages.list" position="bottom" />

                </div>
                */}
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    help: state.translation.help,
    content: state.translation.content,
    meta: state.inbox.meta,
    pagination: state.inbox.pagination,
    items: state.inbox.items,
    new: state.inbox.new,
    opened: state.inbox.opened,
    all: state.inbox.all,
    pages: state.inbox.pages,
    loading: state.inbox.loading,
  }
}

export default connect(mapStateToProps, {
  requestCustomerInbox,
})(Inbox)
