import React from 'react'

const DigiModalContext = React.createContext({
  open: false,
  content: '',
  style: null,
  title: '',
  html: false, // should render content as html?
})

export default DigiModalContext
