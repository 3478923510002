import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoginForm from '../../form/login-form'
import PublicLayout from '../../layout/public-layout'
import DigiModalContext from '../../context/digi-modal-context'

import { requestLoginAndUserInfo } from '../../actions/user'

class Login extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount() {
    if ('/overeni-kontaktu' === this.props.location.pathname) {
      this.context.toggleDigiModal(
        <React.Fragment>
          <p>Pro dokončení ověření kontaktu se prosím přihlašte.</p>
          <div className="footline">
            <button
              className="btn btn--primary btn--next"
              onClick={() => this.context.toggleDigiModal()}
            >
              Dále
            </button>
          </div>
        </React.Fragment>
      )
    }
  }

  handleLogin(values) {
    return this.props.requestLoginAndUserInfo(values)
  }

  render() {
    return (
      <PublicLayout>
        <LoginForm content={this.props.content} onSubmit={this.handleLogin} />
      </PublicLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.translation.content,
  }
}

export default connect(
  mapStateToProps,
  {
    requestLoginAndUserInfo,
  }
)(Login)
