import React from 'react'
import Loading from '../component/loading'

const AppRefresh = (props) => {
  return (
    <div className="admin">
      <Loading className="global-loading" text="Načítání" />
    </div>
  )
}

export default AppRefresh
