import moment from 'moment'
import React, {useState} from 'react'
import {useInterval} from '../utils/hooks'

export default function Countdown(props) {
    const {title, subtitle, date, dateFormat} = props

    const now = moment().unix()
    const end = moment(date, dateFormat).unix()
    const diffTime = end - now
    const interval = 1000

    const [duration, setDuration] = useState(
        moment.duration(diffTime * 1000, 'milliseconds')
    )

    useInterval(() => {
        setDuration(moment.duration(duration - interval, 'milliseconds'))
    }, interval)

    return (
        <div className="loyalty__countdown">
            <strong className="loyalty__countdown__title">{title ? <>{title}<br/></> : ''}<span
                className="loyalty__countdown__subtitle">{subtitle}</span></strong>
            <div className="loalty__countdown__timer">
        <span className="days">
          <span>{duration.days()}</span>
          <br/>
          dny
        </span>
                <span className="hours">
          <span>{duration.hours()}</span>
          <br/>
          hodin
        </span>
                <span className="minutes">
          <span>{duration.minutes()}</span>
          <br/>
          minut
        </span>
                <span className="seconds">
          <span>{duration.seconds()}</span>
          <br/>
          vteřin
        </span>
            </div>
        </div>
    )
}
