import {
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  //RECEIVE_LOGIN_FAILED,
  RECEIVE_USER_INFO,
  RECEIVE_DELETE_CONTACT,
  REQUEST_DELETE_CONTACT,
  // REQUEST_UPDATE_CONTACT,
  RECEIVE_UPDATE_CONTACT,
  RECEIVE_LOGOUT,
  RECEIVE_SIGN_UP_ACKNOWLEDGMENTS,
  REQUEST_SIGN_UP_ACKNOWLEDGMENTS,
  WAS_ACTIVATED,
  SET_PAYMENT_ACTION,
} from '../actions/types'
import { USER_TOKEN_KEY } from '../conf/constants'
import { getCookie } from '../utils/functions'

const INITIAL_STATE = {
  id: false,
  loading: false,
  loading_contacts: false,
  loading_delete_contacts: false,
  auth: false,
  authFull: false,
  active: false,
  acknowledgments: [],
  lastPaymentAction: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return {
        ...state,
        auth: getCookie(USER_TOKEN_KEY) !== null,
      }
    case REQUEST_LOGIN:
      return {
        ...state,
        error: '',
        //items: [],
        loading: true,
      }

    case RECEIVE_LOGIN:
      return {
        ...state,
        auth: true,
        email: action.payload.email,
        active: action.payload.active,
      }

    case RECEIVE_LOGOUT:
      return {
        auth: false,
      }

    /*
        case RECEIVE_LOGIN:
            return {...state

                id          : action.payload.data.id,
                name        : action.payload.data.name,
                surname     : action.payload.data.surname,
                loading     : false,
                token       : action.payload.token,

            }; */
    case RECEIVE_USER_INFO:
      //console.log('RECEIVE_USER_INFO')
      //console.log(action.payload);

      let email = action.payload.data.account.username;

      return {
        ...state,
        ...action.payload.data,
        loading: false,
        contacts: action.payload.data.contacts.data,
        email,
        // auth: true,
        authFull: true,
        /*
                address         : action.payload.data.address,
                birthNumber     : action.payload.data.birthNumber,
                companyName     : action.payload.data.companyName,
                companyNumber   : action.payload.data.companyNumber,
                contacts        : action.payload.data.contacts.data,
                name            : action.payload.data.name,
                surname         : action.payload.data.surname,
                type            : action.payload.data.type,
                vatNumber       : action.payload.data.vatNumber,
                email           : action.payload.data.email*/
      }
    case REQUEST_DELETE_CONTACT:
      //case REQUEST_UPDATE_CONTACT:
      return {
        ...state,
        //loading_contacts : true,
        loading_delete_contacts: true,
      }
    case RECEIVE_UPDATE_CONTACT:
      return { ...state, loading_contacts: false }
    case RECEIVE_DELETE_CONTACT:
      let contacts = state.contacts.filter((item) => action.id !== item.id)

      return {
        ...state,
        contacts: contacts,
        //loading_contacts : false,
        loading_delete_contacts: false,
      }
    case REQUEST_SIGN_UP_ACKNOWLEDGMENTS:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_SIGN_UP_ACKNOWLEDGMENTS:
      return {
        ...state,
        acknowledgments: action.acknowledgments,
        loading: false,
      }
    case WAS_ACTIVATED:
      return {
        ...state,
        active: true,
      }
    case SET_PAYMENT_ACTION:
      return {
        ...state,
        lastPaymentAction: action.action,
      }

    // no default
  }
  return state
}
