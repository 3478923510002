import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { requestFirstLoginValidation } from '../../actions/login'
import Loading from '../../component/loading'
import IntroHeader from '../../component/intro-header'
import PublicLayout from '../../layout/public-layout'

class FirstLoginValidation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: null,
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    let params = new URL(document.location).searchParams
    let hash = params.get('key')
    //let email = params.get('email');
    let contract = params.get('contract')

    this.setState({
      loading: true,
    })

    this.props
      .requestFirstLoginValidation({ hash: hash, contract: contract })
      .then((result) => {
        this.setState({
          message: (
            <div>
              Aktivace účtu proběhla úspěšně. Nyní se můžete{' '}
              <Link to="/">přihlásit</Link>.
            </div>
          ),
          loading: false,
        })
      })
      .catch((error) => {
        this.setState({
          error: <div>Aktivace účtu se nezdařila.</div>,
          loading: false,
        })
        Promise.reject(error)
      })
  }

  render() {
    return (
      <PublicLayout>
        {this.state.loading && <Loading />}

        <IntroHeader label="Aktivace účtu" />

        <div className="intro__text">
          <div className="row">
            <div className="col">
              {this.state.error && (
                <div className="spacer spacer--20 validation--block alert">
                  {this.state.error}
                </div>
              )}

              {this.state.message && (
                <div className="spacer spacer--20 validation--block info">
                  {this.state.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="intro__links">
          <Link className="a--arrow--back green intro__links__link" to="/">
            Zpět na přihlášení
          </Link>
        </div>
      </PublicLayout>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

export default connect(
  mapStateToProps,
  {
    requestFirstLoginValidation,
  }
)(FirstLoginValidation)
