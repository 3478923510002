import { RECEIVE_PORTFOLIO_DEVICES } from '../actions/types'

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_PORTFOLIO_DEVICES:
      let items = {}
      let ids = []

      for (let value of action.payload) {
        items[value.id] = value
        ids.push(value.id)
      }

      return {
        ...state,
        [action.portfolio_id]: {
          loading: false,
          items: items,
          ids: ids,
        },
      }

    // no default
  }
  return state
}
