export const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  content: {
    maxWidth: '880px',
    width: '94%',
    bottom: 'auto',
    rigth: 'auto',
    left: '50%',
    top: '50%',
    maxHeight: '100%',
    transform: 'translate(-50%,-50%)',
    overflowX: 'hidden',
    overflow: 'scrolll',
    padding: '40px',
  },
}

export const modalStyleTiny = {
  ...modalStyle,
  content: {
    ...modalStyle['content'],
    maxWidth: '348px',
  },
}
