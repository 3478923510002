import {clearCookie, getCookie} from "../utils/functions";
import React from 'react'
import {COOKIE_SELECTED_CONTRACT, USER_TOKEN_KEY} from "../conf/constants";
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "../index";

export class PermissionAndPersist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      permission: this.getCookie(USER_TOKEN_KEY)
    }
  }

  getCookie(name) {
    return {
      isPermissionGranted: getCookie(name),
    }
  }

  clearPersistor() {
    persistor.purge()
      .then(() => {
        return persistor.flush()
      })
      .then(() => {
        persistor.pause()
      })
    clearCookie(COOKIE_SELECTED_CONTRACT)
  }

  render() {
    const { permission } = this.state;
    const {children, persistor} = this.props;
    if(!permission.isPermissionGranted) {
      this.clearPersistor()
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      )
    } else if (permission.isPermissionGranted) {
      return <PersistGate persistor={persistor} >{children}</PersistGate>
    }
  }
}
