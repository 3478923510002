import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer, Slide } from 'react-toastify'
import { getTranslation } from '../actions/app'
import { validatePayment } from '../actions/invoices'
import { goToStep, setPaymentAction } from '../actions/services'
import { requestSignUpValidation } from '../actions/user'
import AppRedirect from '../component/app-redirect'
import Routes from '../routes'
import { findContactBaseMobile } from '../utils/functions'
import DigiModal from '../component/digi-modal'
import PromptBasePhone from './prompt-base-phone'
import AppRefresh from '../layout/app-refresh'
import Loading from '../component/loading'
import { requestLogout } from '../actions/login'

const ToastCloseButton = ({ closeToast }) => (
  <button onClick={closeToast} className="button-close">
    zavřít
  </button>
)

class App extends Component {
  constructor(props) {
    super(props)
    document.title = 'Telly - Zákaznická zóna'
  }

  componentDidMount() {
    this.props.getTranslation()
  }

  render() {
    const baseMobilePhone = findContactBaseMobile(this.props.user.contacts)

    let mainComponent = (
      <Routes
        user={this.props.user}
        contract={this.props.contract}
        validatePayment={this.props.validatePayment}
        requestSignUpValidation={this.props.requestSignUpValidation}
        goToStep={this.props.goToStep}
        setPaymentAction={this.props.setPaymentAction}
        requestLogout={this.props.requestLogout}
      />
    )

    //console.log('baseMobilePhone');
    //console.log(baseMobilePhone);

    if (
      true === this.props.user.auth &&
      !baseMobilePhone &&
      this.props.user.contacts
    ) {
      //console.log('aa');
      mainComponent = <PromptBasePhone />
    }

    if (this.props.app.refreshing) {
      mainComponent = <AppRefresh />
    }

    return (
      <React.Fragment>
        <ToastContainer
          autoClose={4000}
          position="top-center"
          transition={Slide}
          closeOnClick={false}
          hideProgressBar
          closeButton={<ToastCloseButton />}
        />
        {this.props.app.loading && <Loading />}
        <AppRedirect />

        <DigiModal>{mainComponent}</DigiModal>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
    contract: state.contract
      ? Object.values(state.contract.items).find(x => x.number == state.contract_nr.selected)
      : null,
  }
}

export default connect(mapStateToProps, {
  getTranslation,
  validatePayment,
  requestSignUpValidation,
  goToStep,
  setPaymentAction,
  requestLogout,
})(App)
