import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import Loading from './loading'

const PrivateRoute = ({ component: Component, render, path }) => {
  const user = useSelector((state) => state.user)

  return (
    <Route
      path={path}
      render={(props) => {
        if (user.auth) {
          if (user.active) {
            if (user.authFull) {
              if ('function' === typeof render) {
                return render(props)
              } else {
                return <Component {...props} />
              }
            } else {
              return <Loading className="global-loading" text="Načítání" />
            }
          } else {
            return <Redirect to="/ucet/aktivace" />
          }
        } else {
          return <Redirect to="/" />
        }
      }}
    />
  )
}

export default PrivateRoute
