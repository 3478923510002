import React, { Component } from 'react'
//import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import {
  remoteSubmitPaymentSetup,
  setCurrentContractNr,
  setCanLeave,
} from '../actions/admin'

import LeavingModalHoc from '../hoc/leaving-modal-hoc'

class ContractNrSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tmp_nr: false,
    }
    this.changeContract = this.changeContract.bind(this)
    this.leave = this.leave.bind(this)
    this.proccess = this.proccess.bind(this)
    this.props.leavingCallbacks(this.leave, this.proccess)
  }

  proccess() {
    this.props.remoteSubmitPaymentSetup()
    this.props.setCurrentContractNr(this.props.contract_nr.selected)
  }

  leave() {
    if (this.state.tmp_nr !== this.props.contract_nr.selected) {
      this.props.setCurrentContractNr(this.state.tmp_nr)
    }
    this.props.setCanLeave(true)
  }

  changeContract(event) {
    if (this.props.app.canLeave) {
      this.props.setCurrentContractNr(event.target.value)
    } else {
      this.setState({
        tmp_nr: event.target.value,
      })
      this.props.openLeavingModal()
    }
  }

  render() {
    const { contract_nr } = this.props
    if (!contract_nr || !contract_nr.ids) {
      return null
    }

    if (contract_nr.ids.length > 1 && contract_nr.selected) {
      return (
        <form className="contract_selector">
          <label htmlFor="contract_number">Číslo smlouvy</label>
          <select
            ref="contract_number"
            id="contract_number"
            onChange={this.changeContract}
            value={contract_nr.selected}
            // disabled={!canChangeContract}
          >
            {contract_nr.ids.map((cnr) => (
              <option key={cnr} value={cnr}>
                {cnr}
              </option>
            ))}
          </select>
        </form>
      )
    } else if (contract_nr.ids.length === 1) {
      return (
        <div className="contract_selector">
          Číslo smlouvy: {contract_nr.ids[0]}
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contract_nr: state.contract_nr,
    // canChangeContract: !state.contract_upsell.inProgress,
    app: state.app,
  }
}

export default connect(mapStateToProps, {
  setCanLeave,
  remoteSubmitPaymentSetup,
  setCurrentContractNr,
})(LeavingModalHoc(ContractNrSelect))
