import React from 'react'
import { connect } from 'react-redux'

//import PopUpHeader from '../../component/popup-header';
import RuianFindAddressFormContainer from '../../form/ruian-find-address-form'
import { modalStyle } from '../../utils/modal-style'
import DigiModalContext from '../../context/digi-modal-context'
import { requestPin } from '../../actions/app'
import PinConfirmation from '../pin-confirmation'

import { formatPhone, findContactBaseMobile } from '../../utils/functions'
import ConfirmationNote from '../confirmation-note'

const UserAddresses = ({
  ruianAddress = '',
  content = {},
  user = { address: {} },
  updateAddress,
  requestPin,
  contract,
}) => {
  const baseMobileContact = findContactBaseMobile(user.contacts)

  return (
    <DigiModalContext.Consumer>
      {({ setStyle, toggleDigiModal }) => (
        <React.Fragment>
          {user.address && (
            <ul className="list list--form list--nowrap align-center">
              <li className="list__row nowrap">
                <div className="list__col--auto list__col">
                  <div className="list__row list__row--col">
                    <div className="list__col list__label">
                      Adresa trvalého pobytu
                    </div>
                    <div className="list__col list__value">
                      {user.address && (
                        <span>
                          {user.address.street}, {user.address.city}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          )}

          <ul className="list list--action list--nowrap align-center">
            {contract && (
              <li className="list__row">
                <div className="list__col--auto list__col">
                  <div className="list__row list__row--col">
                    <div className="list__col list__label">
                      Instalační adresa
                      <div className="list__label__sub">
                        smlouva č. {contract.number}
                      </div>
                    </div>
                    {contract.install_address ? (
                      <div className="list__col list__value">
                        {contract.install_address.street},{' '}
                        {contract.install_address.city}
                      </div>
                    ) : (
                      <div className="list__col list__value">
                        <span>stejná jako adresa trvalého pobytu</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="list__action list__action--icons--single">
                  <button
                    className="btn--edit"
                    onClick={() => {
                      setStyle(modalStyle)
                      toggleDigiModal(
                        <RuianFindAddressFormContainer
                          user={user}
                          initialValues={{
                            ...contract.install_address,
                            no_address:
                              contract.install_address &&
                              contract.install_address.id
                                ? false
                                : true,
                          }}
                          onSubmit={(values) =>
                            requestPin().then(() => {
                              if (values.address) {
                                values.ruian = values.address.value
                              }
                              toggleDigiModal()
                              toggleDigiModal(
                                <PinConfirmation
                                  text={
                                    content &&
                                    content[
                                      'content.profile.personal.data.change.mail.step1'
                                    ] &&
                                    content[
                                      'content.profile.personal.data.change.mail.step1'
                                    ].replace(
                                      '%phone%',
                                      formatPhone(baseMobileContact)
                                    )
                                  }
                                  confirmation={(validationPin) =>
                                    updateAddress(
                                      {
                                        ...values,
                                        validationPin: validationPin,
                                      },
                                      'installAddress'
                                    )
                                      .then((response) => {
                                        if (response)  {
                                            toggleDigiModal()
                                            toggleDigiModal(
                                              <ConfirmationNote
                                                text={
                                                  content[
                                                    'content.profile.personal.data.change.common.confirm'
                                                  ]
                                                }
                                                close={toggleDigiModal}
                                              />
                                            )
                                        }
                                        return Promise.resolve(response)
                                      })
                                      .catch((error) => {
                                        toggleDigiModal()
                                        toggleDigiModal(
                                          <ConfirmationNote
                                            text={
                                              content[
                                                'content.profile.personal.data.change.common.error'
                                              ]
                                            }
                                            close={toggleDigiModal}
                                          />
                                        )
                                        return Promise.reject(error)
                                      })
                                  }
                                />
                              )
                            })
                          }
                        />,
                        `Instalační adresa (smlouva č. ${contract.number})`
                      )
                    }}
                  >
                    Změnit
                  </button>
                </div>
              </li>
            )}

            {contract && (
              <li className="list__row">
                <div className="list__col--auto list__col">
                  <div className="list__row list__row--col">
                    <div className="list__col list__label">
                      Fakturační adresa
                      <div className="list__label__sub">
                        {' '}
                        smlouva č. {contract.number}
                      </div>
                    </div>
                    {contract.invoice_address ? (
                      <div className="list__col list__value">
                        {contract.invoice_address.street},{' '}
                        {contract.invoice_address.city}
                      </div>
                    ) : (
                      <div className="list__col list__value">
                        <span>stejná jako adresa trvalého pobytu</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="list__action list__action--icons--single">
                  <button
                    className="btn--edit"
                    onClick={() => {
                      setStyle(modalStyle)
                      toggleDigiModal(
                        <RuianFindAddressFormContainer
                          requestPin={requestPin}
                          user={user}
                          onSubmit={(values) =>
                            requestPin().then(() => {
                              if (values.address) {
                                values.ruian = values.address.value
                              }
                              toggleDigiModal()
                              toggleDigiModal(
                                <PinConfirmation
                                  text={
                                    content &&
                                    content[
                                      'content.profile.personal.data.change.mail.step1'
                                    ] &&
                                    content[
                                      'content.profile.personal.data.change.mail.step1'
                                    ].replace(
                                      '%phone%',
                                      formatPhone(baseMobileContact)
                                    )
                                  }
                                  confirmation={(validationPin) =>
                                    updateAddress(
                                      {
                                        ...values,
                                        validationPin: validationPin,
                                      },
                                      'invoiceAddress'
                                    )
                                      .then((response) => {
                                        if (response) {
                                          toggleDigiModal()
                                          toggleDigiModal(
                                            <ConfirmationNote
                                              text={
                                                content[
                                                  'content.profile.personal.data.change.common.confirm'
                                                ]
                                              }
                                              close={toggleDigiModal}
                                            />
                                          )
                                        }
                                        return Promise.resolve(response)
                                      })
                                  }
                                />
                              )
                            })
                          }
                          initialValues={{
                            ...contract.invoice_address,
                            no_address:
                              contract.invoice_address &&
                              contract.invoice_address.id
                                ? false
                                : true,
                          }}
                        />,
                        `Fakturační adresa (smlouva č. ${contract.number})`
                      )
                    }}
                  >
                    Změnit
                  </button>
                </div>
              </li>
            )}
          </ul>
        </React.Fragment>
      )}
    </DigiModalContext.Consumer>
  )
}

export default connect(null, {
  requestPin,
})(UserAddresses)

//export default UserAddresses;
