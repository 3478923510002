import {
  REQUEST_CONTRACT_DOCUMENTS,
  RECEIVE_CONTRACT_DOCUMENTS,
  RECEIVE_CONTRACT_DOCUMENTS_FAILED,
  REQUEST_CONTRACT_DOCUMENT,
  RECEIVE_CONTRACT_DOCUMENT,
  RECEIVE_CONTRACT_DOCUMENT_FAILED,
} from '../actions/types'

const INITIAL_STATE = {
  loading: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_CONTRACT_DOCUMENT:
    case REQUEST_CONTRACT_DOCUMENTS:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_CONTRACT_DOCUMENT:
      return {
        ...state,
        loading: false,
        [action.contract_id]: {
          ...state[action.contract_id],
        },
      }
    case RECEIVE_CONTRACT_DOCUMENTS:
      let items = {}
      let ids = []

      for (let value of action.payload) {
        items[value.id] = value
        ids.push(value.id)
      }

      return {
        ...state,
        loading: false,
        [action.contract_id]: {
          items: items,
          ids: ids,
          meta: action.meta,
        },
      }
    case RECEIVE_CONTRACT_DOCUMENT_FAILED:
    case RECEIVE_CONTRACT_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: false,
      }
    // no default
  }
  return state
}
