import { toast } from 'react-toastify'
import { instance } from '../conf/axios'
import {
  REQUEST_SUBMIT_REWARD,
  REQUEST_SUBMIT_REWARD_FAILED,
  REQUEST_SUBMIT_REWARD_SUCCESS,
  RECEIVE_REWARDS,
  REQUEST_REWARDS,
  REQUEST_REWARDS_FAILED,
  LOYALTY_NEXT_STEP,
} from './types'

export function requestRewards() {
  return async function (dispatch, getState) {
    try {
      const contract = getState().contract_nr
      const contract_id = contract.items[contract.selected].map((cID) => cID)
      dispatch({
        type: REQUEST_REWARDS,
      })
      const res = await instance.get(
        `customer/contract/${contract_id}/loyalty.offers`
      )
      const usedRewards = res.data.data.used

      if (usedRewards.length > 0) {
      }

      dispatch({
        type: RECEIVE_REWARDS,
        payload: res.data.data,
      })
      return res.data.data
    } catch (error) {
      dispatch({
        type: REQUEST_REWARDS_FAILED,
      })
      return null
    }
  }
}

export function submitReward(rewardId, phase = 'TEMP') {
  return async function (dispatch, getState) {
    const state = getState()
    const contract_id = state.contract_nr.items[state.contract_nr.selected].map(
      (cID) => cID
    )

    dispatch({
      type: REQUEST_SUBMIT_REWARD,
    })
    let payload = {
      offerLoadId: rewardId,
      phase,
    }
    try {
      const res = await instance.post(
        `customer/contract/${contract_id}/loyalty.offer`,
        payload
      )
      if (res.data.offerLoad.phase === 'FINAL') {
        toast.success('Vámi vybraná odměna byla právě aktivována.')
        await dispatch(requestRewards())
      }

      dispatch({
        type: REQUEST_SUBMIT_REWARD_SUCCESS,
        payload: rewardId,
      })

      return res.data.offerLoad
    } catch (error) {
      dispatch({
        type: REQUEST_SUBMIT_REWARD_FAILED,
      })
      return null
    }
  }
}

export function loyaltyNextStep() {
  return function (dispatch) {
    dispatch({
      type: LOYALTY_NEXT_STEP,
    })
  }
}
