import React, {useEffect, useState} from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import TagManager from 'react-gtm-module'

import Login from './container/public/login'
import Register from './container/public/register'
import FirstLogin from './container/public/first-login'
import FirstLoginValidation from './container/public/first-login-validation'
import PasswordRecovery from './container/public/password-recovery'
import CallMe from './container/public/call-me'
import PasswordCreate from './container/public/create-password'
//import ContactValidation from './container/public/contact-validation';

import Dashboard from './container/dashboard.js'
import Services from './container/services.js'
import ServicesNew from './container/services-new'
import ServiceList from './container/service-list.js'
import Invoices from './container/invoices/invoices.js'
import InvoicesSetup from './container/invoices/invoices-setup.js'
import ProfileActivation from './container/profile-activation'
import Profile from './container/profile.js'
import ProfileSetup from './container/profile-setup.js'
import Inbox from './container/inbox.js'
import InboxMessage from './container/inbox-message.js'
//import PaymentReturn from './container/payment-return.js';
import NotFoundFront from './container/public/not-found-front'
import NotFoundAdmin from './container/not-found-admin.js'
import PrivateRoute from './component/private-route'
import PublicRoute from './component/public-route'
import { PA_NEW_CONTRACT, PA_INVOICE } from './actions/types'
import WithLoader from './hoc/with-loader'
import md5 from 'md5'
import { isGTMEnabled, isUpsellEnabled } from './utils/functions'
import LoyaltyProgram from './container/loyalty-program'
import LoyaltyRewards from './container/loyalty-rewards'
import LoyaltyInfo from './container/loyalty-info'
import LoyaltyPartners from './container/loyalty-partners'
import { hasPermissions, OttTypes } from './utils/permissions'
import ProfileDocuments from './container/profile-documents'
// import ServicesPayment from './container/services-payment'

const Routes = (props) => {
  const {
    user,
    contract,
    validatePayment,
    requestSignUpValidation,
    goToStep,
    startLoading,
    stopLoading,
    //setPaymentAction,
    requestLogout,
  } = props

  const hasDefaultPermissions = hasPermissions(contract);
  let location = useLocation()
  const [paymentAction, setPaymentAction] = useState(() => localStorage.getItem('paymentAction') ?? null)
  useEffect(() => {
    if (isGTMEnabled()) {
      let dl = {
        dataLayer: {
          event: 'ga.pageview',
          page: {
            title: 'Telly - Zákaznická zóna',
            path: location.pathname,
            section: 'Zona',
          },
        },
      }

      if (
        location.pathname === '/' ||
        location.pathname === '/zapomenute-heslo' ||
        location.pathname === '/registrace' ||
        location.pathname === '/prvni-prihlaseni' ||
        location.pathname.includes('/ucet/profil')
      ) {
        dl.dataLayer.page.type = 'User'
      } else if (location.pathname === '/ucet/nastenka') {
        dl.dataLayer.page.type = 'Homepage'
      } else if (
        location.pathname.includes('/ucet/moje-sluzby') ||
        location.pathname.includes('/registrace/vyber-sluzby')
      ) {
        dl.dataLayer.page.type = 'Product'
      } else if (location.pathname.includes('/ucet/fakturace-a-platby')) {
        dl.dataLayer.page.type = 'Other'
      } else {
        if (user.auth) dl.dataLayer.page.type = 'Error 404'
      }

      if (user.auth) {
        dl.dataLayer.visitor = {
          id: user.id,
          email: user.email,
          hashedEmail: md5(user.email),
          type: 'customer',
        }
      }

      TagManager.dataLayer(dl)
    }
  }, [location, user])

  return (
    <Switch>
      <Route
        exact
        path="/overeni-kontaktu"
        render={(props) => {
          const searchParams = new URLSearchParams(props.location.search)
          const hash = searchParams.get('hash')
          const t = searchParams.get('t') // type
          const s = searchParams.get('s') // subtype
          const n = searchParams.get('n') // novy / editace
          const v = searchParams.get('v') // vaqlue

          if (false === user.auth) {
            return <Login {...props} />
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/ucet/profil',
                  state: {
                    hash,
                    t,
                    s,
                    n,
                    v,
                  },
                }}
              />
            )
          }
        }}
      />

      <Route
        exact
        path="/overeni/platba"
        render={(props) => {
          const searchParams = new URLSearchParams(props.location.search)
          const midas_id = searchParams.get('midas_id')
          if (!midas_id) {
            return <Redirect to="/" />
          }
          startLoading('Platba se ověřuje')
          validatePayment(midas_id)
            .then((data) => {
              if (data.status === 'PAID') {
                if (paymentAction === PA_NEW_CONTRACT) {
                  goToStep(4)
                }
              }
            })
            .catch(() => {})
            .then(() => {
              setPaymentAction(null)
              localStorage.removeItem('paymentAction')
              stopLoading()
            })
          // TODO: podle ceho rozlisovat kam presmerovat - nazev posledni akce?
          if (paymentAction === PA_NEW_CONTRACT) {
            return <Redirect to="/registrace/vyber-sluzby" />
          } else if (paymentAction === PA_INVOICE) {
            return <Redirect to="/ucet/fakturace-a-platby" />
          }
          return <Redirect to="/" />
        }}
      />

      <Route
        exact
        path="/registrace/overeni"
        render={(props) => {
          const searchParams = new URLSearchParams(props.location.search)
          const username = searchParams.get('username')
          const hash = searchParams.get('hash')

          if (username !== null && hash !== null) {
            if (user.auth) {
              requestLogout()
            }
            requestSignUpValidation({ username, hash })
              .then(() => {
                toast.info('Účet byl aktivován')
              })
              .catch((err) => {
                toast.error('Chyba při aktivaci účtu')
              })
          }
          return <Redirect to="/" />
        }}
      />

      {/* Public routes - nedostupne pro prihlaseneho uzivatele */}
      <PublicRoute
        exact
        path="/"
        component={Login}
        //render={ () => <PublicLayout><Login /></PublicLayout> }
      />

      <PublicRoute exact path="/registrace" component={Register} />

      <PublicRoute exact path="/prvni-prihlaseni" component={FirstLogin} />

      <PublicRoute exact path="/aktivace" component={FirstLoginValidation} />

      <PublicRoute
        exact
        path="/zapomenute-heslo"
        component={PasswordRecovery}
      />

      <PublicRoute exact path="/obnoveni-hesla" component={PasswordCreate} />

      <Route exact path="/zavolejte-mi" component={CallMe} />

      {/* Admin routes */}
      <PrivateRoute
        exact
        path="/registrace/vyber-sluzby"
        component={ServicesNew}
      />

      <Route
        exact
        path="/ucet/aktivace"
        render={(props) => {
          if (user.auth) {
            if (!user.active) {
              return <ProfileActivation />
            } else {
              return <Redirect to="/ucet/nastenka" />
            }
          } else {
            return <Redirect to="/" />
          }
        }}
      />

      <PrivateRoute exact path="/ucet/nastenka" component={Dashboard} />

      {isUpsellEnabled() && hasPermissions(contract, [OttTypes.CLASSIC]) && (
        <PrivateRoute exact path="/ucet/moje-sluzby" component={Services} />
      )}

      {hasDefaultPermissions &&
        <PrivateRoute
          exact
          path="/ucet/moje-sluzby/prehled-sluzeb"
          component={ServiceList}
        />
      }

      <PrivateRoute exact path="/ucet/moje-sluzby/:step" component={Services} />

      {hasDefaultPermissions &&
        <PrivateRoute
          exact
          path="/ucet/fakturace-a-platby"
          component={Invoices}
        />
      }

      {hasPermissions(contract, [OttTypes.CLASSIC]) &&
        <PrivateRoute
          exact
          path="/ucet/fakturace-a-platby/nastaveni-plateb"
          component={InvoicesSetup}
        />
      }

      {hasDefaultPermissions &&
        <PrivateRoute exact path="/ucet/profil" component={Profile} />
      }

      <PrivateRoute
        exact
        path="/ucet/profil/nastaveni"
        component={ProfileSetup}
      />

      <PrivateRoute exact path="/ucet/profil/zpravy" component={Inbox} />

      <PrivateRoute exact path="/ucet/profil/dokumenty" component={ProfileDocuments} />

      <PrivateRoute
        exact
        path="/ucet/profil/zpravy/:filter_opened"
        component={Inbox}
      />

      <PrivateRoute
        exact
        path="/ucet/profil/zprava/:id"
        component={InboxMessage}
      />

      {hasDefaultPermissions &&
        <>
          <PrivateRoute
            exact
            path="/ucet/vernostni-program/prehled"
            component={LoyaltyProgram}
          />
          <PrivateRoute
            exact
            path="/ucet/vernostni-program/moje-odmeny"
            component={LoyaltyRewards}
          />

          <PrivateRoute
            exact
            path="/ucet/vernostni-program/info"
            component={LoyaltyInfo}
          />

          <PrivateRoute
            exact
            path="/ucet/vernostni-program/partneri"
            component={LoyaltyPartners}
          />
        </>
      }

      <PrivateRoute exact path="/ucet/profil" component={Profile} />

      <PrivateRoute exact path="/ucet/:id/payment" component={Profile} />

      <Route
        render={(props) => {
          let notFound
          if (false === user.auth) {
            notFound = <NotFoundFront />
          } else {
            notFound = <NotFoundAdmin />
          }

          return notFound
        }}
      />
    </Switch>
  )
}

export default WithLoader(Routes)
