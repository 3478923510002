import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import { SET_REFRESHING_INTERVAL, SET_REFRESHING_STEP } from '../actions/types'
import { useInterval } from '../utils/hooks'

const LoaderContract = ({ maxNotices = 0, onComplete = null, progressBarVisible = true, isUpsell = false }) => {
  // const [currentStep, setCurrentStep] = useState(0)
  // const [delay, setDelay] = useState(2000)
  const [isRunning, setIsRunning] = useState(true)

  // redux store
  const dispatch = useDispatch()
  const content = useSelector((state) => state.translation.content)
  const refreshingInterval = useSelector(
    (state) => state.contract.refreshingInterval,
  )
  const currentStep = useSelector((state) => state.contract.refreshingStep)

  // actions
  const setCurrentStep = (step) => {
    dispatch({ type: SET_REFRESHING_STEP, payload: step })
  }
  const setDelay = (delay) => {
    dispatch({ type: SET_REFRESHING_INTERVAL, payload: delay })
  }

  useInterval(
    () => {
      if (currentStep === 0) {
        setDelay(60000 / maxNotices)
      }
      if (currentStep >= maxNotices + 1) {
        if (typeof onComplete === 'function') {
          onComplete()
        }
        return setIsRunning(false)
      }
      setCurrentStep(currentStep + 1)
    },
    isRunning ? refreshingInterval : null,
  )

  return (
    <div className={`loader loader--${currentStep}`}>
      {progressBarVisible && (
        <>
          <div className={`vertical vertical--${currentStep}`} />
          <ul className={`loader__notice`}>
            {[...Array(maxNotices).keys()].map((n) => (
              <li key={n}>
                {content[`content.${isUpsell ? 'upsell' : 'new'}.contract.complete.notice.${n}`] &&
                  sanitizeHtml(
                    content[`content.${isUpsell ? 'upsell' : 'new'}.contract.complete.notice.${n}`],
                    { allowedTags: [], allowedAttributes: [] },
                  )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default LoaderContract
