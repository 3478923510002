import React, { Component } from 'react'
import { connect } from 'react-redux'
import FirstLoginFormFirstStep from '../../form/first-login-form-first-step'
import { requestFirstLogin } from '../../actions/login'
import { getFirstLoginAcknowledgments } from '../../actions/acknowledgments'
import PublicLayout from '../../layout/public-layout'

import DigiModalContext from '../../context/digi-modal-context'
import ConfirmationNote from '../../component/confirmation-note'
import Loading from '../../component/loading'

class FirstLogin extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      acks: null,
      loading: true,
    }
  }

  componentDidMount() {
    this.props
      .getFirstLoginAcknowledgments()
      .then((data) => {
        this.setState({
          acks: data.data,
          loading: false,
        })
      })
      .catch((err) => {
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const { content } = this.props

    return (
      <PublicLayout>
        {this.state.loading && <Loading />}
        <FirstLoginFormFirstStep
          acks={this.state.acks}
          onSubmit={(values) =>
            this.props.requestFirstLogin(values).then(() => {
              this.context.toggleDigiModal(
                <ConfirmationNote
                  text={
                    content['content.login.first.email.confirm'] &&
                    content['content.login.first.email.confirm'].replace(
                      '%email%',
                      values.email
                    )
                  }
                  close={() => {
                    this.context.toggleDigiModal()
                    this.props.history.push('/')
                  }}
                />
              )
            })
          }
          content={this.props.content}
        />
      </PublicLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.translation.content,
  }
}

export default connect(mapStateToProps, {
  requestFirstLogin,
  getFirstLoginAcknowledgments,
})(FirstLogin)
