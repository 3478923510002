import React, { Component } from 'react'

import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import DigiModalContext from '../context/digi-modal-context'
import { requestLogout } from '../actions/login'
import { modalStyle } from '../utils/modal-style'
import { requestCall } from '../actions/admin'
import CallMeFormLogged from '../form/call-me-form-logged'
import phoneLogo from './../assets/images/ico_phone-phone.svg';

import sanitizeHtml from 'sanitize-html'

class AdminHeader extends Component {
  constructor(props) {
    super(props)
    this.callMe = this.callMe.bind(this)
    this.callMeSuccess = this.callMeSuccess.bind(this)

    this.state = {
      message: false,
      phone: null,
    }
  }

  callMe(values) {
    this.setState({
      message: false,
    })
    const send_values = {
      source: 'help',
      customer: this.props.user.id,
      phone: values.phone ? values.phone : values.baseMobilePhone,
    }
    return this.props.requestCall(send_values)
  }

  callMeSuccess() {
    this.setState({
      message: <div>Váš požadavek byl úspěšně odeslán.</div>,
    })
  }

  render() {
    if (false === this.props.user.auth) {
      return <Redirect to="/" />
    }

    let phone = null
    if (this.props.user.contacts) {
      phone = this.props.user.contacts.find((item) => {
        return item.value && item.type === 'mobile' && item.subtype === 'base'
      })
    }

    const baseMobilePhone = phone ? phone.value : null
    const { help } = this.props

    const sanitizedTitle = sanitizeHtml(this.props.title, {
      allowedTags: [],
      allowedAttributes: [],
    })
    const sanitizedSubtitle = sanitizeHtml(this.props.subtitle, {
      allowedTags: [],
      allowedAttributes: [],
    })

    return (
      <DigiModalContext.Consumer>
        {({ setStyle, toggleDigiModal }) => (
          <React.Fragment>
            <header className="row">
              <div className="col col--is--12 col--lg--8 col--xl--9 col--is--last col--lg--reset">
                <h1>
                  {this.props.title && (
                    <span>
                      <Link to={this.props.link}>{sanitizedTitle}</Link>{' '}
                    </span>
                  )}
                  {this.props.title && this.props.subtitle && (
                    <span className="admin__content__separator">/</span>
                  )}

                  {this.props.subtitle && (
                    <span className="admin__content__subtitle">
                      {sanitizedSubtitle}
                    </span>
                  )}
                  <span className="relative">
                    {help && (
                      <button
                        className="btn--help"
                        type="button"
                        onClick={() => {
                          setStyle(modalStyle)
                          toggleDigiModal(help, '', true)
                        }}
                      >
                        nápověda
                      </button>
                    )}
                  </span>
                </h1>
              </div>
              <div className="col col--is--12 col--lg--4 col--xl--3">
                <div className="admin__menu__wrap">

                  <div
                  className="logo-phone"
                      onClick={() => {
                    setStyle(modalStyle)
                    toggleDigiModal(
                        <CallMeFormLogged
                            content={this.props.content}
                            successMessage={this.state.message}
                            onSubmit={(values) =>
                                this.callMe(values).then(() => toggleDigiModal())
                            }
                            onSubmitSuccess={this.callMeSuccess}
                            initialValues={{ baseMobilePhone: baseMobilePhone }}
                            baseMobilePhone={baseMobilePhone}
                        />
                    )
                  }}>
                    <img src={phoneLogo} alt="" />
                  </div>
                  <div className="admin__user">
                    <div
                      className="call-me"
                      onClick={() => {
                        setStyle(modalStyle)
                        toggleDigiModal(
                          <CallMeFormLogged
                            content={this.props.content}
                            successMessage={this.state.message}
                            onSubmit={(values) =>
                              this.callMe(values).then(() => toggleDigiModal())
                            }
                            onSubmitSuccess={this.callMeSuccess}
                            initialValues={{ baseMobilePhone: baseMobilePhone }}
                            baseMobilePhone={baseMobilePhone}
                          />
                        )
                      }}
                    >
                      Nevím si rady, zavolejte mi
                    </div>
                    <div className="admin__user__info">
                      <Link className="user" to="/ucet/profil">
                        {this.props.user.name} {this.props.user.surname}
                      </Link>
                      <button
                        onClick={() => {
                          this.setState({ logouting: true })
                          return this.props.requestLogout()
                        }}
                        className="btn btn--primary admin__user__info__button"
                        type="button"
                        disabled={this.state.logouting}
                      >
                        Odhlásit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </React.Fragment>
        )}
      </DigiModalContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    user: state.user,
  }
}

export default connect(mapStateToProps, {
  requestLogout,
  requestCall,
})(AdminHeader)
