import React from 'react'
import { downloadFile } from '../utils/functions'

const AckSC = ({ ack, children }) => {
  const scRegex = /\[(.*?)\]/g

  const SC = ({ click, text }) => <span onClick={click}>{text}</span>

  let type,
    file,
    title,
    _scs = []
  let _t = ack.content.replace(scRegex, (_text, a) => {
    let parts = a.split(' ')
    type = parts[0]
    file = parseInt(parts[1].split('=')[1].replace('"', '').replace('"', ''))
    title = parts[2].split('=')[1].replace('"', '')
    if (title.substr(title.length - 1, 1) !== '"') {
      title += ` ${parts[3]}`
    }
    title = title.replace('"', '')
    _scs.push({
      type,
      file,
      title,
    })
    return `[sc]`
  })
  const scTextParts = _t.split('[sc]')

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          LabelComponent: () => (
            <div className={'acknowledgment__box'}>
              {scTextParts.map((tp, i) => (
                <React.Fragment key={i}>
                  {tp}
                  {_scs[i] && (
                    <SC
                      text={_scs[i].title}
                      click={(e) => {
                        e.preventDefault()
                        ack &&
                          ack.files &&
                          downloadFile(ack.files.type[_scs[i].file])
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          ),
        })
      )}
    </div>
  )
}

export default AckSC
