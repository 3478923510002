export const defaultServiceTypes = ['TV_SAT', 'TV_HYBRID', 'TV_OTT'];

export const OttTypes = {
  CLASSIC: 4,
  XD: 5,
  TO: 9,
}

export const hasPermissions = (contract, ottTypes = Object.values(OttTypes)) => {
  if(contract && defaultServiceTypes.includes(contract.portfolio.group)) {

    if(contract.portfolio.group === 'TV_OTT' && (!contract.portfolio_branch || ottTypes.includes(contract.portfolio_branch))) {
      return true;
    } else if(contract.portfolio.group !== 'TV_OTT') {
      return true;
    }
  }

  return false;
}