import React, { Component, useState } from 'react'
//import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
// import AdminHeader from '../../component/admin-header'
import ContractHoc from '../../hoc/contract-hoc'
import {
  getContractInvoices,
  downloadInvoice,
  createPayment,
  validatePayment,
} from '../../actions/invoices'
import { setPaymentAction } from '../../actions/services'
import Loading from '../../component/loading'
import Pagination from '../../component/pagination'
// import Banner from '../../component/banner'
import {
  formatCurrency,
  formatDate,
  dateDiffInDays,
  dtApiText,
} from '../../utils/functions'

import { modalStyle } from '../../utils/modal-style'

import AdminContent from '../../layout/admin-content'
import tellyLogo from '../../assets/images/logo.svg'
import { PA_INVOICE } from '../../actions/types'
import { getContractFromState } from '../../utils/contract'
import { hasPermissions, OttTypes } from '../../utils/permissions'

//import gopay from "gopay-node";
//var gopay = require('gopay-nodejs');

const printInvoiceStatus = (invoice) => {
  return (
    <React.Fragment>
      <p className={`scream scream--${invoice.status}`}>
        {dtApiText(invoice.status)}
      </p>
      {invoice.debt > 0 && (
        <span className="small-note">
          (zůstává uhradit: {formatCurrency(invoice.debt)})
        </span>
      )}
      {invoice.overpayment > 0 && (
        <span className="small-note">
          (přeplatek: {formatCurrency(invoice.overpayment)})
        </span>
      )}
    </React.Fragment>
  )
}

const InvoiceList = ({
  invoices = {
    ids: [],
    meta: {
      debt: 0,
    },
  },
  createPayment,
  loading = false,
  downloadInvoice,
  paginate,
  contract_id,
  contract
}) => {
  const [isLoading, setLoading] = useState(false)

  return (
    <React.Fragment>
      {isLoading && <Loading className="ui__loading--fixed" />}
      {hasPermissions(contract, [OttTypes.CLASSIC]) &&
        <div className="info__box info__box--invoices section">
          <div className="flex-middle">
            <div>
              <p>
                Suma neuhrazených faktur:{' '}
                <strong>{formatCurrency(invoices.meta.debt)}</strong>
              </p>

              {parseFloat(invoices.meta.overpayment) > 0 && (
                <p>
                  Přeplatek{' '}
                  <strong>{formatCurrency(invoices.meta.overpayment)}</strong>
                </p>
              )}
            </div>
          </div>

          <div>
            {invoices.meta && invoices.meta.debt > 0 && (
              <button
                className="btn btn--primary  btn--next"
                onClick={() =>
                  createPayment(contract_id, invoices.meta.unpaidInvoices, true)
                }
              >
                Uhradit celou dlužnou částku
              </button>
            )}
          </div>
        </div>
      }

      <table className="digi-table digi-table--invoices">
        <thead>
          <tr>
            <td className="col-1">
              <div className="cell">Období</div>
            </td>
            <td className="col-2" />
            <td className="col-3">
              <div className="cell">Stav</div>
            </td>
            <td className="col-4">
              <div className="cell">Suma</div>
            </td>
            <td className="col-5">
              <div className="cell">Datum splatnosti</div>
            </td>
            <td />
          </tr>
        </thead>
        <tbody>
          {invoices.ids.map((invoice_id, index) => {
            const invoice = invoices.items[invoice_id]
            const invoiceCreated = new Date(invoice.dateCreate)
            const invoiceMaturity = new Date(invoice.dateMaturity)
            const days_diff = dateDiffInDays(new Date(), invoiceMaturity)

            return (
              <tr
                key={invoice_id}
                className={`${1 === index % 2 ? 'even' : ''}`}
              >
                <td className="col-1">
                  <div className="cell">
                    <span className="cell__label">
                      <b>Období</b>
                    </span>
                    <span className="cell__content slim">
                      {invoiceCreated.getMonth() + 1}/
                      {invoiceCreated.getFullYear()}
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div className="cell">
                    <span className="cell__label">
                      <b>Faktura</b>
                    </span>
                    <span className="cell__content">
                      <button
                        type="button"
                        onClick={async () => {
                          setLoading(true)
                          await downloadInvoice(contract_id, invoice_id)
                          setLoading(false)
                        }}
                        className="invoice-pdf relative"
                      >
                        <i className="ico ico-pdf" /> <span>pdf</span>{' '}
                        <i className="ico ico-next ico-next--gray" />
                      </button>
                    </span>
                  </div>
                </td>
                <td className="col-3">
                  <div className="cell">
                    <span className="cell__label">
                      <b>Stav</b>
                    </span>
                    <span className="cell__content">
                      {printInvoiceStatus(invoice)}
                    </span>
                  </div>
                </td>
                <td className="col-4">
                  <div className="cell">
                    <span className="cell__label">
                      <b>Suma</b>
                    </span>
                    <span className="cell__content">
                      {formatCurrency(invoice.price)}
                    </span>
                  </div>
                </td>
                <td className="col-4">
                  <div className="cell">
                    <span className="cell__label">
                      <b>Datum splatnosti</b>
                    </span>
                    <span className="cell__content">
                      <span className="payment-issue">
                        {formatDate(invoice.dateMaturity)}
                      </span>{' '}
                      {invoice.debt > 0 && (
                        <span className="payment-note">
                          {days_diff < 1 && (
                            <React.Fragment>Po splatnosti</React.Fragment>
                          )}
                          {days_diff >= 1 && (
                            <React.Fragment>
                              Ještě zbývá {days_diff} dní
                            </React.Fragment>
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                </td>
                <td className="col-5">
                  {invoice.debt > 0 && hasPermissions(contract, [OttTypes.CLASSIC]) && (
                    <div className="cell">
                      <button
                        onClick={() => createPayment(contract_id, invoice_id)}
                        className="btn btn--primary  btn--next btn--block--xs"
                      >
                        Uhradit
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Pagination pagination={invoices.meta.pagination} paginate={paginate} />
    </React.Fragment>
  )
}

class Invoices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current_page: 1,
      loading: false,
      lastInvoiceModal: false,
      constract_id: '',
    }
    this.paginate = this.paginate.bind(this)
    this.downloadInvoice = this.downloadInvoice.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.closeLastInvoiceModal = this.closeLastInvoiceModal.bind(this)
  }

  componentDidMount() {
    this.props.getContractInvoices(this.props.contract_id)
    var script = document.createElement('script')
    script.src = 'https://gw.sandbox.gopay.com/gp-gw/js/embed.js'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.current_nr !== prevProps.current_nr &&
        this.props.contract_nr_items &&
        this.props.contract_nr_items[this.props.current_nr]) ||
      (this.props.contract_nr_items !== prevProps.contract_nr_items &&
        this.props.current_nr &&
        this.props.contract_nr_items[this.props.current_nr])
    ) {
      this.props.getContractInvoices(this.props.contract_id)
    }

    if (this.props.current_nr !== prevProps.current_nr) {
      this.setState({
        current_page: 1,
      })
    }
  }

  paginate(page) {
    this.props.getContractInvoices(this.props.contract_id, page)
  }

  downloadInvoice(contract_id, invoice_id) {
    return this.props.downloadInvoice(contract_id, invoice_id)
  }

  createPayment(contract_id, invoice_id, all = false) {
    if (
      !all &&
      invoice_id !== this.props.invoices[contract_id].meta.lastUnpaidInvoice.id
    ) {
      this.setState({
        lastInvoiceModal: true,
        contract_id: contract_id,
      })
    } else {
      this.setState({
        loading: true,
        lastInvoiceModal: false,
      })
      this.props
        .createPayment(contract_id, invoice_id)
        .then((data) => {
          this.props.setPaymentAction(PA_INVOICE)
          localStorage.setItem('paymentAction', PA_INVOICE)
          window._gopay.checkout(
            {
              gatewayUrl: data.gate_url,
              inline: true,
            },
            async function (ret) {
              try {
                await this.props.validatePayment(data.midas_id)
                await this.props.getContractInvoices(contract_id)
              } catch (error) {
              } finally {
                this.setState({
                  loading: false,
                })
              }
            }.bind(this)
          )
        })
        .catch((error) => {
          this.setState({
            loading: false,
          })
        })
    }
  }

  closeLastInvoiceModal() {
    this.setState({
      lastInvoiceModal: false,
    })
  }

  render() {
    const { content, help } = this.props

    return (
      <div className="relative">
        {this.state.loading && (
          <Loading
            className="global-loading"
            text="Probíhá přesměrování na platební bránu"
          />
        )}

        <AdminContent
          advertisementTarget="invoice.overview.banner"
          title="Faktury a platby"
          subtitle={content['content.invoice.overview.title']}
          help={help['help.invoice.overview.help']}
          link="/ucet/fakturace-a-platby"
        >
          <Modal
            appElement={document.getElementById('root')}
            style={{
              ...modalStyle,
              content: {
                ...modalStyle['content'],
                maxWidth: '680px',
              },
            }}
            isOpen={this.state.lastInvoiceModal}
          >
            <div className="popup-headline">
              <div className="row">
                <div className="col col--is--12 col--xm--5">
                  <div className="intro__header__logo">
                    <img src={tellyLogo} alt="intro logo" />
                  </div>
                </div>
              </div>
            </div>

            <button
              className="button-close"
              onClick={this.closeLastInvoiceModal}
            >
              zavřít
            </button>

            <div className="intro__text intro__text--extra-step">
              <div className="row">
                <div className="col">
                  {this.props.invoices && this.state.contract_id && (
                    <div>
                      <p>
                        Pro uhrazení této faktury je nezbytné nejprve zaplatit
                        všechny neuhrazené starší faktury a dlužné částky.
                      </p>

                      <p>
                        <button
                          type="button"
                          className="btn btn--primary"
                          onClick={() =>
                            this.createPayment(
                              this.state.contract_id,
                              this.props.invoices[this.state.contract_id].meta
                                .lastUnpaidInvoice.id
                            )
                          }
                        >
                          Uhradit nejstarší nezaplacenou fakturu –{' '}
                          {new Date(
                            this.props.invoices[
                              this.state.contract_id
                            ].meta.lastUnpaidInvoice.dateCreate
                          ).getMonth() + 1}
                          /
                          {new Date(
                            this.props.invoices[
                              this.state.contract_id
                            ].meta.lastUnpaidInvoice.dateCreate
                          ).getFullYear()}{' '}
                          ({' '}
                          {formatCurrency(
                            this.props.invoices[this.state.contract_id].meta
                              .lastUnpaidInvoice.price -
                              this.props.invoices[this.state.contract_id].meta
                                .lastUnpaidInvoice.price_paid
                          )}{' '}
                          )
                        </button>
                      </p>
                      <p>
                        <button
                          type="button"
                          className="btn btn--primary"
                          onClick={() =>
                            this.createPayment(
                              this.state.contract_id,
                              this.props.invoices[this.state.contract_id].meta
                                .unpaidInvoices,
                              true
                            )
                          }
                        >
                          Uhradit celou dlužnou částku (Celkem{' '}
                          {formatCurrency(
                            this.props.invoices[this.state.contract_id].meta
                              .debt
                          )}
                          )
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>

          <div className="row row--big">
            <div className="col col--big">
              <div className="admin__inner relative">
                <InvoiceList
                  invoices={this.props.invoices[this.props.contract_id]}
                  createPayment={this.createPayment}
                  loading={this.props.loading}
                  downloadInvoice={this.downloadInvoice}
                  contract_id={this.props.contract_id}
                  paginate={this.paginate}
                  contract={this.props.contract}
                />
              </div>
            </div>
          </div>
        </AdminContent>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    help: state.translation.help,
    content: state.translation.content,
    current_nr: state.current_nr.value,
    contract_nr_items: state.contract_nr.items,
    invoices: state.contract_invoices || [],
    loading: state.contract_invoices.loading,
    contract: getContractFromState(state),
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getContractInvoices,
    downloadInvoice,
    createPayment,
    validatePayment,
    setPaymentAction,
  })(ContractHoc(Invoices))
)
