import React from 'react'

const Progress = ({ labels = [], current = 1, goToStep }) => {
  return (
    <ul className="progress">
      {labels.map((label, index) => (
        <li
          key={index}
          className={`progress__item ${
            current === index + 1
              ? `progress__item--active`
              : current > index + 1 && 'progress__item--finished'
          }`}
        >
          <span
            className="progress_label"
            onClick={() => {
              if (index + 1 <= current) {
                goToStep(index + 1)
              }
            }}
          >
            {label}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default Progress
