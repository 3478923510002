import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminHeader from '../component/admin-header'
import AdminNav from '../component/admin-nav.js'
import { getAdvertisement } from '../actions/app'

const POSITION_TOP = 'top'
const POSITION_BOTTOM = 'bottom'
const POSITION_RIGHT = 'right'

class AdminContent extends Component {
  componentDidMount() {
    if (this.props.advertisementTarget) {
      this.props.getAdvertisement(this.props.advertisementTarget, POSITION_TOP)
      this.props.getAdvertisement(
        this.props.advertisementTarget,
        POSITION_RIGHT
      )
      this.props.getAdvertisement(
        this.props.advertisementTarget,
        POSITION_BOTTOM
      )
    }
  }

  render() {
    const {
      advertisementTarget,
      advertisement,
      title,
      subtitle,
      link,
      help,
    } = this.props

    return (
      <div className="admin">
        <AdminNav
          //unreadMessages={this.props.unreadMessages}
          //location={this.props.location}
          {...this.props}
        />

        <div className="grid grid--big">
          <div className="admin__content">
            <AdminHeader
              title={title}
              subtitle={subtitle}
              link={link}
              help={help}
            />

            {advertisement[advertisementTarget] &&
              advertisement[advertisementTarget][POSITION_TOP] &&
              advertisement[advertisementTarget][POSITION_TOP].content && (
                <div
                  className={`banner banner--top`}
                  dangerouslySetInnerHTML={{
                    __html:
                      advertisement[advertisementTarget][POSITION_TOP].content,
                  }}
                />
              )}

            <div className="row mb0">
              <div className="col col--sm">{this.props.children}</div>

              {advertisement[advertisementTarget] &&
                advertisement[advertisementTarget][POSITION_RIGHT] &&
                advertisement[advertisementTarget][POSITION_RIGHT].content && (
                  <div className="col col--banner">
                    <div
                      className={`banner banner--side`}
                      dangerouslySetInnerHTML={{
                        __html:
                          advertisement[advertisementTarget][POSITION_RIGHT]
                            .content,
                      }}
                    />
                  </div>
                )}
            </div>

            {advertisement[advertisementTarget] &&
              advertisement[advertisementTarget][POSITION_BOTTOM] &&
              advertisement[advertisementTarget][POSITION_BOTTOM].content && (
                <div className="row">
                  <div className="col">
                    <div
                      className={`banner banner--bottom`}
                      dangerouslySetInnerHTML={{
                        __html:
                          advertisement[advertisementTarget][POSITION_BOTTOM]
                            .content,
                      }}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    advertisement: state.advertisement,
  }
}

export default connect(
  mapStateToProps,
  {
    getAdvertisement,
  }
)(AdminContent)
