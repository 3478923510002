import React, { Component } from 'react'
import AcknowledgmentForm from '../form/acknowledgment-form'
import DigiModalContext from '../context/digi-modal-context'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

const LoyaltyConfirmModalHoc = (WrappedComponent) => {
  return class extends Component {
    static contextType = DigiModalContext

    componentDidMount() {
      if (this.props.acknowledgment?.confirmed === false) {
        this.context.toggleDigiModal(
          <>
            <div dangerouslySetInnerHTML={{
              __html: sanitizeHtml(this.props.content['content.loyalty.popup.confirm'], {
                allowedTags: ALLOWED_TAGS,
                allowedAttributes: ALLOWED_ATTRIBUTES,
              }),
            }} />
            <AcknowledgmentForm acks={[this.props.acknowledgment]}
                                toggleDigiModal={this.context.toggleDigiModal}
            />
          </>, '', false, () => {
            this.props.history.push('/ucet/nastenka')
            return true
          })
      }
    }

    render() {
      return (
        <DigiModalContext.Consumer>
          {() => (
            <WrappedComponent
              {...this.props}
            />
          )}
        </DigiModalContext.Consumer>
      )
    }
  }
}

export default LoyaltyConfirmModalHoc