import React, { Component } from 'react'
import { connect } from 'react-redux'

import PublicLayout from '../layout/public-layout'
import { requestConfirmationEmail } from '../actions/profile'

class ProfileActivation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clicked: false,
      btnText: 'Přeposlat e-mail',
    }
  }

  render() {
    return (
      <PublicLayout>
        <div className={'modal__body'}>
          <div className={'modal__title'}>Potvrďte e-mailovou adresu</div>
          <p className={'modal__subtitle modal__subtitle--blue'}>
            Na e-mail "{this.props.email}" jsme vám zaslali přihlašovací údaje a
            potvrzení o registraci. Prosím autorizujte svou e-mailovou adresu
            kliknutím na odkaz v e-mailové zprávě.
          </p>
          <p>
            Pokud nemůžete e-mail s potvrzením najít, zkontrolujte prosím složku
            nevyžádané pošty (Spam, Junk).
          </p>
          <p>
            Služby Telly si můžete objednat po ověření e-mailové adresy a po
            přihlášení do portálu.
          </p>
          <div className={'modal__actions'}>
            <button
              className="btn btn--primary"
              onClick={() => {
                this.setState({
                  clicked: true,
                  btnText: 'Odesláno',
                })
                this.props.requestConfirmationEmail()
              }}
              disabled={this.state.clicked}
            >
              {this.state.btnText}
            </button>
          </div>
        </div>
      </PublicLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}

export default connect(mapStateToProps, {
  requestConfirmationEmail,
})(ProfileActivation)
