import { SET_CURRENT_CONTRACT } from '../actions/types'


const INITIAL_STATE = {
  value: null,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_CONTRACT:
      return {
        ...state,
        value: action.payload,
      }
    // no default
  }
  return state
}
