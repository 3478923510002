import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import sanitizeHtml from 'sanitize-html'

import LoaderContract from '../component/loader-contract'

// import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

import { finishRegistration, requestNewServices } from '../actions/services'
import { getContract } from '../actions/admin'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'
import { isGTMEnabled } from '../utils/functions'
import TagManager from 'react-gtm-module'
// import TagManager from 'react-gtm-module'
// import { isGTMEnabled } from '../utils/functions'

let refreshTimeout

class ServicesActivationStep4Upsell extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    if (isGTMEnabled() && !this.props.GTMPushed) {
      // TODO: odeslat push do GTM s ID kontraktu, ALE!!! kontrakt se prece vytvari nejakou dobu, odeslat push pote co prijde kontrakt??
      const { overview, portfolioKey } = this.props
      const { packages } = overview

      let products = []
      const category =
        portfolioKey === 'TV_OTT'
          ? 'Internetová televize'
          : 'Satelitní televize'

      for (const i in packages) {
        if (i === 'installation') continue
        if (packages.hasOwnProperty(i) && typeof packages[i] === 'object') {
          for (const x in packages[i]) {
            if (packages[i].hasOwnProperty(x)) {
              const element = packages[i][x]
              // console.log(element)
              products.push({
                item_name: element.name,
                item_id: element.group,
                price: element.price,
                item_brand: 'Telly',
                item_category: 'Televize',
                item_variant: category,
                quantity: 1,
              })
            }
          }
        }
      }

      let dl = {
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            currency: 'CZK',
            value: overview.price,
            tax: overview.priceVat - overview.price,
            affiliation: 'zona.telly.cz',
            purchaseType: 'upsell',
            transaction_id: new Date().getTime(), // nebo si pockat na ID kontraktu
            totalPaidByCustomer: overview.priceVat,
            recurringPayment: overview.priceNextMonth,
            recurringPaymentWithVat: overview.priceVatNextMonth,
            items: products,
          },
        },
      }
      // console.log(dl)
      TagManager.dataLayer(dl)
    }
  }

  refetchContracts = async () => {
    const res = await this.props.getContract()
    if (res.data.length > 0) {
      clearTimeout(refreshTimeout)
    } else {
      refreshTimeout = setTimeout(this.refetchContracts, 5000)
    }
   this.clearAndRedirect();
  }

  clearAndRedirect() {
    this.props.finishRegistration();
    this.props.history.push('/ucet/nastenka')
  }

  render() {
    const { portfolioKey, content, overview } = this.props

    if (Object.keys(overview).length === 0) {
      this.props.history.push('/ucet/nastenka')
    }

    // console.log(overview);

    const devices = Object.entries(overview.packages.device)
    const jeSamoInstalace = Object.keys(
      overview.packages.installation
    ).includes('2')

    let secondLineKey

    if (jeSamoInstalace && devices.length === 0) {
      //! samo instalace bez zarizeni
      secondLineKey = `content.upsell.contract.${portfolioKey}.complete.self.no_device`
    } else if (jeSamoInstalace && devices.length > 0) {
      //! samo instalace s 1 zarizenim
      secondLineKey = `content.upsell.contract.${portfolioKey}.complete.self.more_devices`
    } else if (!jeSamoInstalace && devices.length >= 1) {
      //! technik s vice jak 1 zarizenim
      secondLineKey = `content.upsell.contract.${portfolioKey}.complete.technik.more_devices`
    } else {
      console.log('nastala vyjimecna situace')
    }

    return (
      <div className="">
        <div className={'modal__body'}>
          <div
            className={'modal__title'}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                content[`content.upsell.contract.${portfolioKey}.complete.title`],
                {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                },
              ),
            }}
          />
          <p
            className={'modal__subtitle'}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                content[`content.upsell.contract.${portfolioKey}.complete`],
                {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                },
              ),
            }}
          />
          {secondLineKey !== undefined && <p
            className={'modal__subtitle'}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content[secondLineKey], {
                allowedTags: ALLOWED_TAGS,
                allowedAttributes: ALLOWED_ATTRIBUTES,
              }),
            }}
          />}

          <LoaderContract maxNotices={5} onComplete={this.refetchContracts} progressBarVisible={false} isUpsell={true} />
          <button
            type="button"
            className="btn btn--primary"
            onClick={() => {
             this.clearAndRedirect();
            }}
          >
            Pokračovat na nástěnku
          </button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    overview: state.contract_upsell.overview,
    content: state.translation.content,
  }
}

ServicesActivationStep4Upsell = withRouter(ServicesActivationStep4Upsell)

export default connect(mapStateToProps, {
  finishRegistration,
  requestNewServices,
  getContract,
})(ServicesActivationStep4Upsell)
