import React, { Component } from 'react'
import DigiModalContext from '../context/digi-modal-context'
import Modal from 'react-modal'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'
import tellyLogo from '../assets/images/logo-blue.svg'

import { modalStyle } from '../utils/modal-style'

class DigiModal extends Component {
  constructor(props) {
    super(props)

    const { content = '', style = modalStyle, title = '', html = '' } = props
    this.state = {
      open: !!content,
      content: content,
      style: style,
      title: title,
      html: html,
    }

    this.toggleDigiModal = this.toggleDigiModal.bind(this)
    this.setStyle = this.setStyle.bind(this)
  }

  toggleDigiModal(content = '', title = '', html = false, onClose = null) {
    this.setState(() => ({
        title: title,
        content: content,
        open: !!content,
        html: html,
        onClose,
      }),
    )
  }

  setStyle(style) {
    this.setState({
      style: style,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          appElement={document.getElementById('root')}
          style={this.state.style}
          isOpen={this.state.open}
        >
          <button
            className="button-close"
            onClick={() => {
              if (typeof this.state.onClose === 'function') {
                this.state.onClose() && this.toggleDigiModal()
              } else {
                this.toggleDigiModal()
              }
            }}
          >
            zavřít
          </button>
          <div className="popup-headline">
            <div className="row">
              <div className="col col--is--12 col--xm--5">
                <div className="intro__header__logo">
                  <img src={tellyLogo} alt="" />
                </div>
              </div>
            </div>
          </div>

          {this.state.title && (
            <div className="row">
              <div className="col col--is--12">
                <h2 className="h2--pop">{this.state.title}</h2>
              </div>
            </div>
          )}

          {this.state.content && (
            <React.Fragment>
              {this.state.html ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(this.state.content, {
                      allowedTags: ALLOWED_TAGS,
                      allowedAttributes: ALLOWED_ATTRIBUTES,
                    }),
                  }}
                />
              ) : (
                this.state.content
              )}
            </React.Fragment>
          )}
        </Modal>
        <DigiModalContext.Provider
          value={{
            state: this.state,
            setStyle: this.setStyle,
            toggleDigiModal: this.toggleDigiModal,
          }}
        >
          {this.props.children}
        </DigiModalContext.Provider>
      </React.Fragment>
    )
  }
}

export default DigiModal
