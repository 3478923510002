import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import sanitizeHTML from 'sanitize-html'

import ContractHoc from '../hoc/contract-hoc'
import BtnCardReactivation from '../component/btn-card-reactivation'
// import WithLoader from '../hoc/with-loader'
import AdminContent from '../layout/admin-content'

import {
  getContract,
  getContractPackages,
  getContractDevices,
  getContractInfo,
} from '../actions/admin'

import { startLoading, stopLoading } from '../actions/app'

import LiveTvButton from '../component/live-tv'
import { formatDate } from '../utils/functions'

class HiddenUnderLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.toggleLink = this.toggleLink.bind(this)
  }

  toggleLink() {
    this.setState({
      show: !this.state.show,
    })
  }

  render() {
    return (
      <span>
        {this.state.show ? (
          this.props.value
        ) : (
          <button
            onClick={this.toggleLink}
            className="a--arrow green intro__links__link link"
          >
            {this.props.label}
          </button>
        )}
      </span>
    )
  }
}

class ServiceList extends Component {
  componentDidMount() {
    this.props.startLoading()
    Promise.all([
      this.props.getContractPackages(this.props.contract_id).catch((err) => {
        // console.log(err)
      }),
      this.props.getContractDevices(this.props.contract_id).catch((err) => {
        // console.log(err)
      }),
      this.props.getContractInfo(this.props.contract_id).catch((err) => {
        // console.log(err)
      }),
    ])
      .then(() => {
        this.props.stopLoading()
      })
      .catch((err) => {
        // console.log(err)
        this.props.stopLoading()
      })
  }

  render() {
    let i = 0
    const contract = this.props.contract_items[this.props.contract_id] || {}
    const contract_info = this.props.contract_info[this.props.contract_id]
    let contract_packages = this.props.contract_packages[this.props.contract_id]
    const contract_devices = this.props.contract_device[this.props.contract_id]
    const { content, help } = this.props

    try {
      contract_packages.core = Object.values(contract_packages.core).filter(
        (p) => !p.private
      )
      contract_packages.other = Object.values(contract_packages.other).filter(
        (p) => !p.private
      )
      contract_packages.suppl = Object.values(contract_packages.suppl).filter(
        (p) => !p.private
      )
    } catch (error) {}

    return (
      <AdminContent
        advertisementTarget="account.services.overview.banner"
        title="Moje služby"
        subtitle={content['content.account.services.overview.title']}
        help={help['help.account.services.overview.help']}
        link="/ucet/moje-sluzby/prehled-sluzeb"
      >
        <div className="row">
          <div className="col col--big">
            <div className="admin__inner relative">
              {/*this.state.loading &&
                                <Loading />
                            */}

              <h2>Balíčky</h2>
              <ul className="list list--services">
                <li className="list__row">
                  <div className="list__label list__col">
                    <b>Služba</b>
                  </div>
                  <div className="list-col">
                    <p>
                      {contract.portfolio.name} - smlouva č.{' '}
                      <strong>{this.props.current_nr}</strong>
                    </p>
                  </div>
                  <div className="list__action list__col" />
                </li>

                {contract_info && contract_info.bounded_date && (
                  <li className="list__row">
                    <div className="list__label list__col">
                      <b>Vázanost smlouvy</b>
                    </div>
                    <div className="list__value list__col">
                      <HiddenUnderLink
                        value={formatDate(contract_info.bounded_date)}
                        label="Zobrazit"
                      />
                    </div>
                  </li>
                )}
              </ul>

              <ul className="list list--services">
                <li className="list__row">
                  <div className="list__label list__col">
                    {contract_packages &&
                    contract_packages['core'] &&
                    contract_packages['core'].length > 1 ? (
                      <span>
                        <b>Hlavní balíčky</b>
                      </span>
                    ) : (
                      <span>
                        <b>Hlavní balíček</b>
                      </span>
                    )}
                  </div>
                  <div className="list__value list__col">
                    {contract_packages &&
                      contract_packages['core'] &&
                      contract_packages['core'].map((contract_package) => (
                        <p key={contract_package.id}>{contract_package.name}</p>
                      ))}
                  </div>
                </li>

                {contract_packages &&
                  contract_packages['suppl'] &&
                  contract_packages['suppl'].length > 0 && (
                    <li className="list__row">
                      <div className="list__label list__col">
                        {contract_packages &&
                        contract_packages['suppl'].length > 1 ? (
                          <span>
                            <b>Filmové balíčky</b>
                          </span>
                        ) : (
                          <span>
                            <b>Filmový balíček</b>
                          </span>
                        )}
                      </div>
                      <div className="list__value list__col">
                        {contract_packages &&
                          contract_packages['suppl'].map((contract_package) => (
                            <p key={contract_package.id}>
                              {contract_package.name}
                            </p>
                          ))}
                      </div>
                    </li>
                  )}

                {contract_packages &&
                  contract_packages['other'] &&
                  contract_packages['other'].length > 0 && (
                    <li className="list__row">
                      <div className="list__label list__col">
                        {contract_packages &&
                        contract_packages['other'].length > 1 ? (
                          <span>
                            <b>Doplňkové balíčky</b>
                          </span>
                        ) : (
                          <span>
                            <b>Doplňkový balíček</b>
                          </span>
                        )}
                      </div>
                      <div className="list__value list__col">
                        {contract_packages &&
                          contract_packages['other'].map((contract_package) => (
                            <p key={contract_package.id}>
                              {contract_package.name}
                            </p>
                          ))}
                      </div>
                    </li>
                  )}

                {contract_packages &&
                  contract_packages['services'] &&
                  contract_packages['services'].length > 0 && (
                    <li className="list__row">
                      <div className="list__label list__col">
                        {contract_packages &&
                        contract_packages['services'].length > 1 ? (
                          <span>
                            <b>Doplňkové služby</b>
                          </span>
                        ) : (
                          <span>
                            <b>Doplňková služba</b>
                          </span>
                        )}
                      </div>
                      <div className="list__value list__col">
                        {contract_packages &&
                          contract_packages.services.map((package_service) => (
                            <p key={package_service.id}>
                              {package_service.name}
                            </p>
                          ))}
                      </div>
                    </li>
                  )}
              </ul>

              {contract_devices &&
                (contract_devices.devices.length > 0 ||
                  contract_devices.cards.length) > 0 && (
                  <React.Fragment>
                    <h2>Zařízení</h2>
                    <div className="row">
                      <div className="col col--is--12 col--lg--9">
                        <table className="digi-table digi-table--services">
                          <thead>
                            <tr>
                              <td>
                                <div className="cell">Typ</div>
                              </td>
                              <td>
                                <div className="cell">Sériové číslo</div>
                              </td>
                              <td />
                              <td />
                            </tr>
                          </thead>
                          <tbody>
                            {contract_devices.devices.map(
                              (contract_device, index) => (
                                <tr
                                  key={index}
                                  className={`digi-table__row ${
                                    1 === ++i % 2 ? `` : `even`
                                  }`}
                                >
                                  <td>
                                    <div className="cell">
                                      <span className="cell__label">Typ</span>
                                      <span className="cell__content">
                                        <strong>{contract_device.name}</strong>
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="cell">
                                      <span className="cell__label">
                                        Sériové číslo
                                      </span>
                                      <span className="cell__content slim">
                                        {contract_device.serial}
                                      </span>
                                    </div>
                                  </td>
                                  <td />
                                  <td>
                                    <div className="cell">
                                      <span className="cell__label">
                                        &nbsp;
                                      </span>
                                      <span className="cell__content">
                                        {contract_device.code && (
                                          <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="a--arrow green intro__links__link"
                                            href={sanitizeHTML(
                                              content[
                                                `content.account.services.device.${contract_device.code}.support_url`
                                              ],
                                              { allowedTags: [] }
                                            )}
                                          >
                                            podpora
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                            {contract_devices.cards.map((card, index) => (
                              <tr
                                key={card.id}
                                className={`digi-table__row ${
                                  1 === ++i % 2 ? `` : `even`
                                }`}
                              >
                                <td className="col-1">
                                  <div className="cell">
                                    <span className="cell__label">Typ</span>
                                    <span className="cell__content">
                                      <strong>{card.name}</strong>
                                    </span>
                                  </div>
                                </td>
                                <td className="col-1">
                                  <div className="cell">
                                    <span className="cell__label">
                                      Sériové číslo
                                    </span>
                                    <span className="cell__content slim">
                                      {card.serial}
                                    </span>
                                  </div>
                                </td>
                                <td className="col-1">
                                  <div className="cell">
                                    <span className="cell__label">&nbsp;</span>
                                    <span className="cell__content">
                                      <BtnCardReactivation
                                        className={`a--arrow green intro__links__link link`}
                                        contract_id={this.props.contract_id}
                                        card={card}
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td className="col-1">
                                  <div className="cell">
                                    <span className="cell__label">&nbsp;</span>
                                    <span className="cell__content">
                                      {card.code && (
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="a--arrow green intro__links__link"
                                          href={sanitizeHTML(
                                            content[
                                              `content.account.services.device.${card.code}.support_url`
                                            ],
                                            { allowedTags: [] }
                                          )}
                                        >
                                          podpora
                                        </a>
                                      )}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {contract &&
                          contract.live_tv &&
                          contract.live_tv_status && (
                            <span key={contract.id} className="small-note">
                              Telly aplikace můžete spravovat na{' '}
                              <LiveTvButton
                                className="green link"
                                contract_id={contract.id}
                              >
                                moje.telly.cz
                              </LiveTvButton>
                            </span>
                          )}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              <div className="footline">
                {/* <Link
                  to="/ucet/moje-sluzby"
                  className="btn btn--primary btn--next btn--block--xs"
                >
                  Aktivovat nové služby
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        {/*
                <Banner target="account.services.overview" position="bottom" />
            </div>
                */}
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    //contract : state.contract,
    help: state.translation.help,
    content: state.translation.content,
    contract_packages: state.contract_packages,
    current_nr: state.current_nr.value,
    contract_nr_ids:
      state.contract_nr.items && state.contract_nr.items[state.current_nr.value]
        ? state.contract_nr.items[state.current_nr.value]
        : null,
    contract_items: state.contract.items,
    contract_device: state.contract_device,
    loading:
      state.contract.loading ||
      state.contract_packages.loading ||
      state.contract_device.loading,
    contract_info: state.contract_info,
  }
}

const enhance = compose(
  connect(mapStateToProps, {
    getContract,
    getContractPackages,
    getContractDevices,
    //reinitSmartcard,
    getContractInfo,
    startLoading,
    stopLoading,
  }),
  ContractHoc
  // WithLoader
)

export default enhance(ServiceList)

/*
export default connect(mapStateToProps, {
    getContract,
    getContractPackages,
    getContractDevices,
    //reinitSmartcard,
    getContractInfo
})(ContractHoc(ServiceList));
*/
