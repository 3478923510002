import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField, sipoValidation } from '../../utils/form.js'

let PaymentSipoForm = (props) => {
  const { handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <ul className="list list--action align-center list--modal">
        <li className="list__row">
          <div className="list__label list__col">
            <label htmlFor="sipo-value">Číslo SIPO</label>
          </div>
          <div className="list__value list__col">
            <Field
              id="sipo-value"
              name="sipoValue"
              component={renderField}
              type="text"
              validate={[sipoValidation]}
              placeholder=""
            />
          </div>
        </li>
      </ul>

      <button
        className="btn btn--primary btn--next btn--block--xs"
        type="submit"
      >
        OK
      </button>
    </form>
  )
}

export default reduxForm({
  form: 'form_payment_sipo',
  touchOnBlur: false,
  //validate,
  enableReinitialize: true,
})(PaymentSipoForm)
