import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PublicRoute = ({ component: Component, render, user, path }) => {
  return (
    <Route
      render={(props) =>
        user.auth ? (
          <Redirect path={path} to="/ucet/nastenka" />
        ) : 'function' === typeof render ? (
          render(props)
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {})(PublicRoute)
