import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { requestPin } from '../actions/app'
import Loading from './loading'

const PinConfirmation = ({ requestPin, confirmation, text }) => {
  const pinInputCount = 4
  let pinInputRefs = []

  const [pin, setPin] = useState({})
  const [loading, setLoading] = useState(false)

  const hangleChange = (event, i) => {
    setPin({ ...pin, [i]: event.target.value })

    if (event.target.value && i < pinInputCount - 1) {
      pinInputRefs[i + 1].focus()
    }
  }

  useEffect(() => {
    if('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', e => {
        const input = document.querySelectorAll('input[autocomplete="one-time-code"]');
        if (input.length === 0 ) return;
        const ac = new AbortController();
        const form = input.closest('form');
        if (form) {
          form.addEventListener('submit', e => {
            ac.abort();
          });
        }
        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          const otpCodeArr = otp.split('')
          input.forEach((singleInput, index) => {
            singleInput.value = otpCodeArr[index]
          })
          if (form) form.submit();
        }).catch(err => {
          console.log(err);
        });
      });
    }
  })

  const handleSubmit = () => {
    const validationPin = Object.values(pin).join('')
    setLoading(true)
    return confirmation(validationPin)
      .then((response) => {
        setLoading(false)
        for (let i = 0; i < pinInputCount; i++) {
          pinInputRefs[i].value = ''
        }
        return Promise.resolve(response)
      })
      .catch((error) => {
        setLoading(false)
        return Promise.reject(error)
      })
  }

  const handlePinRequest = () => {
    console.log('handlePinRequest')
    setLoading(true)
    return requestPin()
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false)
        return Promise.resolve(err)
      })
  }

  let pinInputs = []
  for (let i = 0; i < pinInputCount; i++) {
    pinInputs.push(
      <input
        key={i}
        ref={(element) => pinInputRefs.push(element)}
        maxLength="1"
        autocomplete="one-time-code"
        className="pin__input"
        type="text"
        name={`validationPin[${i}]`}
        onChange={(event) => hangleChange(event, i)}
        onKeyDown={(e) => {
          if (
            i + 1 === pinInputCount &&
            e.target.value !== '' &&
            e.key === 'Enter'
          ) {
            handleSubmit()
          }
        }}
      />
    )
  }

  return (
    <React.Fragment>
      {loading && <Loading />}

      <div className="mb20">
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      <div className="pin">{pinInputs}</div>

      <div className="pin-action">
        <button
          type="button"
          className="link link--small link--renew"
          onClick={handlePinRequest}
        >
          přeposlat ověřovací sms ještě jednou
        </button>
      </div>

      <div className="footline">
        <button className="btn btn--primary  btn--next" onClick={handleSubmit}>
          Dále
        </button>
      </div>
    </React.Fragment>
  )
}

export default connect(null, {
  requestPin,
})(PinConfirmation)
