import React from 'react'
import { reset } from 'redux-form'
import { toast } from 'react-toastify'

import {
  REQUEST_DELETE_CONTACT,
  RECEIVE_DELETE_CONTACT,
  RECEIVE_DELETE_CONTACT_FAILED,
  RECEIVE_UPDATE_CONTACT_FAILED,
  RECEIVE_UPDATE_CONTRACT_ADDRESS,
  RECEIVE_CONTRACT_INFO,
  RECEIVE_CONTRACT_UPDATE,
} from './types'

import { instance } from '../conf/axios'

export function requestCreateBillingEmail(contract_id, values) {
  return function (dispatch, getState) {
    return instance
      .put(`customer/contract/${contract_id}`, {
        invoiceEmail: values.value,
        validationPin: values.validationPin,
      })
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTRACT_INFO,
          payload: response.data,
          contract_id: contract_id,
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_UPDATE_CONTACT_FAILED,
        })
        return Promise.reject(error)
      })
  }
}

export function requestChangePassword(values) {
  return function (dispatch, getState) {
    return instance
      .put(`/customer/account`, values)
      .then((response) => {
        dispatch(reset('form_profile_password'))
        return Promise.resolve(response.data)
      })
      .catch((error) => Promise.reject(error))
  }
}

const filterAddressRequestData = (values) => {
  const allowed = ['ruian', 'city', 'street', 'zip']
  const ret = Object.keys(values).reduce((acc, key, index) => {
    if (allowed.includes(key)) {
      acc[key] = values[key]
    }
    return acc
  }, {})
  //console.log('ret');
  //console.log(ret);
  return ret
}

export function requestUpdateContractAddress(
  contract_id,
  address_type,
  values
) {
  return function (dispatch, getState) {
    let data = {}

    if (values.no_address) {
      data = {
        [address_type]: {},
      }
    } else {
      data = {
        [address_type]: {
          ...values,
        },
      }
    }

    /**
     * ocistime request o nepotrebne polozky
     */
    if (data[address_type] instanceof Object) {
      data[address_type] = filterAddressRequestData(data[address_type])
    }

    return instance
      .put(`customer/contract/${contract_id}`, {
        ...data,
        validationPin: values.validationPin,
      })
      .then((response) => {
        dispatch({
          type: RECEIVE_UPDATE_CONTRACT_ADDRESS,
          contract_id: contract_id,
          address_type: address_type,
          payload: response.data,
        })
        return Promise.resolve(response.data)
      })
      .catch((error) => Promise.reject(error))
  }
}

export function requestUpdateContact(values) {
  return function (dispatch, getState) {
    return instance
      .put(`customer/contact/${values.id}`, values)
      .then((response) => {
        if (true === response.data.validation) {
          if ('development' === process.env.REACT_APP_EMAIL_MODE) {
            var iframe = `<iframe style="border:0; width: 100%;" src="data:text/html;base64,${response.data.preview}"></iframe>`
            toast.info(
              <div
                dangerouslySetInnerHTML={{
                  __html: iframe,
                }}
              />,
              {
                autoClose: false,
              }
            )
          }
        }

        return Promise.resolve(response.data)
      })
      .catch((error) => Promise.reject(error))
  }
}

export function requestRemoveContact(id, values) {
  return function (dispatch, getState) {
    dispatch({
      type: REQUEST_DELETE_CONTACT,
    })

    return instance
      .delete(`customer/contact/${id}`, { params: values })
      .then((response) => {
        dispatch({
          type: RECEIVE_DELETE_CONTACT,
          id: id,
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_DELETE_CONTACT_FAILED,
          id: id,
        })
        return Promise.reject(error)
      })
  }
}

export function requestCreateContact(values) {
  return function (dispatch) {
    return instance
      .post(`customer/contact`, values)
      .then((response) => {
        if (true === response.data.validation) {
          if ('development' === process.env.REACT_APP_EMAIL_MODE) {
            const iframe = `<iframe style="border:0; width: 100%;" src="data:text/html;base64,${response.data.preview}"></iframe>`
            toast.info(
              <div
                dangerouslySetInnerHTML={{
                  __html: iframe,
                }}
              />,
              {
                autoClose: false,
              }
            )
          }
        }

        dispatch(reset('form_profile_contact_create'))
        return Promise.resolve(response.data)
      })
      .catch((error) => Promise.reject(error))
  }
}

export function requestToggleLiveTV(contract_id, values) {
  return function (dispatch, getState) {
    return instance
      .put(`customer/contract/${contract_id}`, {
        liveTv: values.liveTv,
        validationPin: values.validationPin,
      })
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTRACT_INFO,
          payload: response.data.contract,
          contract_id: contract_id,
        })
        dispatch({
          type: RECEIVE_CONTRACT_UPDATE,
          payload: response.data.contract,
          contract_id: contract_id,
        })
        return Promise.resolve(response.data)
      })
      .catch((error) => Promise.reject(error))
  }
}

export function requestConfirmationEmail() {
  return function (dispatch, getState) {
    return instance
      .post(`customer/account/confirm/email`, {})
      .then((response) => {
        // return Promise.resolve(response.data)
      })
      .catch((error) => {})
  }
}
