import {
  //DESTROY_STATE,
  RECEIVE_LOGOUT,
} from '../actions/types'

import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import advertisementReducer from './advertisement-reducer'
import appReducer from './app-reducer'
import userReducer from './user-reducer'
import portfolioReducer from './portfolio-reducer'
import portfolioDevicesReducer from './portfolio-devices-reducer'
import portfolioPackagesReducer from './portfolio-packages-reducer'
import contractReducer from './contract-reducer'
import contractNrReducer from './contract-nr'
import contractDevicesReducer from './contract-devices-reducer'
import contractInfoReducer from './contract-info-reducer'
import contractInvoiceReducer from './contract-invoices-reducer'
import contractDocumentReducer from './contract-documents-reducer'
import contractPackageInfoReducer from './contract-package-info-reducer'
import contractPackagesReducer from './contract-packages-reducer'
import contractUpsellReducer from './contract-upsell-reducer'
import currentNrReducer from './current-nr-reducer'
import inboxReducer from './inbox-reducer'
import messageReducer from './message-reducer'
import translationReducer from './translation-reducer'
import acknowledgmentsReducer from './acknowledgments-reducer'
import contractNewReducer from './contract-new-reducer'
import loyaltyReducer from './loyalty-reducer'

const applicationReducer = combineReducers({
  form: reduxFormReducer,
  advertisement: advertisementReducer,
  app: appReducer,
  contract: contractReducer,
  contract_device: contractDevicesReducer,
  contract_info: contractInfoReducer,
  contract_invoices: contractInvoiceReducer,
  contract_documents: contractDocumentReducer,
  contract_nr: contractNrReducer,
  contract_package_info: contractPackageInfoReducer,
  contract_packages: contractPackagesReducer,
  contract_upsell: contractUpsellReducer,
  current_nr: currentNrReducer,
  inbox: inboxReducer,
  message: messageReducer,
  portfolio: portfolioReducer,
  portfolio_devices: portfolioDevicesReducer,
  portfolio_packages: portfolioPackagesReducer,
  translation: translationReducer,
  user: userReducer,
  acknowledgments: acknowledgmentsReducer,
  contract_new: contractNewReducer,
  loyalty: loyaltyReducer,
})

const rootReducer = (state, action) => {
  //console.log(state);

  if (action.type === RECEIVE_LOGOUT) {
    //state = undefined;
    state = {
      translation: state.translation,
    }
  }

  return applicationReducer(state, action)
}

export default rootReducer
