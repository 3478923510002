import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Prompt } from 'react-router-dom'
import { compose } from 'redux'

import { formatPhone, findContactBaseMobile } from '../../utils/functions'

import PaymentTypeForm from '../../form/payment-setup/payment-type.js'
import { getContractInfo, setCanLeave } from '../../actions/admin'
import { submitEmailPaymentSuccess, submitPaymentSetup, validatePayment } from '../../actions/invoices'
import { findContactBaseEmail } from '../../utils/functions'

import { toast } from 'react-toastify'

import ContractHoc from '../../hoc/contract-hoc'
// import LeavingModalHoc from '../../hoc/leaving-modal-hoc'

import PaymentInvoiceTypeForm from '../../form/payment-setup/payment-invoice-type.js'
import { requestPin } from '../../actions/app'

import { setError } from '../../actions/error'

import DigiModalContext from '../../context/digi-modal-context'
import AdminContent from '../../layout/admin-content'

import PinConfirmation from '../../component/pin-confirmation'
import ConfirmationNote from '../../component/confirmation-note'
import Loading from '../../component/loading'
import { GOPAY_NOTIFY_URL, GOPAY_RETURN_URL } from '../../conf/constants'
import PaymentSuccessNotification from '../../form/payment-setup/payment-success-notification'

class InvoicesSetup extends Component {
  static contextType = DigiModalContext
  constructor(props) {
    super(props)
    this.state = {
      formData: {},
      currentFormData: {},
      somethingChanged: false,
      tmpLocation: null,
      current_nr: null,
      loading: true,
    }
    this.saveSetup = this.saveSetup.bind(this)
    this.changePaymentType = this.changePaymentType.bind(this)
    this.submitSubForm = this.submitSubForm.bind(this)
    // this.confirmLeave = this.confirmLeave.bind(this)
    // this.processBeforeLeave = this.processBeforeLeave.bind(this)
    // this.props.leavingCallbacks(this.confirmLeave, this.processBeforeLeave)
  }

  componentDidMount() {
    this.getContractInfoInit()
    this.setState({
      current_nr: this.props.current_nr,
    })
    var script = document.createElement('script')
    script.src = 'https://gw.sandbox.gopay.com/gp-gw/js/embed.js'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  getContractInfoInit() {
    this.props
      .getContractInfo(this.props.contract_id)
      .then((data) => {
        let bankPre,
          bankAccount,
          bankCode = null

        try {
          if ('inkaso' === data.invoice_type) {
            let invoiceTypeValue = data.invoice_type_value

            if (invoiceTypeValue.indexOf('-') > -1) {
              const splits = invoiceTypeValue.split('-')
              bankPre = splits[0]
              invoiceTypeValue = splits[1]
            }
            if (invoiceTypeValue.indexOf('/') > -1) {
              const splits = invoiceTypeValue.split('/')
              bankAccount = splits[0]
              bankCode = splits[1]
            }
          }
        } catch (error) {}

        const formData = {
          invoiceEmail:
            data.invoice_email ||
            findContactBaseEmail(this.props.user.contacts),
          invoiceAddress: data.invoice_address,
          invoiceType: data.invoice_type,
          invoiceDelivery: data.invoice_type_delivery,
          invoiceTypeValue: data.invoice_type_value,
          inkasoValue:
            'inkaso' === data.invoice_type ? data.invoice_type_value : null,
          sipoValue:
            'sipo' === data.invoice_type ? data.invoice_type_value : null,
          bankPre: bankPre,
          bankAccount: bankAccount,
          bankCode: bankCode,
          sendPaymentConfirmingEmail: data.payment_notification,
        }

        this.setState({
          formData,
          currentFormData: formData,
          loading: false,
        })
      })
      .catch((err) => {
        console.log('chybka')
      })
  }

  changePaymentType(invoice_type) {
    const somethingChanged =
      invoice_type !== this.state.formData.invoiceType ||
      this.state.somethingChanged
    this.setState({
      somethingChanged,
      formData: {
        ...this.state.formData,
        invoiceType: invoice_type,
      },
    })
  }

  submitSubForm(values) {
    let changed = false

    Object.keys(values).forEach((key) => {
      if (values[key] && typeof values[key] === 'object') {
        Object.keys(values[key]).forEach((subkey) => {
          if (
            !this.state.formData[key] ||
            values[key][subkey] !== this.state.formData[key][subkey]
          ) {
            changed = true
          }
        })
      } else {
        if (values[key] !== this.state.formData[key]) {
          changed = true
        }
      }
    })

    this.setState({
      formData: {
        ...this.state.formData,
        ...values,
      },
      somethingChanged: this.state.somethingChanged || changed,
    })

    // this.props.setCanLeave(!(this.state.somethingChanged || changed))
    return Promise.resolve()
  }

  validateAndFormatFinalValues(values = {}) {
    let invoiceTypeValue

    if ('inkaso' === values.invoiceType) {
      if (!values.bankAccount || !values.bankCode) {
        //return Promise.reject('Prosím, vyplňte číslo účtu.')
        throw new Error('Prosím, vyplňte číslo účtu.')
      }

      let inkaso = []
      if (values.bankPre) {
        inkaso.push(values.bankPre)
      }

      inkaso.push(values.bankAccount + '/' + values.bankCode)
      invoiceTypeValue = inkaso.join('-')
    } else if ('sipo' === values.invoiceType) {
      if (!values.sipoValue) {
        throw new Error('Prosím, vyplňte sipo číslo.')
        //return Promise.reject('Prosím, vyplňte sipo číslo.')
      }
      invoiceTypeValue = values.sipoValue
    }

    let data = {
      invoiceType: values.invoiceType,
      invoiceDelivery: values.invoiceDelivery,
      installAddress: values.installAddress,
      validationPin: values.validationPin,
    }

    if (invoiceTypeValue) {
      data.invoiceTypeValue = invoiceTypeValue
    }

    if ('email' === values.invoiceDelivery && !values.invoiceEmail) {
      throw new Error('Prosím, vyplňte fakturační e-mail.')
    }

    if ('email' === values.invoiceDelivery) {
      data.invoiceEmail = values.invoiceEmail
    }

    if (true === values.no_invoice_address) {
      data.invoiceAddress = null
    } else {
      data.invoiceAddress = values.invoiceAddress
    }

    return data
  }

  isNewValue(values) {
    if (
      values.invoiceType === this.state.formData.invoiceType &&
      values.invoiceTypeValue === this.state.formData.invoiceTypeValue &&
      values.invoiceDelivery === this.state.formData.invoiceDelivery &&
      values.invoiceEmail === this.state.formData.invoiceEmail
    ) {
      return false
    }
    return true
  }

  async saveSetup(values) {
    if ('email' === values.invoiceDelivery) {
      delete values['invoiceAddress']
    }

    if (values.invoiceType === 'pay_gate') {
      values.returnUrl = GOPAY_RETURN_URL
      values.notificationUrl = GOPAY_NOTIFY_URL
      // TODO: ukazat modal s 1kc platbou
    }

    try {
      const res = await this.props.submitPaymentSetup(
        this.props.contract_id,
        values
      )

      if (res.payment) {
        this.setState({ loading: true })
        window._gopay.checkout(
          {
            gatewayUrl: res.gate_url,
            inline: true,
          },
          async function (ret) {
            try {
              await this.props.validatePayment(res.midas_id)
              this.getContractInfoInit()
              let _t = `Nastavení bylo uloženo.`
              if (
                this.state.currentFormData.invoiceEmail !== values.invoiceEmail
              ) {
                _t += `<br><br>${this.props.content['content.profile.personal.data.change.mail.verify']}`
              }
              this.context.toggleDigiModal(
                <ConfirmationNote
                  text={_t}
                  close={this.context.toggleDigiModal}
                />
              )
            } catch (error) {
            } finally {
              this.setState({
                somethingChanged: false,
                loading: false,
              })
              // this.props.setCanLeave(true)
            }
          }.bind(this)
        )
      } else {
        let _t = `Nastavení bylo uloženo.`
        if (this.state.currentFormData.invoiceEmail !== values.invoiceEmail) {
          _t += `<br><br>${this.props.content['content.profile.personal.data.change.mail.verify']}`
        }
        this.context.toggleDigiModal(
          <ConfirmationNote text={_t} close={this.context.toggleDigiModal} />
        )
        this.getContractInfoInit()
      }
    } catch (error) {
      console.log(error)
      this.setState({
        loading: false,
      })
    }
  }

  // confirmLeave() {
  // this.props.setCanLeave(true)
  // }

  // processBeforeLeave() {
  //   this.saveSetup()
  //   this.setState({
  //     tmpLocation: null,
  //   })
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   this.props.history.block((location, action) => {
  //     if (!nextProps.canLeave) {
  //       this.setState({
  //         tmpLocation: location.pathname,
  //       })
  //       // this.props.openLeavingModal()
  //       return false
  //     } else {
  //       return true
  //     }
  //   })

  //   return true
  // }

  submitForm = () => {
    let dirty = false
    try {
      Object.entries(this.state.formData).forEach((v) => {
        if (this.state.currentFormData[v[0]] !== v[1]) {
          dirty = true
        }
      })

      if (!dirty) return

      const values = this.validateAndFormatFinalValues(this.state.formData)

      if (!this.isNewValue(values)) return
      this.setState({ loading: true })
      this.props
        .requestPin()
        .then(() => {
          this.setState({ loading: false })
          this.context.toggleDigiModal()
          this.context.toggleDigiModal(
            <PinConfirmation
              text={
                this.props.content[
                  'content.profile.personal.data.change.mail.step1'
                ] &&
                this.props.content[
                  'content.profile.personal.data.change.mail.step1'
                ].replace(
                  '%phone%',
                  formatPhone(findContactBaseMobile(this.props.user.contacts))
                )
              }
              confirmation={(validationPin) => {
                return this.saveSetup({
                  ...values,
                  validationPin: validationPin,
                }).then(() => {
                  this.setState({
                    somethingChanged: false,
                  })
                  if (values.invoiceType === 'pay_gate') {
                    this.context.toggleDigiModal()
                  } else {
                    let _t = `Nastavení bylo uloženo.`
                    if (
                      this.state.currentFormData.invoiceEmail !==
                      values.invoiceEmail
                    ) {
                      _t += `<br><br>${this.props.content['content.profile.personal.data.change.mail.verify']}`
                    }
                    this.context.toggleDigiModal()
                    this.context.toggleDigiModal(
                      <ConfirmationNote
                        text={_t}
                        close={this.context.toggleDigiModal}
                      />
                    )
                  }
                })
              }}
            />
          )
        })
        .catch((err) => {
          this.setState({ loading: false })
          Promise.reject(err)
        })
    } catch (e) {
      this.setState({ loading: false })
      toast.error(e.message)
    }
  }

  changePaymentConfirmingEmail = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        sendPaymentConfirmingEmail: !this.state.formData.sendPaymentConfirmingEmail,
      },
    })
  }

  submitPaymentConfirmingEmail = async () => {
    try {
      this.setState({ loading: true });
      await this.props.submitEmailPaymentSuccess(this.props.contract_id, { notify: this.state.formData.sendPaymentConfirmingEmail });
      toast.success('Nastavení potvrzení o přijaté platbě bylo úspěšně uloženo');
      this.setState({ loading: false });
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
      this.setState({ loading: false });
    }
  }

  render() {
    let pernamentAddress = ''
    if (this.props.user.address) {
      pernamentAddress =
        this.props.user.address.street + ', ' + this.props.user.address.city
    }

    const { content, help } = this.props

    let dirty = false
    Object.entries(this.state.formData).forEach((v) => {
      if (this.state.currentFormData[v[0]] !== v[1]) {
        dirty = true
      }
    })

    return (
      <AdminContent
        advertisementTarget="invoice.settings.banner"
        title="Faktury a platby"
        subtitle={content['content.invoice.settings.title']}
        help={help['help.invoice.settings.help']}
        link="/ucet/fakturace-a-platby"
      >
        <Prompt
          when={dirty}
          message={() => 'Nemáte uložené změny, chcete přesto odejít?'}
        />
        {/* <DigiModalContext.Consumer>
          {({ setStyle, toggleDigiModal }) => (
            <React.Fragment> */}
        {this.state.tmpLocation && this.props.canLeave && (
          <Redirect to={this.state.tmpLocation} />
        )}

        <div className="row">
          <div className="col col--is--12 col--xl--12">
            <div className="admin__inner">
              <div className="relative">
                {this.state.loading && <Loading />}

                <div className="row">
                  <div className="col col--is--12 col--lg--8 col--xl--6">
                    <h2>Způsob zasílání vyúčtování</h2>
                    <ul className="list list--action align-center list--payment-setup">
                      <li className="list__row">
                        <div className="list__col list__col--auto">
                          <div className="list__row list__row--col--setting">
                            <div className="list__label list__col list__label--payment">
                              {'email' ===
                                this.state.formData.invoiceDelivery && (
                                <span>Elektronická faktura</span>
                              )}
                              {'post' ===
                                this.state.formData.invoiceDelivery && (
                                <span>Papírová faktura</span>
                              )}
                            </div>

                            <div className="list__value list__value--cut list__col">
                              {'email' ===
                                this.state.formData.invoiceDelivery && (
                                <div>
                                  <span>
                                    {this.state.formData.invoiceEmail}
                                  </span>
                                </div>
                              )}

                              {'post' ===
                                this.state.formData.invoiceDelivery && (
                                <div>
                                  {this.state.formData.invoiceAddress &&
                                  Object.values(
                                    this.state.formData.invoiceAddress
                                  ).length > 0 ? (
                                    <span>
                                      {this.state.formData.invoiceAddress && (
                                        <span>
                                          {
                                            this.state.formData.invoiceAddress
                                              .street
                                          }
                                          ,{' '}
                                          {
                                            this.state.formData.invoiceAddress
                                              .city
                                          }
                                        </span>
                                      )}
                                    </span>
                                  ) : (
                                    <span>{pernamentAddress}</span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="list__action list__action--icons list__col">
                          <button
                            type="button"
                            className="btn btn--secondary btn--next"
                            onClick={() => {
                              this.context.toggleDigiModal(
                                <PaymentInvoiceTypeForm
                                  user={this.props.user}
                                  formData={this.state.formData}
                                  initialValues={this.state.formData}
                                  submitSubForm={(values) =>
                                    this.submitSubForm(values).then(
                                      this.context.toggleDigiModal()
                                    )
                                  }
                                />
                              )
                            }}
                          >
                            Změnit
                          </button>
                        </div>
                      </li>
                    </ul>

                    <h2>Způsob úhrady</h2>

                    <PaymentTypeForm
                      initialValues={this.state.formData}
                      formData={this.state.formData}
                      changePaymentType={this.changePaymentType}
                      toggleDigiModal={this.context.toggleDigiModal}
                      submitSubForm={(values) =>
                        this.submitSubForm(values).then(
                          this.context.toggleDigiModal()
                        )
                      }
                      verified={
                        this.state.currentFormData.invoiceType === 'pay_gate' &&
                        this.state.formData.invoiceType ===
                        this.state.currentFormData.invoiceType
                      }
                    />
                    <button
                      onClick={this.submitForm}
                      className="btn btn--primary btn--next btn--block--xs"
                      type="button"
                    >
                      Uložit
                    </button>

                    {this.state.formData.invoiceType !== 'pay_gate' &&
                      <>
                        <h2>Potvrzení o přijaté platbě</h2>
                        <PaymentSuccessNotification
                          formData={this.state.formData}
                          changePaymentConfirmingEmail={this.changePaymentConfirmingEmail}
                          submitPaymentConfirmingEmail={this.submitPaymentConfirmingEmail}
                        />
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </React.Fragment>
          )}
        </DigiModalContext.Consumer> */}
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    help: state.translation.help,
    canLeave: state.app.canLeave,
    contract_nr_items: state.contract_nr.items,
    contract_info: state.contract_info
      ? Object.values(state.contract_info)[0]
      : null,
    current_nr: state.contract_nr.selected,
    user: state.user,
    form: state.form.form_payment_setup,
  }
}

/*
export default LeavingModalHoc(connect(mapStateToProps, {
    submitPaymentSetup,
    getContractInfo,
    setCanLeave,
    setError
})((ContractHoc(InvoicesSetup))));
*/

const enhance = compose(
  connect(mapStateToProps, {
    submitPaymentSetup,
    getContractInfo,
    setCanLeave,
    setError,
    requestPin,
    validatePayment,
    submitEmailPaymentSuccess,
  }),
  ContractHoc
  // LeavingModalHoc
)

let Enhanced = enhance(InvoicesSetup)

export default Enhanced

//export default  <DigiModalContext.Consumer>xxx</DigiModalContext.Consumer>

//export default enhance( InvoicesSetup );
