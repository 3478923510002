import { toast } from 'react-toastify'
import jwtDecode from 'jwt-decode'
import {
  RECEIVE_TRANSLATION,
  RECEIVE_ADVERTISEMENT,
  CLEAR_REDIRECT,
  RECEIVE_LOGOUT,
  START_LOADING,
  STOP_LOADING,
} from './types'

import { instance } from '../conf/axios'
import { APP_TOKEN_KEY, USER_TOKEN_KEY } from '../conf/constants'
import { apiOverrideEnabled, clearCookie, getCookie, writeCookie } from '../utils/functions'
import {isEqual} from 'lodash'

function getAppToken() {
  const API_ENV = getCookie('API_ENV')
  const isProd = apiOverrideEnabled() && API_ENV === 'prod'
  return window.location.origin.includes('localhost:3000')
    ? instance.get(`https://dtsecure.cz/telly/api/${isProd ? 'login-prod.php' : 'login.php'}`, {})
    : instance.post(
        `${window.location.origin}/api/${isProd ? 'login-prod.php' : 'login.php'}`,
        {},
        { public: true }
      )
}

export function issueAppToken() {
  return function (dispatch, getState) {
    try {
      // const app_token = localStorage.getItem(APP_TOKEN_KEY)
      const app_token = getCookie(APP_TOKEN_KEY)
      const decodedToken = jwtDecode(app_token)
      const currentTime = parseInt(Date.now() / 1000)
      const expireTime = decodedToken.exp
      let timeToExpire = expireTime - currentTime

      if (timeToExpire > 100) {
        return Promise.resolve(app_token)
      }
      return getAppToken()
        .then((response) => {
          // localStorage.setItem(APP_TOKEN_KEY, response.data.token)
          clearCookie(APP_TOKEN_KEY)
          writeCookie(APP_TOKEN_KEY, response.data.token)
          return Promise.resolve(response.data.token)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    } catch (err) {
      return getAppToken()
        .then((response) => {
          // localStorage.setItem(APP_TOKEN_KEY, response.data.token)
          clearCookie(APP_TOKEN_KEY)
          writeCookie(APP_TOKEN_KEY, response.data.token)
          return Promise.resolve(response.data.token)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }
  }
}

export function refreshToken(token) {
  return function (dispatch, getState) {
    const config = {
      headers: { Authorization: `Bearer ${getCookie(APP_TOKEN_KEY)}` },
    }
    return instance
      .post(`token`, { token }, config)
      .then((response) => {
        return Promise.resolve(response.data.token)
      })
      .catch((error) => {
        //console.log(error);

        const { auth } = getState().user

        if (auth) {
          // localStorage.clear()
          clearCookie(USER_TOKEN_KEY)
          dispatch({
            type: RECEIVE_LOGOUT,
          })
        } else {
          // localStorage.removeItem(APP_TOKEN_KEY)
          clearCookie(APP_TOKEN_KEY)
        }

        return Promise.reject(error)
      })
  }
}

export function clearRedirect(token) {
  return function (dispatch, getState) {
    dispatch({
      type: CLEAR_REDIRECT,
    })
  }
}

export function getAdvertisement(target, position) {
  //console.log('target ' + target);

  return function (dispatch, getState) {
    return instance
      .get(`customer/advertisement/${target}/${position}`)
      .then((response) => {
        dispatch({
          type: RECEIVE_ADVERTISEMENT,
          payload: response.data,
          target: target,
          position: position,
        })
        return Promise.resolve(response)
      })
      .catch((error) => {
        console.log(error)
        return Promise.reject(error)
      })
  }
}

export function getTranslation() {

  return async function (dispatch, getState) {
    const translation = localStorage.getItem('translation')
    const translObject = JSON.parse(translation)
    let loops = 0
    if(translation && translObject.expireTime > new Date().getTime()) {
        for (const key in translObject.content) {
          translObject.content[key] = translObject.content[key].replace(/!3B/g, ';')
          loops++
        }
      for (const key in translObject.help) {
        translObject.help[key] = translObject.help[key].replace(/!3B/g, ';')
        loops++
      }
      if(translObject.content && translObject.help && loops === (Object.keys(translObject.content).length + Object.keys(translObject.help).length)) {
        return  dispatch({
          type: RECEIVE_TRANSLATION,
          payload: {content: translObject.content, help: translObject.help},
        })
      }

    } else {
      return instance
        .get(`customer/translation`, { public: true })
        .then((response) => {
          const data = {... response.data}
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + 1800000;
          now.setTime(expireTime);
          const {content, help} = response.data
          for (const key in content) {
            content[key] = content[key].replace(/;/g, '!3B')
          }
          for (const key in help) {
            help[key] = help[key].replace(/;/g, '!3B')
          }
          const stringifiedTransl = JSON.stringify({content: content, help: help, expireTime: expireTime})
          localStorage.setItem('translation', stringifiedTransl)

          let loops = 0
          for (const key in data.content) {
            data.content[key] = data.content[key].replace(/!3B/g, ';')
            loops++
          }
          for (const key in data.help) {
            data.help[key] = data.help[key].replace(/!3B/g, ';')
            loops++
          }

          if(data.content && data.help && loops === (Object.keys(data.content).length + Object.keys(data.help).length)) {
            return  dispatch({
              type: RECEIVE_TRANSLATION,
              payload: data,
            })
          }

        })
        .catch((err) => Promise.reject(err))
    }
  }
}

export function requestPin(phone = null) {
  return async function (dispatch, getState) {
    try {
      const response = await instance.post('/customer/validation/sms', phone)
      if (process.env.REACT_APP_EMAIL_MODE !== 'production') {
        toast.info(response.data.text)
      }

      return response
    } catch (error) {
      return error
    }
  }
}

export function customerContactValidate(hash) {
  return function (dispatch, getState) {
    const params = {
      hash: hash,
    }
    return instance
      .post(`/customer/contact/validate`, params)
      .then((response) => {
        return Promise.resolve(response.data.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

export function startLoading() {
  return function (dispatch) {
    dispatch({
      type: START_LOADING,
    })
  }
}

export function stopLoading() {
  return function (dispatch) {
    dispatch({
      type: STOP_LOADING,
    })
  }
}
