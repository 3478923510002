import { RECEIVE_ADVERTISEMENT } from '../actions/types'

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_ADVERTISEMENT: {
      return {
        ...state,
        [action.target]: {
          ...state[action.target],
          [action.position]: action.payload,
        },
        //content: action.payload.content
      }
    }
    // no default
  }
  return state
}
