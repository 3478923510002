import React from 'react'
import sanitizeHtml from 'sanitize-html'
// import tellyLogoMobile from '../assets/images/logo-blue.svg'

const IntroHeader = ({ label = '' }) => {
  return (
    <div className="intro__header">
      <div className="row">
        {label && (
          <div className="col col--is--12">
            <h1>
              {sanitizeHtml(label, {
                allowedTags: [],
                allowedAttributes: [],
              })}
            </h1>
          </div>
        )}
      </div>
    </div>
  )
}

export default IntroHeader
