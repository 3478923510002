import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { Link } from 'react-router-dom'

import { passwordValidation, renderTooglePassword } from './../utils/form'
import Loading from '../component/loading'
import IntroHeader from '../component/intro-header'

class PasswordCreateForm extends Component {
  render() {
    const { handleSubmit, submitting, error, content = {} } = this.props

    return (
      <form onSubmit={handleSubmit}>
        {submitting && <Loading />}

        <IntroHeader label={content['content.login.reset.credential.new']} />

        {!this.props.successMessage && (
          <div>
            {content['content.login.reset.credential.new.description'] && (
              <div className="intro__text">
                <div className="row">
                  <div
                    className="col"
                    dangerouslySetInnerHTML={{
                      __html:
                        content[
                          'content.login.reset.credential.new.description'
                        ],
                    }}
                  />
                </div>
              </div>
            )}

            <div className="form__row">
              <div className="form__item">
                <label htmlFor="pass1">Heslo:</label>
              </div>
              <div className="form__item">
                <Field
                  id="pass1"
                  name="pass1"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </div>

            <div className="form__row">
              <div className="form__item">
                <label htmlFor="pass2">Potvrzení hesla:</label>
              </div>
              <div className="form__item">
                <Field
                  id="pass2"
                  name="pass2"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </div>

            {error && (
              <div className="spacer spacer--20 validation--block alert">
                {error}
              </div>
            )}

            <div className="intro__buttons intro__buttons--right">
              <button className="btn btn--primary" type="submit">
                Získat nové heslo
              </button>
            </div>
          </div>
        )}

        {this.props.successMessage && (
          <div className="spacer spacer--20 validation--block info">
            {this.props.successMessage}
          </div>
        )}

        <div className="intro__links">
          <Link className="a--arrow--back green intro__links__link" to="/">
            Zpět na přihlášení
          </Link>
        </div>
      </form>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.email) {
    errors.email = 'Prosím, vyplňte toto pole.'
  }

  if (!values.pass1) {
    errors.pass1 = 'Prosím, vyplňte toto pole.'
  }

  if (!values.pass2) {
    errors.pass2 = 'Prosím, vyplňte toto pole.'
  }

  if (values.pass2 && values.pass1 && values.pass2 !== values.pass1) {
    errors.pass2 = 'Hesla musí být stejná.'
  }

  if (values.pass2 && values.pass1) {
    let err = passwordValidation(values.pass1)

    if (err) {
      errors.pass1 = err
    }
  }

  return errors
}

export default reduxForm({
  form: 'form_password_create',
  touchOnBlur: false,
  validate,
})(PasswordCreateForm)
