import React, { Component } from 'react'
//import AdminHeader from "../component/admin-header";
import { connect } from 'react-redux'
import { compose } from 'redux'
import sanitizeHtml from 'sanitize-html'
import {
  loyaltyNextStep,
  requestRewards,
  submitReward,
} from '../actions/loyalty'
import Loading from '../component/loading'
import LoyaltySummary from '../component/loyalty-summary'
// import { dtApiText } from '../utils/functions'
// import Loading from '../component/loading'
import Progress from '../component/progress'
import ChosenReward from '../component/reward-chosen'
import Subreward from '../component/subreward'
//import Loading from '../component/loading'
import DigiModalContext from '../context/digi-modal-context'
import ContractHoc from '../hoc/contract-hoc'
import AdminContent from '../layout/admin-content'
import { postHeight } from '../utils/functions'
import Countdown from '../component/countdown'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS, LOYALTY_ACK_ID } from '../conf/constants'
import LoyaltyConfirmModalHoc from '../hoc/loyalty-confirm-modal-hoc'

class LoyaltyRewards extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      selectedReward: null,
      selectedRewardKey: null,
      rewardWithSummary: false,
      step: 'choose',
      wizard: null,
      loading: false,
      acknowledgedReward: false,
      acknowledged: false,
    }

    this.chooseReward = this.chooseReward.bind(this)
    this.submitReward = this.submitReward.bind(this)
  }

  async componentDidMount() {
    window.addEventListener('resize', () => postHeight('.subreward h3'))
    await this.props.requestRewards()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => postHeight('.subreward h3'))
  }

  chooseReward(rewardId) {
    this.setState({
      selectedRewardKey: rewardId,
    })
  }

  async submitReward(phase = 'TEMP') {
    this.setState({
      loading: true,
    })
    try {
      const reward = await this.props.submitReward(
        this.state.selectedRewardKey,
        phase,
      )
      if (reward === null) return
      this.setState({
        selectedReward: reward,
      })
      if (reward.type === 'CAMPAIGN') {
      } else if (reward.type === 'AMENDMENT') {
        this.setState({
          step: 0,
          wizard: reward.wizard,
        })
      } else if (reward.type === 'VOUCHER') {
        this.setState({
          step: 0,
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const { content, loyalty } = this.props
    let subrewards = []

    if (loyalty.rewards.available.length > 0) {
      subrewards = loyalty.rewards.available[0].options
    }

    if (loyalty.rewards.used.length) {
    }

    postHeight('.subreward h3')

    return (
      <AdminContent
        advertisementTarget="profile.personal.data.banner"
        title="Věrnostní program"
        subtitle={content['content.loyalty.rewards.title']}
        link="/ucet/vernostni-program/prehled"
      >
        {(loyalty.loading || this.state.loading) && <Loading />}
        <div className="row">
          <div className="col">
            <div className="admin__inner">
              <div className="row">
                <div className="col col--xl--9">
                  {loyalty.rewards.used.length > 0 && (
                    <>
                      <h2>
                        {sanitizeHtml(
                          content['content.loyalty.rewards.used.heading'],
                          {
                            allowedTags: [],
                            allowedAttributes: [],
                          },
                        )}
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            content['content.loyalty.rewards.used.description'],
                            {
                              allowedTags: ALLOWED_TAGS,
                              allowedAttributes: ALLOWED_ATTRIBUTES,
                            },
                          ),
                        }}
                      />
                      <div className="row">
                        {loyalty.rewards.used.map((reward) => (
                          <div key={reward.id} className={`col col--md--4`}>
                            <ChosenReward reward={reward} />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {loyalty.rewards.available.length > 0 && (
                    <>
                      <Progress
                        labels={['Výběr odměny', 'Potvrzení']}
                        current={this.props.loyalty.step}
                        goToStep={() => {
                        }}
                      />
                      {this.state.step === 'choose' && (
                        <>
                          <h2>
                            {sanitizeHtml(
                              content[
                                'content.loyalty.rewards.available.heading'
                                ],
                              {
                                allowedTags: [],
                                allowedAttributes: [],
                              },
                            )}
                          </h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                content[
                                  'content.loyalty.rewards.available.description'
                                  ],
                                {
                                  allowedTags: ALLOWED_TAGS,
                                  allowedAttributes: ALLOWED_ATTRIBUTES,
                                },
                              ),
                            }}
                          />
                          <div className="row">
                            <div className="col col--md--6">
                              <Countdown
                                title="Pospěšte si"
                                subtitle="Zbývá už jen"
                                date={loyalty.rewards.available[0].endDate}
                                dateFormat="YYYY-MM-DD"
                              />
                            </div>
                            <div className="col col--md--6">
                              <div className="reward__more">
                                {sanitizeHtml(
                                  content[
                                    'content.loyalty.rewards.banner_gift.description'
                                    ],
                                  {
                                    allowedTags: [],
                                    allowedAttributes: [],
                                  },
                                )}
                              </div>
                            </div>
                          </div>

                          {this.state.selectedRewardKey !== null && (
                            <div className="reward-selection-alert">
                              <strong>Upozornění:</strong> po aktivaci si již
                              nebudete moci změnit odměnu
                            </div>
                          )}

                          <div className="row">
                            {Array.isArray(subrewards) &&
                            subrewards.map((reward) => (
                              <div
                                key={reward.id}
                                className={`col col--md--4`}
                              >
                                <Subreward
                                  reward={reward}
                                  chooseReward={this.chooseReward}
                                  selected={this.state.selectedRewardKey}
                                  disabled={
                                    this.state.selectedRewardKey !== reward.id
                                  }
                                />
                              </div>
                            ))}
                          </div>
                          {this.state.selectedRewardKey !== null && (
                            <>
                              <div className="form__row form__row--gdpr">
                                <div className="form__item__group form__item__group--checkbox">
                                  <label className="checkbox">
                                    <input
                                      type="checkbox"
                                      onClick={(e) => {
                                        this.setState({
                                          acknowledgedReward:
                                          e.currentTarget.checked,
                                        })
                                      }}
                                    />
                                    <div
                                      className="acknowledgment__box"
                                      dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                          content[
                                            'content.loyalty.rewards.acknowledgment'
                                            ],
                                          {
                                            allowedTags: ALLOWED_TAGS,
                                            allowedAttributes: ALLOWED_ATTRIBUTES,
                                          },
                                        ),
                                      }}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="text--center">
                                <button
                                  disabled={!this.state.acknowledgedReward}
                                  className="btn btn--primary btn--big"
                                  onClick={() => this.submitReward('TEMP')}
                                >
                                  Aktivovat vybranou odměnu
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {this.state.selectedReward &&
                      this.state.selectedReward.type === 'AMENDMENT' && (
                        <>
                          {this.state.step === 0 && (
                            <>
                              {this.state.selectedReward.wizard.step0.type ===
                              'pricelist' && (
                                <>
                                  <h2>
                                    {sanitizeHtml(
                                      content[
                                        'content.loyalty.rewards.pricelist.heading'
                                        ],
                                      {
                                        allowedTags: [],
                                        allowedAttributes: [],
                                      },
                                    )}
                                  </h2>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(
                                        content[
                                          'content.loyalty.rewards.pricelist.description'
                                          ],
                                        {
                                          allowedTags: ALLOWED_TAGS,
                                          allowedAttributes: ALLOWED_ATTRIBUTES,
                                        },
                                      ),
                                    }}
                                  />
                                  <LoyaltySummary
                                    data={
                                      this.state.selectedReward.wizard.step0
                                        .data
                                    }
                                  />
                                  <br />
                                  <div className="text--right">
                                    <button
                                      className="btn btn--primary"
                                      onClick={() =>
                                        this.setState({ step: 1 })
                                      }
                                    >
                                      Pokračovat
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {this.state.step === 1 && (
                            <>
                              {this.state.selectedReward.wizard.step1.type ===
                              'pdf' && (
                                <>
                                  <h2>
                                    {sanitizeHtml(
                                      content[
                                        'content.loyalty.rewards.pdf.heading'
                                        ],
                                      {
                                        allowedTags: [],
                                        allowedAttributes: [],
                                      },
                                    )}
                                  </h2>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(
                                        content[
                                          'content.loyalty.rewards.pdf.description'
                                          ],
                                        {
                                          allowedTags: ALLOWED_TAGS,
                                          allowedAttributes: ALLOWED_ATTRIBUTES,
                                        },
                                      ),
                                    }}
                                  />
                                  <div
                                    className="contract no__mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: `<iframe style="border:0; width: 100%;" height="700px" src="data:${this.state.selectedReward.wizard.step1.data.contentType};base64,${this.state.selectedReward.wizard.step1.data.base64}"></iframe>`,
                                    }}
                                  />
                                  <div className="form__row form__row--gdpr">
                                    <div className="form__item__group form__item__group--checkbox">
                                      <label className="checkbox">
                                        <input
                                          type="checkbox"
                                          onClick={(e) => {
                                            this.setState({
                                              acknowledged:
                                              e.currentTarget.checked,
                                            })
                                          }}
                                        />
                                        <div className="acknowledgment__box">
                                          {
                                            this.state.selectedReward.wizard
                                              .step1.data.acknowlegment
                                          }
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="text--right">
                                    <button
                                      className="btn btn--primary"
                                      disabled={!this.state.acknowledged}
                                      onClick={() => {
                                        this.submitReward('FINAL')
                                      }}
                                    >
                                      Dokončit
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {this.state.selectedReward &&
                      (this.state.selectedReward.type === 'CAMPAIGN' ||
                        this.state.selectedReward.type === 'VOUCHER') && (
                        <>
                          <ChosenReward reward={this.state.selectedReward} />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    loyalty: state.loyalty,
    acknowledgment: state.acknowledgments.items[LOYALTY_ACK_ID] || null,
  }
}

export default compose(
  connect(mapStateToProps, {
    requestRewards,
    submitReward,
    loyaltyNextStep,
  }),
  LoyaltyConfirmModalHoc,
  ContractHoc,
)(LoyaltyRewards)
