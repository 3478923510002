import { reset } from 'redux-form'
import { toast } from 'react-toastify'
import React from 'react'

import {
  REQUEST_LOGIN,
  RECEIVE_LOGOUT,
  START_LOADING,
  STOP_LOADING,
} from './types'

import { COOKIE_SELECTED_CONTRACT, USER_TOKEN_KEY } from '../conf/constants'

import { clearCookie, getCookie } from '../utils/functions'

import { instance } from '../conf/axios'

export function requestFirstLoginValidation(values) {
  return function (dispatch) {
    dispatch({
      type: REQUEST_LOGIN,
    })
    return instance
      .post(`sign/validation`, values, { public: true })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => Promise.reject(error))
  }
}

export function requestFirstLogin(values) {
  return function (dispatch) {
    dispatch({
      type: REQUEST_LOGIN,
    })
    return instance
      .post(
        `sign/customer`,
        {
          contract: values.contract,
          email: values.email,
          password: values.pass1,
        },
        { public: true }
      )
      .then((response) => {
        dispatch(reset('first_login_form'))

        // if ('development' === process.env.REACT_APP_EMAIL_MODE) {
        //   var iframe = `<iframe style="border:0; width: 100%;" src="data:text/html;base64,${response.data.preview}"></iframe>`
        //   toast.info(
        //     <div
        //       dangerouslySetInnerHTML={{
        //         __html: iframe,
        //       }}
        //     />,
        //     {
        //       autoClose: false,
        //     }
        //   )
        // }

        return Promise.resolve(response)
      })
      .catch((err) => Promise.reject(err))
  }
}

export function requestRecoverPassword(values) {
  return function (dispatch) {
    dispatch({
      type: REQUEST_LOGIN,
    })
    return instance
      .post(`password/reset`, values, { public: true })
      .then((response) => {
        dispatch(reset('form_password_recovery'))

        if ('development' === process.env.REACT_APP_EMAIL_MODE) {
          var iframe = `<iframe style="border:0; width: 100%;" src="data:text/html;base64,${response.data.preview}"></iframe>`
          toast.info(
            <div
              dangerouslySetInnerHTML={{
                __html: iframe,
              }}
            />,
            {
              autoClose: 12000,
            }
          )
        }

        return Promise.resolve(response.data)
      })
      .catch((err) => Promise.reject(err))
  }
}

export function requestCreatePassword(values) {
  return function (dispatch) {
    dispatch({
      type: REQUEST_LOGIN,
    })

    return instance
      .put(
        'password/reset',
        {
          email: values.email,
          password: values.pass1,
          hash: values.hash,
        },
        { public: true }
      )
      .then((response) => {
        dispatch(reset('form_password_create'))
        return Promise.resolve(response.data)
      })
      .catch((err) => Promise.reject(err))
  }
}

export function requestLogout() {
  return async function (dispatch, getState) {
    // const token = localStorage.getItem(USER_TOKEN_KEY)
    const token = getCookie(USER_TOKEN_KEY)
    dispatch({
      type: START_LOADING,
    })
    try {
      const res = await instance.post(`logout?token=${token}`)
      // localStorage.removeItem(USER_TOKEN_KEY)
      clearCookie(USER_TOKEN_KEY)
      clearCookie(COOKIE_SELECTED_CONTRACT)
      dispatch({
        type: RECEIVE_LOGOUT,
      })
      dispatch({
        type: STOP_LOADING,
      })
      clearCookie(USER_TOKEN_KEY)

      return Promise.resolve(res)
    } catch (err) {
      clearCookie(USER_TOKEN_KEY)
      clearCookie(COOKIE_SELECTED_CONTRACT)
      dispatch({
        type: RECEIVE_LOGOUT,
      })
      dispatch({
        type: STOP_LOADING,
      })
      clearCookie(USER_TOKEN_KEY)
      dispatch({
        type: STOP_LOADING,
      })
      return Promise.reject(err)
    }
  }
}

export function requestCall(values) {
  return function (dispatch, getState) {
    return instance
      .post(`customer/call-request`, values, { public: true })
      .then((response) => {
        dispatch(reset('form_call_me_not_logged'))
        return Promise.resolve(response)
      })
      .catch((error) => Promise.reject(error))
  }
}
