import { RECEIVE_TRANSLATION } from '../actions/types'

const INITIAL_STATE = {
  help: {},
  content: {},
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_TRANSLATION: {
      return {
        ...state,
        ...action.payload
      }
    }
    // no default
  }
  return state
}
