import React, { Component, useContext } from 'react'
//import AdminHeader from "../component/admin-header";
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import ContractHoc from '../hoc/contract-hoc'

import AdminContent from '../layout/admin-content'
//import Loading from '../component/loading'

import DigiModalContext from '../context/digi-modal-context'
// import Reward from '../component/reward'

import { requestRewards } from '../actions/loyalty'
// import Loading from '../component/loading'

// import { postHeight } from '../utils/functions'
import sanitizeHtml from 'sanitize-html'

// import imagePartners1 from '../assets/images/image_partners_1.png'
// import imagePartners2 from '../assets/images/image_partners_2.png'
// import imagePartners3 from '../assets/images/image_partners_3.png'
// import imagePartnersPhilips from '../assets/images/image_partners_philips.jpg'
// import imagePartnersFitcann from '../assets/images/image_partners_fitcann.png'
// import imagePartnersHopSport from '../assets/images/image_partners_hop_sport.jpg'
// import imagePartnersAlbi from '../assets/images/image_partners_albi.jpg'
// import imagePartnersFeedo from '../assets/images/image_partners_feedo.jpg'
// import imagePartnersLogo1 from '../assets/images/image_partners_1_logo.png'
// import imagePartnersLogo2 from '../assets/images/image_partners_2_logo.png'
// import imagePartnersLogo3 from '../assets/images/image_partners_3_logo.png'
// import imagePartnersPhilipsLogo from '../assets/images/image_partners_philips_logo.png'
// import imagePartnersFitcannLogo from '../assets/images/image_partners_fitcann_logo.png'
// import imagePartnersHopSportLogo from '../assets/images/image_partners_hop_sport_logo.png'
// import imagePartnersAlbiLogo from '../assets/images/image_partners_albi_logo.png'
// import imagePartnersFeedoLogo from '../assets/images/image_partners_feedo_logo.png'
import { postHeight } from '../utils/functions'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS, BANNER_REQUIRED_PARAMS, LOYALTY_ACK_ID } from '../conf/constants'
import LoyaltyConfirmModalHoc from '../hoc/loyalty-confirm-modal-hoc'

const PartnerBanner = ({ banner }) => {
  const content = useSelector((state) => state.translation.content)
  const context = useContext(DigiModalContext)
  // const bannerParams = Object.keys(banner)

  return (
    <>
      <div className="loyalty__partners__item">
        <h2 dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content[banner.title], {
            allowedAttributes: ALLOWED_ATTRIBUTES,
            allowedTags: ALLOWED_TAGS,
          }),
        }} />
        <div dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content[banner.image], {
            allowedAttributes: { img: ['src', 'alt'] },
            allowedTags: ['img'],
          }),
        }} />
        <div className="loyalty__partners__desc" dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content[banner.description], {
            allowedAttributes: ALLOWED_ATTRIBUTES,
            allowedTags: ALLOWED_TAGS,
          }),
        }} />
        <button
          className="btn btn--primary"
          onClick={() => {
            context.toggleDigiModal(
              sanitizeHtml(content[banner.content], {
                allowedAttributes: ALLOWED_ATTRIBUTES,
                allowedTags: ALLOWED_TAGS,
              }),
              '',
              true,
            )
          }}
        >
          {content[banner.button] !== undefined ? sanitizeHtml(content[banner.button], {
            allowedAttributes: [],
            allowedTags: [],
          }) : 'Zjistit více'}
        </button>
      </div>
      <div className="loyalty__partners__image" dangerouslySetInnerHTML={{
        __html: sanitizeHtml(content[banner.image_big], {
          allowedAttributes: { img: ['src', 'alt'] },
          allowedTags: ['img'],
        }),
      }} />
    </>
  )
}

class LoyaltyPartners extends Component {
  static contextType = DigiModalContext

  componentDidMount() {
    window.addEventListener('resize', () => {
      postHeight('.loyalty__partners__item h2')
      postHeight('.loyalty__partners__desc')
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      postHeight('.loyalty__partners__item h2')
      postHeight('.loyalty__partners__desc')
    })
  }

  render() {
    postHeight('.loyalty__partners__item h2')
    postHeight('.loyalty__partners__desc')

    const { content } = this.props

    let banners = {}

    const numberRegex = /[0-9]+/

    for (const contentKey in content) {
      if (!content.hasOwnProperty(contentKey)) continue
      if (contentKey.includes('loyalty.partners.banner')) {
        const r = numberRegex.exec(contentKey)
        if (Array.isArray(r)) {
          let type = contentKey.split('.')
          if (typeof banners[r[0]] === 'undefined') {
            banners[r[0]] = {}
          }
          banners[r[0]][type[type.length - 1]] = contentKey
        }
      }
    }

    banners = Object.values(banners).reverse()

    banners = banners.filter((b) => {
      let ok = parseInt(sanitizeHtml(content[b.show], {
        allowedAttributes: [],
        allowedTags: [],
      })) === 1
      const bannerParams = Object.keys(b)
      for (const param of BANNER_REQUIRED_PARAMS) {
        if (!bannerParams.includes(param)) {
          ok = false
          break
        }
      }

      return ok
    })

    return (
      <AdminContent
        advertisementTarget="profile.personal.data.banner"
        title="Věrnostní program"
        subtitle={content['content.loyalty.partners.title']}
        link="/ucet/vernostni-program/prehled"
      >
        <div className="row">
          <div className="col col--is--12 col--big">
            <div className="admin__inner">
              <div className="loyalty__partners">
                <div className="row">
                  {banners.map((banner, i) => (
                    <div className="col col--sm--4" key={i}>
                      <PartnerBanner banner={banner} />
                    </div>
                  ))}
                  {/*<div className="col col--sm--4">*/}
                  {/*  <div className="loyalty__partners__item">*/}
                  {/*    <h2>DATART: Sleva 100 Kč při nákupu nad 1 000 Kč na vše mimo Apple</h2>*/}
                  {/*    <img src={imagePartnersLogo2} alt="" />*/}
                  {/*    <p className="loyalty__partners__desc">*/}
                  {/*      Využijte zvýhodněných cen v oficiálním e-shopu datart.cz*/}
                  {/*    </p>*/}
                  {/*    <button*/}
                  {/*      className="btn btn--primary"*/}
                  {/*      onClick={() => {*/}
                  {/*        this.context.toggleDigiModal(*/}
                  {/*          content['content.loyalty.partners.datart.content'],*/}
                  {/*          '',*/}
                  {/*          true,*/}
                  {/*        )*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      Zjistit více*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  <div className="loyalty__partners__image">*/}
                  {/*    <img src={imagePartners2} alt="" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="col col--sm--4">*/}
                  {/*  <div className="loyalty__partners__item">*/}
                  {/*    <h2>LAMA energy: Chytrá sleva na plyn a elektřinu</h2>*/}
                  {/*    <img src={imagePartnersLogo1} alt="" />*/}
                  {/*    <p className="loyalty__partners__desc">*/}
                  {/*      Ušetřete s Lamou 50 % nákladů na stálou platbu energií.*/}
                  {/*    </p>*/}
                  {/*    <a*/}
                  {/*      href="https://telly.cz/le/"*/}
                  {/*      target="_blank"*/}
                  {/*      rel="noopener noreferrer"*/}
                  {/*      className="btn btn--primary"*/}
                  {/*    >*/}
                  {/*      Zjistit více*/}
                  {/*    </a>*/}
                  {/*  </div>*/}
                  {/*  <div className="loyalty__partners__image">*/}
                  {/*    <img src={imagePartners1} alt="" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="col col--sm--4">*/}
                  {/*  <div className="loyalty__partners__item">*/}
                  {/*    <h2>Internet a mobil: sleva až 200 Kč měsíčně</h2>*/}
                  {/*    <img src={imagePartnersLogo3} alt="" />*/}
                  {/*    <p className="loyalty__partners__desc">*/}
                  {/*      Zkombinujte TV balíček s internetem od Telly a mobilním*/}
                  {/*      tarifem od LAMA mobile a získejte slevu až 200 Kč*/}
                  {/*      měsíčně.*/}
                  {/*    </p>*/}
                  {/*    <a*/}
                  {/*      href="https://telly.cz/kombinace/"*/}
                  {/*      target="_blank"*/}
                  {/*      rel="noopener noreferrer"*/}
                  {/*      className="btn btn--primary"*/}
                  {/*    >*/}
                  {/*      Zjistit více*/}
                  {/*    </a>*/}
                  {/*  </div>*/}
                  {/*  <div className="loyalty__partners__image">*/}
                  {/*    <img src={imagePartners3} alt="" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="col col--sm--4">*/}
                  {/*  <div className="loyalty__partners__item">*/}
                  {/*    <h2>20 % na celý sortiment značky konopné péče Fitcann</h2>*/}
                  {/*    <img src={imagePartnersFitcannLogo} alt="" />*/}
                  {/*    <p className="loyalty__partners__desc">*/}
                  {/*      Fitcann je konopná péče pro sportovce. Bio oleje v nativní formě.*/}
                  {/*    </p>*/}
                  {/*    <button*/}
                  {/*      className="btn btn--primary"*/}
                  {/*      onClick={() => {*/}
                  {/*        this.context.toggleDigiModal(*/}
                  {/*          content['content.loyalty.partners.fitcann.content'],*/}
                  {/*          '',*/}
                  {/*          true,*/}
                  {/*        )*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      Zjistit více*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  <div className="loyalty__partners__image">*/}
                  {/*    <img src={imagePartnersFitcann} alt="" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="col col--sm--4">*/}
                  {/*  <div className="loyalty__partners__item">*/}
                  {/*    <h2>Albi: Sleva 10 % na vše s logem Albi</h2>*/}
                  {/*    <img src={imagePartnersAlbiLogo} alt="" />*/}
                  {/*    <p className="loyalty__partners__desc">*/}
                  {/*      Využijte zvýhodněných cen na Albi produkty*/}
                  {/*    </p>*/}
                  {/*    <button*/}
                  {/*      className="btn btn--primary"*/}
                  {/*      onClick={() => {*/}
                  {/*        this.context.toggleDigiModal(*/}
                  {/*          content['content.loyalty.partners.albi.content'],*/}
                  {/*          '',*/}
                  {/*          true,*/}
                  {/*        )*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      Zjistit více*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  <div className="loyalty__partners__image">*/}
                  {/*    <img src={imagePartnersAlbi} alt="" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="col col--sm--4">*/}
                  {/*  <div className="loyalty__partners__item">*/}
                  {/*    <h2>Feedo.cz: Sleva 10 %</h2>*/}
                  {/*    <img src={imagePartnersFeedoLogo} alt="" />*/}
                  {/*    <p className="loyalty__partners__desc">*/}
                  {/*      Sleva 10% na vše kromě kategorie: pleny a přebalování, mléko a výživa a dárkové poukázky.*/}
                  {/*    </p>*/}
                  {/*    <button*/}
                  {/*      className="btn btn--primary"*/}
                  {/*      onClick={() => {*/}
                  {/*        this.context.toggleDigiModal(*/}
                  {/*          content['content.loyalty.partners.albi.content'],*/}
                  {/*          '',*/}
                  {/*          true,*/}
                  {/*        )*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      Zjistit více*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*  <div className="loyalty__partners__image">*/}
                  {/*    <img src={imagePartnersFeedo} alt="" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    acknowledgment: state.acknowledgments.items[LOYALTY_ACK_ID] || null,
  }
}

export default compose(
  connect(mapStateToProps, { requestRewards }),
  LoyaltyConfirmModalHoc,
  ContractHoc,
)(LoyaltyPartners)
