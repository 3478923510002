import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import sanitizeHtml from 'sanitize-html'

import { renderRadio } from '../../utils/form.js'

import PaymentInkasoForm from '../../form/payment-setup/payment-inkaso.js'
import PaymentSipoForm from '../../form/payment-setup/payment-sipo.js'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../../conf/constants.js'

// const firstCol = (element) => {
//   return (
//     <div className="list__label list__label--payment list__col">{element}</div>
//   )
// }

const printBankNo = (values) => {
  let ret = ''
  if (values.bankPre) {
    ret += `${values.bankPre}-`
  }

  ret += `${values.bankAccount}`

  if (values.bankCode) {
    ret += `/${values.bankCode}`
  }

  return ret
}

class PaymentTypeForm extends Component {
  render() {
    const {
      initialValues = {},
      formData = {},
      toggleDigiModal,
      content,
      help,
    } = this.props

    return (
      <ul className="list list--action align-center with-buttons list--6--3 list--payment-setup">
        <li
          className={`${
            formData.invoiceType === 'sipo' ? 'active' : ''
          } list__row`}
        >
          <div className="list__col list__col--auto">
            <div className="list__row list__row--col--setting list__row--input">
              <div className="list__label list__col list__label--payment">
                <Field
                  name="invoiceType"
                  component={renderRadio}
                  type="radio"
                  value="sipo"
                  placeholder=""
                  label="SIPO"
                  onClick={() =>
                    setTimeout(this.props.changePaymentType('sipo'))
                  }
                />
              </div>

              <div className="list__value list__value--cut list__col">
                {'sipo' === formData.invoiceType && (
                  <span>
                    Číslo SIPO{' '}
                    {formData.sipoValue ? (
                      <span>{formData.sipoValue}</span>
                    ) : (
                      <span className="small-note">(nevyplňeno)</span>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="list__action list__action--icons list__col">
            {'sipo' === formData.invoiceType && (
              <button
                type="button"
                className="btn btn--secondary btn--next"
                onClick={() =>
                  toggleDigiModal(
                    <PaymentSipoForm
                      initialValues={{
                        sipoValue: formData.sipoValue,
                      }}
                      onSubmit={this.props.submitSubForm}
                    />
                  )
                }
              >
                Nastavit
              </button>
            )}
          </div>
        </li>

        <li
          className={`${
            initialValues.invoiceType === 'inkaso' ? 'active' : ''
          } list__row`}
        >
          <div className="list__col list__col--auto">
            <div className="list__row list__row--col--setting list__row--input">
              <div className="list__label list__label--payment list__col">
                <Field
                  name="invoiceType"
                  component={renderRadio}
                  type="radio"
                  value="inkaso"
                  placeholder=""
                  label="Inkaso"
                  onClick={() => this.props.changePaymentType('inkaso')}
                />
              </div>
              <div className="list__value list__value--cut list__col">
                {initialValues && 'inkaso' === initialValues.invoiceType && (
                  <span>
                    Číslo účtu{' '}
                    {formData.bankAccount ? (
                      <span>{printBankNo(formData)}</span>
                    ) : (
                      <span className="small-note">(nevyplňeno)</span>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="list__action list__action--icons list__col">
            {initialValues && 'inkaso' === initialValues.invoiceType && (
              <button
                type="button"
                className="btn btn--secondary btn--next"
                onClick={() =>
                  toggleDigiModal(
                    <PaymentInkasoForm
                      initialValues={{
                        bankPre: formData.bankPre,
                        bankAccount: formData.bankAccount,
                        bankCode: formData.bankCode,
                      }}
                      onSubmit={this.props.submitSubForm}
                    />
                  )
                }
              >
                Nastavit
              </button>
            )}
          </div>
        </li>
        <li
          className={`${
            initialValues.invoiceType === 'pay_gate' ? 'active' : ''
          } list__row list__row--info`}
        >
          <div className="list__label list__label--payment list__col">
            <Field
              name="invoiceType"
              component={renderRadio}
              type="radio"
              value="pay_gate"
              placeholder=""
              label="Pravidelná platba přes platební bránu"
              onClick={() => this.props.changePaymentType('pay_gate')}
            />
          </div>
          <div className="list__action list__col">
            {!this.props.verified ? (
              initialValues &&
              'pay_gate' === initialValues.invoiceType && (
                <>
                  <span>
                    {content['content.invoice.settings.recurrent.info'] &&
                      sanitizeHtml(
                        content['content.invoice.settings.recurrent.info'],
                        { allowedAttributes: [], allowedTags: [] }
                      )}
                  </span>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="link"
                    onClick={() =>
                      toggleDigiModal(
                        help['help.invoice.settings.recurrent.help'] && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                help['help.invoice.settings.recurrent.help'],
                                {
                                  allowedAttributes: ALLOWED_ATTRIBUTES,
                                  allowedTags: ALLOWED_TAGS,
                                }
                              ),
                            }}
                          ></div>
                        )
                      )
                    }
                  >
                    více info
                  </button>
                </>
              )
            ) : (
              <b className="scream--success">Ověřeno</b>
            )}
          </div>
        </li>

        <li
          className={`${
            initialValues.invoiceType === 'other' ? 'active' : ''
          } list__row`}
        >
          <div>
            <div className="list__label list__col">
              <Field
                name="invoiceType"
                component={renderRadio}
                type="radio"
                value="other"
                placeholder=""
                label="Jiné (hotovost, převod)"
                onClick={() => this.props.changePaymentType('other')}
              />
              {/*
                            <label>
                                <Field
                                    name="invoiceType"
                                    component="input"
                                    type="radio"
                                    value="other"
                                />{' '}
                                Jiné (hotovost, převod)
                            </label>
                            */}
            </div>
          </div>
        </li>
      </ul>
    )
  }
}

PaymentTypeForm = reduxForm({
  form: 'form_payment_type',
  touchOnBlur: false,
  //validate,
  enableReinitialize: true,
})(PaymentTypeForm)

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    help: state.translation.help,
  }
}

export default connect(mapStateToProps, {})(PaymentTypeForm)
