import {
  REQUEST_ACKNOWLEDGMENTS,
  RECEIVE_ACKNOWLEDGMENTS,
  TOGGLE_ACKNOWLEDGMENT_CONFIRM,
} from '../actions/types'

const INITIAL_STATE = { loading: false, items: {} }

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_ACKNOWLEDGMENTS:
      return {
        ...state,
        loading: true,
      }

    case RECEIVE_ACKNOWLEDGMENTS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      }

    case TOGGLE_ACKNOWLEDGMENT_CONFIRM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.acknowledgment_id]: {
            ...state.items[action.acknowledgment_id],
            confirmed: action.confirmed,
          },
        },
      }

    // no default
  }
  return state
}
