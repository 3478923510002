import {
  // REQUEST_CONTRACT,
  RECEIVE_CONTRACT,
  SET_CURRENT_CONTRACT,
  // RECEIVE_CONTRACT_FAILED
} from '../actions/types'


const INITIAL_STATE = {
  time: null,
  selected: null,
  former: null,
  ids: [],
  items: {},
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_CONTRACT:
      //const former = state.selected && state.selected !== action.payload ? true : false;
      console.log(action.payload)
      return {
        ...state,
        selected: action.payload,
        //former: changed
      }
    case RECEIVE_CONTRACT:
      const contracts = action.payload.reduce(
        function(acc, cur, i) {
          acc.items[cur.number]
            ? acc.items[cur.number].push(cur.id)
            : (acc.items[cur.number] = [cur.id])
          acc.ids.push(cur.number)
          return acc
        },
        { ids: [], items: {} }
      )

      return {
        ...state,
        ids: contracts.ids,
        items: contracts.items,
      }

    // no default
  }

  return state
}
