import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {
  renderField,
  emailValidation,
  faxValidation,
  phoneValidation,
  //mobileValidation,
  //landlineValidation
} from '../utils/form'

import Loading from '../component/loading'

const ProfileContactUpdateForm = ({ handleSubmit, submitting, text }) => {
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: text }} />
      <form onSubmit={handleSubmit}>
        {submitting && <Loading />}

        <Field id="id" name="id" component="input" type="hidden" />

        <Field id="type" name="type" component="input" type="hidden" />

        <ul className="list list--action align-center list--modal">
          <li className="list__row">
            <div className="list__label list__col">
              <label htmlFor="value">Kontakt</label>
            </div>
            <div className="list__value list__col">
              <Field
                id="value"
                name="value"
                component={renderField}
                type="text"
                placeholder=""
              />
            </div>
          </li>
        </ul>

        <div className="footline">
          <button
            disabled={submitting}
            className="btn btn--primary btn--next btn--block--xs"
            type="submit"
          >
            Dále
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

function validate(values) {
  const errors = {}

  if (!values.value) {
    errors.value = 'Prosím, vyplňte kontakt.'
  }

  if (!values.value) {
    errors.value = 'Prosím, vyplňte kontakt.'
  } else {
    switch (values.type) {
      case 'email':
        errors.value = emailValidation(values.value)
        break
      case 'telephone':
      case 'mobile':
        errors.value = phoneValidation(values.value, values)
        break
      /*
            case 'telephone':
                errors.value = landlineValidation(values.value);
                break;
            case 'mobile':
                errors.value = mobileValidation(values.value);
                break;
            */
      case 'fax':
        errors.value = faxValidation(values.value)
        break

      // no default
    }
  }
  return errors
}

const mapStateToProps = (state) => {
  return {
    currentValues: state.form.form_profile_contact
      ? state.form.form_profile_contact.values
      : {},
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'form_profile_contact',
    touchOnBlur: false,
    validate,
    enableReinitialize: true,
  })
)(ProfileContactUpdateForm)
