import FileSaver from 'file-saver'

import {
  REQUEST_CONTRACT_DOCUMENTS,
  RECEIVE_CONTRACT_DOCUMENTS,
  RECEIVE_CONTRACT_DOCUMENTS_FAILED,
  REQUEST_CONTRACT_DOCUMENT,
  RECEIVE_CONTRACT_DOCUMENT,
  RECEIVE_CONTRACT_DOCUMENT_FAILED,
  SET_MESSAGE,
} from './types'
import { instance } from '../conf/axios'
import { MESSAGE_ERROR } from '../conf/constants'
import { requestLogout } from './login'

export function getContractDocuments(contract_id, page = 1) {
  return async function (dispatch) {
    dispatch({
      type: REQUEST_CONTRACT_DOCUMENTS,
      contract_id: contract_id,
    })

    try {
      const response = await instance.get(
        `customer/contract/${contract_id}/file?page=${page}`
      )
      dispatch({
        type: RECEIVE_CONTRACT_DOCUMENTS,
        payload: response.data.data,
        meta: response.data.meta,
        contract_id: contract_id,
      })
      return Promise.resolve(response.data)
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: error,
        kind: MESSAGE_ERROR,
      })
      dispatch({
        type: RECEIVE_CONTRACT_DOCUMENTS_FAILED,
      })
      if(error.response.status === 500) {
        requestLogout()
        window.location.assign('/')
      }
      return Promise.reject(error)
    }
  }
}

export function downloadDocument(contract_id, document_id) {
  return async function (dispatch, getState) {
    dispatch({
      type: REQUEST_CONTRACT_DOCUMENT,
      contract_id: contract_id,
    })
    try {
      const response = await instance.get(
        `customer/contract/${contract_id}/file/${document_id}`
      )
      const content = response.data.content;
      let data_url =
        'data:' + content.contentType + ';base64,' + content.base64
      fetch(data_url)
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, content.name)
        })
      dispatch({
        type: RECEIVE_CONTRACT_DOCUMENT,
        contract_id: contract_id,
      })
      return Promise.resolve(response)
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: error,
        kind: MESSAGE_ERROR,
      })
      dispatch({
        type: RECEIVE_CONTRACT_DOCUMENT_FAILED,
        contract_id: contract_id,
      })
      if(error.response.status === 500) {
        requestLogout()
        window.location.assign('/')
      }
      return Promise.reject(error)
    }
  }
}