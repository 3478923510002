import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ContractHoc from '../hoc/contract-hoc'
import { Component } from 'react'
import DigiModalContext from '../context/digi-modal-context'
import AdminContent from '../layout/admin-content'
import DocumentsTable from './profile-documents/documents-table'
import { downloadDocument, getContractDocuments } from '../actions/documents'

class ProfileDocuments extends Component {

  constructor(props) {
    super(props)
    this.state = {
      current_page: 1,
      loading: false,
    }
    this.downloadDocument = this.downloadDocument.bind(this)
    this.paginate = this.paginate.bind(this)
  }

  componentDidMount() {
    this.props.getContractDocuments(this.props.contract_id)
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (
  //     (this.props.current_nr !== prevProps.current_nr &&
  //       this.props.contract_nr_items &&
  //       this.props.contract_nr_items[this.props.current_nr]) ||
  //     (this.props.contract_nr_items !== prevProps.contract_nr_items &&
  //       this.props.current_nr &&
  //       this.props.contract_nr_items[this.props.current_nr])
  //   ) {
  //     this.props.getContractDocuments(this.props.contract_id)
  //   }
  //
  //   if (this.props.current_nr !== prevProps.current_nr) {
  //     this.setState({
  //       current_page: 1,
  //     })
  //   }
  // }

  downloadDocument(contract_id, documentId) {
    return this.props.downloadDocument(contract_id, documentId)
  }

  paginate(page) {
    this.props.getContractDocuments(this.props.contract_id, page)
  }

  render() {
    const { content, help } = this.props;

    return (
      <DigiModalContext.Consumer>
        {({ setStyle, toggleDigiModal }) => (
          <AdminContent
            advertisementTarget="profile.settings.banner"
            title="Můj profil"
            subtitle={content['content.profile.documents.title']}
            help={help['help.profile.documents.help']}
            link="/ucet/profil"
          >
            <div className="row row--big">
              <div className="col col--big">
                <div className="admin__inner relative">
                  <DocumentsTable
                    contractNumber={this.props.contractNumber}
                    documents={this.props.documents[this.props.contract_id]}
                    contractId={this.props.contract_id}
                    loading={this.props.loading}
                    paginate={this.paginate}
                    downloadDocument={this.downloadDocument}
                  />
                </div>
              </div>
            </div>
          </AdminContent>
        )}
      </DigiModalContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return {
    help: state.translation.help,
    content: state.translation.content,
    user: state.user,
    contractNumber: state.current_nr.value,
    documents: state.contract_documents || [],
    loading: state.contract_documents.loading,
  }
}

const enhance = compose(
  connect(mapStateToProps, {
    getContractDocuments,
    downloadDocument,
  }),
  ContractHoc,
)

export default enhance(ProfileDocuments)