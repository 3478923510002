import {
  RECEIVE_REWARDS,
  REQUEST_SUBMIT_REWARD_SUCCESS,
  LOYALTY_NEXT_STEP,
  REQUEST_REWARDS, REQUEST_REWARDS_FAILED,
} from '../actions/types'

const INITIAL_STATE = {
  rewards: {
    used: [],
    available: [],
  },
  selectedReward: null,
  loading: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_REWARDS:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_REWARDS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case RECEIVE_REWARDS:
      return {
        ...state,
        rewards: action.payload,
        loading: false,
      }
    case REQUEST_SUBMIT_REWARD_SUCCESS:
      return {
        ...state,
        selectedReward: action.payload,
        loading: false,
      }
    case LOYALTY_NEXT_STEP:
      return {
        ...state,
        step: state.step + 1,
      }

    // no default
  }
  return state
}
