import {
  REQUEST_CONTRACT_PACKAGES,
  RECEIVE_CONTRACT_PACKAGES,
  // RECEIVE_CONTRACT_PACKAGES_FAILED,
} from '../actions/types'

const INITIAL_STATE = {}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_CONTRACT_PACKAGES:
      return {
        ...state,
        loading: true,
      }

    case RECEIVE_CONTRACT_PACKAGES:
      return {
        ...state,
        [action.contract_id]: action.payload,
      }

    // no default
  }
  return state
}
