import React, { Component } from 'react'

import { formatCurrency } from '../../utils/functions'
import login from "../../container/public/login";

class PackageOther extends Component {
  render() {
    const { item, children, modalContent, toggleModal, desc, descTextAction, descActionText, descSuffix } = this.props

    return (
      <div className={'portfolio__content__item'}>
        <div className={'portfolio__content__item__content'}>
          <label className={'checkbox'}>
            {children}
            <span className={'portfolio__content__item__name'}>{item.name}</span>
            {modalContent && (
              <span
                className="portfolio__content__item__info"
                onClick={(e) => {
                  e.preventDefault()
                  toggleModal(modalContent)
                }}
              >
              ?
            </span>
            )}
          </label>
          <div className={'portfolio__content__item__price'}>
            {item.active ? 'Aktivní' : `+ ${formatCurrency(item.priceOriginalVat, 0)}`}
          </div>
          {item.discountVat > 0 && <div>{formatCurrency(item.priceVat, 0)}</div>}
          {/* <button className="btn btn--primary" onClick={selectPackage}>
          Vybrat
        </button> */}
        </div>
        <p className={'portfolio__content__info'}>
          {desc} <u onClick={descTextAction}>{descActionText}</u>
          {descSuffix}
        </p>
      </div>
    )
  }
}

export default PackageOther
