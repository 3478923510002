import React, { useEffect, useState } from 'react'
import Loading from '../../component/loading'
import { dateDiffInDays, formatCurrency, formatDate } from '../../utils/functions'
import Pagination from '../../component/pagination'

const DocumentsTable = ({
  contractNumber,
  documents = {
    ids: [],
    meta: {
      pagination: 0,
    }
  },
  contractId,
  loading = false,
  paginate,
  downloadDocument
}) => {
  const [isLoading, setLoading] = useState(false)

  return (
    <React.Fragment>

      {(loading || isLoading) && <Loading className="ui__loading--fixed" />}
      <div className="info__box info__box--documents section">
        <div className="flex-middle">
          <div>
            <p>
              Seznam dokumentů ke smlouvě č.{' '}
              <strong>{contractNumber}</strong>
            </p>
          </div>
        </div>
      </div>

      <table className="digi-table digi-table--documents">
        <thead>
        <tr>
          <td className="col-1">
            <div className="cell">Datum vytvoření</div>
          </td>
          <td className="col-3">
            <div className="cell">Stáhnout</div>
          </td>
          <td className="col-4">
            <div className="cell">Název dokumentu</div>
          </td>
        </tr>
        </thead>
        <tbody>
        {documents.ids.map((document_id, index) => {
          const document = documents.items[document_id]
          const documentCreated = new Date(document.created_at)

          return (
            <tr
              key={document_id}
              className={`${1 === index % 2 ? 'even' : ''}`}
            >
              <td className="col-1">
                <div className="cell">
                  <span className="cell__label">
                    <b>Datum vytvoření</b>
                  </span>
                  <span className="cell__content slim">
                    {documentCreated.getDate()}/{documentCreated.getMonth() + 1}/{documentCreated.getFullYear()}
                  </span>
                </div>
              </td>
              <td className="col-2">
                <div className="cell">
                  <span className="cell__label">
                    <b>Stáhnout</b>
                  </span>
                  <span className="cell__content">
                    <button
                      type="button"
                      onClick={async () => {
                        setLoading(true)
                        await downloadDocument(contractId, document.id)
                        setLoading(false)
                      }}
                      className="document-pdf relative"
                    >
                      <i className="ico ico-pdf" /> <span>pdf</span>{' '}
                      <i className="ico ico-next ico-next--gray" />
                    </button>
                  </span>
                </div>
              </td>
              <td className="col-4">
                <div className="cell">
                  <span className="cell__label">
                    <b>Název dokumentu</b>
                  </span>
                  <span className="cell__content">
                    {document.name_original}
                  </span>
                </div>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>

      <Pagination pagination={documents.meta.pagination} paginate={paginate} />
    </React.Fragment>
  )
}

export default DocumentsTable;