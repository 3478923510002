import React, { Component } from 'react'
import { connect } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'

import { formatCurrency } from '../../utils/functions'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../../conf/constants'

const OrderSummaryRow = (props) => {
  const { item, type } = props
  return (
    <tr key={item.group} className={'order__total__row'}>
      <td className={'order__total__row__name'}>
        {item.name}
        {type === 'device' && ` (${item.count}x)`}
      </td>
      <td className={'order__total__row__price__outer'}>
        <div
          className={`order__total__row__price ${
            item.discountVat > 0 && ' order__total__row__price--discounted'
          }`}
        >
          {' '}
          {formatCurrency(item.priceOriginalVat, 0)}
        </div>{' '}
        {item.discountVat > 0 && (
          <span className={'order__total__row__discount'}>
            <span>{formatCurrency(item.priceVat, 0)}</span>
          </span>
        )}
      </td>
    </tr>
  )
}

class OrderSummary extends Component {
  render() {
    const {
      new_contract,
      upsell_contract,
      upsell_options,
      toggleModal,
      content,
      help,
      portfolioKey,
      isUpsell,
    } = this.props

    const contract = isUpsell ? upsell_contract : new_contract

    let addedPrice = 0.0;

    // Active devices
    let devicesSummary = upsell_options?.packages?.device ? Object.values(upsell_options.packages.device).filter(item => item.activeCount > 0).map(item => {
      addedPrice += (item.activeCount * item.priceVat);
      return {...item, count: item.activeCount};
    }) : [];

    // Add new devices to summary if present
    const newDevices = contract.packages.device && Object.values(contract.packages.device);
    newDevices.forEach(device => {
      if (devicesSummary.some(d => d.name === device.name)) {
        devicesSummary.map(item => {
          if (item.name === device.name) {
            item.count += device.count;
          }
          return item;
        });
      }
      else {
        devicesSummary.push(device);
      }
    });

    return (
      <table className="order__total">
        <thead>
          <tr>
            <th>
              <h2 className={'order__total__title'}>Souhrn objednávky</h2>
            </th>
            <th>Cena za měsíc</th>
          </tr>
        </thead>
        <tbody>
          {contract.packages.core &&
            Object.values(contract.packages.core).map((item) => (
              <OrderSummaryRow key={item.group} item={item} />
            ))}
          {contract.packages.suppl &&
            Object.values(contract.packages.suppl).map((item) => (
              <OrderSummaryRow key={item.group} item={item} />
            ))}
          {contract.packages.other &&
            Object.values(contract.packages.other).map((item) => (
              <OrderSummaryRow key={item.group} item={item} />
            ))}
          {isUpsell && devicesSummary &&
            devicesSummary.map((item) => (
              <OrderSummaryRow key={item.group} item={item} type="device" />
            ))}

          <tr className={'order__total__row'}>
            <td
              className={'order__total__row__name order__total__row__name--big'}
            >
              Vaše měsíční platba
              <span
                onClick={() =>
                  toggleModal(
                    help[
                      `help.${isUpsell ? 'upsell' : 'new'}.contract.${portfolioKey}.payment.monthly.info`
                    ]
                  )
                }
                className={'portfolio__content__item__info'}
              >
                ?
              </span>
            </td>
            <td
              className={
                'order__total__row__price order__total__row__price--big'
              }
            >
              {formatCurrency(contract.priceVatNextMonth + addedPrice, 0)}
            </td>
          </tr>
          {contract.packages.ppv &&
            Object.values(contract.packages.ppv).filter((item) => item.selected).map((item) => (
              <>
                <tr className="order__total__row">
                  <td className="order__total__row__one-time-payment" colSpan={2}>
                    Jednorázová platba
                      <span
                        onClick={() =>
                          toggleModal(
                            help[
                              `help.${isUpsell ? 'upsell' : 'new'}.contract.payment.ppv.info`
                              ]
                          )
                        }
                        className={'portfolio__content__item__info portfolio__content__item__info--white'}
                      >
                        ?
                      </span>
                  </td>
                </tr>
                <OrderSummaryRow key={item.group} item={item} />
              </>
            ))}
          {contract.priceVat > 0 && (
            <React.Fragment>
              <tr className={'order__total__row order__total__row--gray'}>
                <td
                  className={
                    'order__total__row__name order__total__row__name--final'
                  }
                >
                  {contract.priceVatPeriod && (
                    <p>
                      Za období{' '}
                      {moment(contract.priceVatPeriod.start).format('LL')}{' '}
                      až {moment(contract.priceVatPeriod.end).format('LL')}
                    </p>
                  )}
                  nyní uhradíte{' '}
                  <span
                    onClick={() =>
                      toggleModal(
                        help[
                          `help.new.contract.${portfolioKey}.payment.deposit.modal.info`
                        ]
                      )
                    }
                  >
                    (Proč tato částka?)
                  </span>
                </td>
                <td
                  className={
                    'order__total__row__price order__total__row__price--final'
                  }
                >
                  {formatCurrency(contract.priceVat, 0)}
                </td>
              </tr>
              <tr className={'order__total__row order__total__row--gray'}>
                <td
                  colSpan="2"
                  className={'order__total__text'}
                  // dangerouslySetInnerHTML={{
                  //   __html: sanitizeHtml(
                  //     content[
                  //       `content.new.contract.${portfolioKey}.payment.deposit.info`
                  //     ],
                  //     {
                  //       allowedTags: ALLOWED_TAGS,
                  //       allowedAttributes: ALLOWED_ATTRIBUTES,
                  //     }
                  //   ),
                  // }}
                >
                  <div
                    className="order__total__text__inner"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        content[
                          `content.new.contract.${portfolioKey}.payment.deposit.info`
                        ],
                        {
                          allowedTags: ALLOWED_TAGS,
                          allowedAttributes: ALLOWED_ATTRIBUTES,
                        }
                      ),
                    }}
                  />
                  {/* {' '}
                  <span className="order__total__parent__more__info">
                    (
                    <span
                      className="order__total__more__info"
                      onClick={() =>
                        toggleModal(
                          sanitizeHtml(
                            content[
                              `content.new.contract.${portfolioKey}.payment.deposit.info.modal`
                            ],
                            {
                              allowedTags: ALLOWED_TAGS,
                              allowedAttributes: ALLOWED_ATTRIBUTES,
                            }
                          )
                        )
                      }
                    >
                      více info
                    </span>
                    ).
                  </span> */}
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    )
  }
}

function mapStateToProps(state) {
  return {
    new_contract: state.contract_new.overview || {
      packages: {},
    },
    upsell_contract: state.contract_upsell.overview || {
      packages: {},
    },
    upsell_options: state.contract_upsell.options || {
      packages: {},
    },
    content: state.translation.content,
    help: state.translation.help,
  }
}

export default connect(mapStateToProps, {})(OrderSummary)
