import React, { Component } from 'react'
import { formatCurrency } from '../../utils/functions'

class Device extends Component {
  constructor(props) {
    super(props)
      this.state = {
        value: (!props.isUpsell ? (props.value || 0) : (props.currentThisDeviceTotal ? props.value - props.currentThisDeviceTotal : props.value)),
      }

    this.inc = this.inc.bind(this)
    this.dec = this.dec.bind(this)
    this.setValue = this.setValue.bind(this)
  }

  componentDidMount() {

    if (
      !(this.props.value > 0) &&
      this.props.device &&
      this.props.device.defaultCount &&
      !this.props.isUpsell
    ) {
      // TODO: nenastavovat default value pokud je uz zvolene jine zarizeni
      this.setValue(this.props.device.defaultCount)
      for (let i = 0; i < this.props.device.defaultCount; i++) {
        this.props.inc()
      }
    }

    if (this.props.prevStep && this.props.prevStep === 2 && this.props.presetValueUpsell) {
      this.setValue(this.props.presetValueUpsell)
    }
    if (this.props.presetValue) {
      this.setValue(this.props.presetValue)
    }

  }

  componentDidMount(prevProps, prevState, snapshot) {

    //console.log(this.state.value, this.props.device.name)

    if(this.state.value > 1) {
      this.setValue(this.props.deviceTotal + this.state.value)
    }
    if(this.props.selectedPackage && !this.props.presetValue) {
     this.setValue(this.props.device.defaultCount)
    }

  }

  setValue(val) {
    this.props.change(
      this.props.isUpsell
        ? `device[${this.props.device.group}]`
        : `device[${this.props.selectedPortfolio}][${this.props.device.group}]`,
      val
    )
    this.setState({
      value: val,
    })
    // this.props.resetDeviceTotal(val)
  }

  inc() {
    if (this.props.deviceTotal < this.props.deviceMax) {
      this.setValue(this.state.value + 1)
      this.props.inc()
      // this.props.handleChange(this.props.device.group, this.state.value + 1)
    }
  }

  dec() {
    if (
      ((this.props.currentThisDeviceTotal ? this.props.minValue - this.props.currentThisDeviceTotal : this.props.minValue) <  this.state.value)
    ) {
      this.setValue(this.state.value - 1)
      this.props.dec()
      // this.props.handleChange(this.props.device.group, this.state.value - 1)
    }
  }

  render() {
    const { device, toggleModal, currentThisDeviceTotal, deviceMax, deviceTotal } = this.props
    return (
      <React.Fragment>
        {//console.log(this.props.currentThisDeviceTotal, this.state.value, deviceTotal, device.name)//
        }
        <div className={'portfolio__content__item'}>
          <div className={'portfolio__content__item__name'}>
            {device.name} {device.required && '*'}
            <span
              onClick={toggleModal}
              className={'portfolio__content__item__info'}
            >
              ?
            </span>
          </div>
          <div className={'portfolio__content__item__inner'}>
            <div className={'quantity-picker'}>
              <button
                type="button"
                disabled={device.disabled || (this.props.currentThisDeviceTotal ? (this.state.value === this.props.minValue - this.props.currentThisDeviceTotal) : (this.state.value === this.props.minValue))}
                onClick={this.dec}
                className={
                  'quantity-picker__button quantity-picker__button--dec'
                }
              >
                -
              </button>
              {/*TODO: Find a way to fix it for more hybrids too */}
              <input
                type="text"
                disabled={device.disabled}
                value={`${this.state.value} ks`}
                className={'quantity-picker__input'}
                readOnly
              />
              <button
                type="button"
                disabled={device.disabled || this.state.value > deviceTotal || deviceTotal >= deviceMax}
                onClick={this.inc}
                className={
                  'quantity-picker__button quantity-picker__button--inc'
                }
              >
                +
              </button>
            </div>
            <div className={'portfolio__content__item__price'}>
              +&nbsp;
              {formatCurrency(
                this.state.value > 0 ? device.priceVat * this.state.value : 0,
                0
              )}{' '}
              {device.discountVat > 0 && (
                <div>{formatCurrency(device.discountVat, 0)}</div>
              )}
            </div>
          </div>
        </div>
        {currentThisDeviceTotal && currentThisDeviceTotal > 0 ? (<div className={'portfolio__content__item__price mb-20'}>
          Aktuálně máte {currentThisDeviceTotal} zařízení tohoto typu.
        </div>) : <></>}
      </React.Fragment>
    )
  }
}

export default Device
