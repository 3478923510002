import React, { useContext, useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from "react-redux";
import { isEqual } from 'lodash'
import { renderCheckbox } from '../utils/form'
import AckSC from '../component/AckSC'
import PinConfirmation from '../component/pin-confirmation'
import { useDispatch } from 'react-redux'
import { changeCustomerAcknowledgment } from '../actions/acknowledgments'
import { requestPin } from '../actions/app'
import Loading from '../component/loading'
import DigiModalContext from '../context/digi-modal-context'


const AcknowledgmentForm = ({ acks = [], toggleDigiModal, change }) => {
  const dispatch = useDispatch()
  const context = useContext(DigiModalContext)
  const [acknowledgements, setAcknowledgements] = useState(acks)
  const modalIsOpen = context.state && context.state.open

  useEffect(() => {
    if(!isEqual(acknowledgements, acks)) {
      setAcknowledgements(acks)
    }
  }, [modalIsOpen, acks])


  const [loading, setLoading] = useState(false)

  function submitAcknowledgement (e, ack, acks) {
    const checked = e.target.checked
    setLoading(true)
    if(ack.sms === true) {
      dispatch(requestPin())
        .then(() => {
          toggleDigiModal()
          toggleDigiModal(
            <PinConfirmation
              text={''}
              confirmation={(
                validationPin,
              ) =>
                dispatch(changeCustomerAcknowledgment(
                  ack.id,
                  checked,
                  validationPin,
                ))
                  .then(() => {
                    toggleDigiModal()
                  })
                  .catch(() => {
                  })
              }
            />,
            null, false,
            () => {
              e.target.checked = ack.confirmed
              toggleDigiModal()
            }
          )
        })
        .then(() => {
          setLoading(false)
        })
    } else if (ack.sms === false) {
     dispatch(changeCustomerAcknowledgment(ack.id, checked )).then(() => {
          setLoading(false)
        })
    }
  }

  return (<ul className="list list--6--2 list--action align-center">
      {loading && <Loading />}
      {acknowledgements.map((ack, i) => (
        <li key={i} className="list__row">
          <div className="list__col">
            <AckSC ack={ack}>
              <Field
                id={`acknowledgments[${ack.id}]`}
                name={`acknowledgments[${ack.id}]`}
                component={renderCheckbox}
                // type="checkbox"
                validate={[]}
                value={ack.confirmed}
                checked={ack.confirmed}
                label={ack.description}
                onChange={(e) => submitAcknowledgement(e, ack, acks)}
              />
            </AckSC>
          </div>
        </li>
      ))}
    </ul>
  )
}


export default reduxForm(
  {
    form: 'form_acknowledgment',
  },
)(AcknowledgmentForm)
