import React, { Component } from 'react'
import sanitizeHtml from 'sanitize-html'
import AsyncSelect from 'react-select/lib/Async'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'
import { dtApiText } from './functions'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import cs from 'date-fns/locale/cs'
registerLocale('cs', cs)

const PhoneNumberType = require('google-libphonenumber').PhoneNumberType
const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export const required = (value) => (value ? undefined : 'Vyžadováno')

export const ruianInput = (value, allValues = {}) => {
  if (!value && !allValues.no_address) {
    return 'Prosím, vyplňte toto pole.'
  }
  return false
}

export const mandatory = (value) => {
  if (!value) {
    return 'Prosím, vyplňte toto pole.'
  }
  return false
}

export const sipoValidation = (value) => {
  if (!value) {
    return 'Prosím, vyplňte sipo číslo.'
  } else if (!/^[0-9]{10}$/.test(value)) {
    return 'Prosím, zadejte 10 číslic.'
  }

  return false
}

export const streetValidation = (value, allValues = {}, props) => {
  if (allValues.no_invoice_address) {
    return false
  }

  if (!value) {
    return 'Prosím, vyplňte ulici.'
  }
  return false
}

export const cityValidation = (value, allValues = {}, props) => {
  if (allValues.no_invoice_address) {
    return false
  }

  if (!value) {
    return 'Prosím, vyplňte město.'
  }
  return false
}

export const zipValidation = (value, allValues = {}, props) => {
  if (allValues.no_invoice_address) {
    return false
  }

  if (!/^[0-9]{5}$/.test(value)) {
    return 'Prosím, zadejte 5 číslic.'
  }

  return false
}

export const preBankCodeValidation = (value, allValues = {}, props) => {
  if (value && !/^[0-9]{0,6}$/.test(value) && !allValues.no_invoice_address) {
    return 'Prosím, zadejte maximálně 6 číslic.'
  }

  return false
}

export const bankAccountValidation = (value, allValues = {}, props) => {
  if (!value) {
    return 'Prosím, vyplňte číslo účtu.'
  } else if (/^[0-9]+$/.test(value)) {
    if ('' + value.length > 10) {
      return 'Číslo účtu muže mít maximálně 10 číslic.'
    }
  } else if (!/^[0-9]+$/.test(value)) {
    return 'Prosím, zadejte pouze číslice.'
  }

  return false
}

export const bankCodeValidation = (value, allValues = {}, props) => {
  if (!value) {
    return 'Prosím, vyplňte kód banky.'
  }

  /*else if ( !(/^[0-9]{4}$/.test(value)) ){
        return 'Prosím, zadejte 4 číslice.'
    }*/

  return false
}

export const phoneValidation = (value, allValues = {}) => {
  let number
  let isOk = false

  try {
    number = phoneUtil.parse(value)
    if (phoneUtil.isValidNumber(number)) {
      const e164 = phoneUtil.format(number, PNF.E164)
      if (e164.toString() !== value) {
        return `Prosím, zadejte číslo ve správném formátu např. ${e164}`
      }

      isOk = true
      //return false; // phone is ok
    }
  } catch (err) {
    // console.log(err)
  }

  if (!isOk) {
    try {
      number = phoneUtil.parse(value, 'CZ')
      if (
        phoneUtil.isValidNumber(number) &&
        phoneUtil.isValidNumberForRegion(number, 'CZ')
      ) {
        const nationalNumber = number.getNationalNumber()
        if (nationalNumber.toString() !== value) {
          return `Prosím, zadejte číslo ve správném formátu např. ${nationalNumber}`
        }
        isOk = true
        //return false; // phone is ok
      }
    } catch (err) {
      // console.log(err)
    }
  }

  if (isOk && allValues.type) {
    //console.log('number');

    const numberType = phoneUtil.getNumberType(number)

    if (
      'telephone' === allValues.type &&
      PhoneNumberType.FIXED_LINE !== numberType
    ) {
      return `Prosím, zadejte číslo pevné linky.`
    }
    if ('mobile' === allValues.type && PhoneNumberType.MOBILE !== numberType) {
      return `Prosím, zadejte číslo mobilního telefonu.`
    }
  }

  if (!isOk) {
    return `Prosím, zadejte platné telefonní číslo.`
  }
}

export const emailValidation = (value) => {
  if (!/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/g.test(value)) {
    return 'Prosím, zadejte platnou emailovou adresu.'
  }

  return false
}

export const deliveryEmailValidation = (value, allValues) => {
  if (allValues.invoiceDelivery && 'email' !== allValues.invoiceDelivery) {
    return false
  }

  return emailValidation(value)
}

export const faxValidation = (value) => {
  return phoneValidation(value)

  /*
    if( !phoneValidation(value) ){
        return 'Prosím, zadejte platné faxové čislo (9 číslic bez mezery).';
    }

    return false;
    */
}

export const mobileValidation = (value) => {
  return phoneValidation(value)

  /*
    if( !phoneValidation(value) ){
        return 'Prosím, zadejte platné telefonní čislo (9 číslic bez mezery).';
    }

    return false;
    */
}

export const landlineValidation = (value) => {
  return phoneValidation(value)

  /*
    if( !phoneValidation(value) ){
        return 'Prosím, zadejte platné telefonní čislo (9 číslic bez mezery).';
    }

    return false;
    */
}

export const passwordValidation = (value) => {
  if (!value) {
    return false
  }

  if (value.length < 8) {
    return 'Minimální délka hesla je 8 znaků.'
  }

  if (!value.match(/[a-z]/) || !value.match(/[A-Z]/)) {
    return 'Heslo musí obsahovat velká a malá písmena.'
  }

  const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
  if (!format.test(value)) {
    return 'Heslo musí obsahovat alespoň jeden speciální znak.'
  }

  return false
}

export const renderReactAsyncSelect = ({
  input,
  name,
  id,
  onChange,
  loadOptions,
  placeholder,
  isDisabled,
  cacheOptions,
  defaultOptions = [],
  meta: { touched, error },
}) => {
  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <AsyncSelect
        {...input}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          input.onChange(e)
        }}
        onBlur={() => {
          input.onBlur()
        }}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        cacheOptions={true}
        defaultOptions={defaultOptions}
        className="nice-select"
        classNamePrefix="nice-select"
        noOptionsMessage={() => 'Žádné možnosti...'}
        loadingMessage={() => 'Načítání...'}
        styles={{
          singleValue: (provided, state) => ({
            display: state.selectProps.menuIsOpen ? 'none' : 'block',
          }),
        }}
      />
    </div>
  )
}

export const renderRadio = ({
  input,
  label,
  moreInfo,
  id,
  disabled = false,
  customOnChange,
  onClick,
  meta: { touched, error },
}) => {
  const errorClass = touched && error ? 'field-error' : ''
  //const checked = object && object.selected ? true : false;

  let element = ''

  if (customOnChange) {
    element = (
      <input
        {...input}
        id={id}
        disabled={disabled}
        //value="true"
        //checked={ 1 == input.value ? true: false }
        type="radio"
        className={errorClass}
        onClick={(event) => {
          input.onChange(event)
          setTimeout(customOnChange)
        }}
        //onClick={ onClick }
      />
    )
  } else {
    element = (
      <input
        {...input}
        id={id}
        disabled={disabled}
        //checked={ 1 == input.value ? true: false }
        type="radio"
        className={errorClass}
        onClick={onClick}
      />
    )
  }

  return (
    <label className="radio">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      {element}
      {label}{' '}
      {moreInfo && (
        <button
          type="button"
          className="a--arrow green link"
          onClick={moreInfo}
        >
          více info
        </button>
      )}
    </label>
  )
}

export const renderCheckbox = ({
  input,
  label,
  LabelComponent = null,
  moreInfo,
  id,
  disabled = false,
  checked,
  customOnChange,
  onClick,
  meta: { touched, error },
}) => {
  const errorClass = touched && error ? 'field-error' : ''
  //const checked = object && object.selected ? true : false;

  //if (checked) input.checked = checked
  input.checked = undefined

  let element = ''

  if (customOnChange) {
    element = (
      <input
        {...input}
        id={id}
        // checked={input.checked}
        disabled={disabled}
        type="checkbox"
        className={`checkbox ${errorClass}`}
        defaultChecked={!!checked}
        onClick={(event) => {
          input.onChange(event)
          setTimeout(customOnChange)
        }}
        //onClick={ onClick }
      />
    )
  } else {
    element = (
      <input
        {...input}
        id={id}
        disabled={disabled}
        // checked={input.checked}
        type="checkbox"
        className={`checkbox ${errorClass}`}
        onClick={onClick}
        defaultChecked={!!checked}
      />
    )
  }
  return (
    <label className="checkbox">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      {element}
      {LabelComponent && <LabelComponent />}
      {!LabelComponent && (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(label, {
              allowedTags: ALLOWED_TAGS,
              allowedAttributes: ALLOWED_ATTRIBUTES,
            }),
          }}
        />
      )}

      {moreInfo && (
        <button
          type="button"
          className="a--arrow green link"
          onClick={moreInfo}
        >
          více info
        </button>
      )}
    </label>
  )
}

export const renderSelect = ({
  input,
  label,
  id,
  type,
  options,
  objectValue = false,
  blankOption = true,
  meta: { touched, error },
}) => {
  const errorClass = touched && error ? 'field-error' : ''
  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <select id={id} {...input} className={errorClass}>
        {blankOption && <option />}
        {options &&
          options.map((value) => {
            if (objectValue) {
              return (
                <option key={value.value} value={value.value}>
                  {dtApiText(value.label)}
                </option>
              )
            } else {
              return (
                <option key={value} value={value}>
                  {dtApiText(value)}
                </option>
              )
            }
          })}
      </select>
    </div>
  )
}

// TODO: pridat prop pro ikonu helpu
export const renderSelectWithHelp = ({
  input,
  label,
  id,
  type,
  options,
  objectValue = false,
  blankOption = true,
  help,
  meta: { touched, error },
}) => {
  const errorClass = touched && error ? 'field-error' : ''
  return (
    <div className="form__item toggle-input toggle-input--help">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <select
        id={id}
        {...input}
        className={errorClass}
        disabled={options.length <= 1}
      >
        {blankOption && <option />}
        {options &&
          options.map((value) => {
            if (objectValue) {
              return (
                <option key={value.value} value={value.value}>
                  {dtApiText(value.label)}
                </option>
              )
            } else {
              return (
                <option key={value} value={value}>
                  {dtApiText(value)}
                </option>
              )
            }
          })}
      </select>
      <button
        type="button"
        className="toggle-help"
        onClick={() => help()}
        // onMouseOver={() => help()}
      >
        help
      </button>
    </div>
  )
}

// export const renderNumber = ({
//   input,
//   label,
//   id,
//   type,
//   options,
//   meta: { touched, error },
// }) => {
//   const errorClass = touched && error ? 'field-error' : ''
//   return (
//     <div className="form__item">
//       {touched && error && (
//         <span className="validation--message red">{error}</span>
//       )}
//       <button></button>
//       <input id={id} {...input} className={errorClass} />
//     </div>
//   )
// }

export const renderDatePicker = ({
  input,
  label,
  dateFormat,
  maxDate,
  id,
  meta: { touched, error },
}) => {
  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <DatePicker
        name={input.name}
        id={id}
        locale="cs"
        selected={input.value}
        onChange={input.onChange}
        dateFormat={dateFormat}
        showYearDropdown
        dropdownMode="select"
        maxDate={maxDate}
        autoComplete="off"
        placeholderText={label}
      />
    </div>
  )
}

export const renderError = ({ meta: { touched, error } }) => {
  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
    </div>
  )
}

export const renderField = ({
  input,
  label,
  id,
  type,
  autocomplete,
  noWrap,
  meta: { touched, error },
}) => {
  const errorClass = touched && error ? 'field-error' : ''

  if (autocomplete) {
    autocomplete = { autocomplete: 'off', autoComplete: 'off' }
  }

  return (
    <div className={noWrap ? '' : 'form__item'}>
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <input
        {...autocomplete}
        {...input}
        id={id}
        type={type}
        placeholder={label}
        className={errorClass}
      />
    </div>
  )
}

export const renderFieldWithHelp = ({
  input,
  label,
  id,
  type,
  autocomplete,
  meta: { touched, error },
  toggle,
}) => {
  const errorClass = touched && error ? 'field-error' : ''

  if (autocomplete) {
    autocomplete = { autocomplete: 'off', autoComplete: 'off' }
  }

  return (
    <div className="form__item toggle-input toggle-input--help">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <input
        {...autocomplete}
        {...input}
        id={id}
        type={type}
        placeholder={label}
        className={errorClass}
      />
      <button
        type="button"
        className="toggle-help"
        onClick={(e) => {
          e.preventDefault()
          return toggle()
        }}
      >
        help
      </button>
    </div>
  )
}

export const renderPhoneFieldWithHelp = ({
  input,
  label,
  id,
  type,
  autocomplete,
  mask = null,
  meta: { touched, error },
  toggle,
}) => {
  const errorClass = touched && error ? 'field-error' : ''

  if (autocomplete) {
    autocomplete = { autocomplete: 'off', autoComplete: 'off' }
  }

  return (
    <div className="form__item toggle-input toggle-input--help">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <input
        {...autocomplete}
        {...input}
        value={
          '' +
          mask +
          (input.value.length < 3
            ? ''
            : input.value.replace(mask, '').substr(0, 10))
        }
        id={id}
        type={type}
        placeholder={label}
        className={errorClass}
      />
      <button
        type="button"
        className="toggle-help"
        onClick={(e) => {
          e.preventDefault()
          return toggle()
        }}
      >
        help
      </button>
    </div>
  )
}

export const renderPhoneField = ({
  input,
  label,
  id,
  type,
  autocomplete,
  mask = null,
  meta: { touched, error },
  toggle,
}) => {
  const errorClass = touched && error ? 'field-error' : ''

  if (autocomplete) {
    autocomplete = { autocomplete: 'off', autoComplete: 'off' }
  }

  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <input
        {...autocomplete}
        {...input}
        value={
          '' +
          mask +
          (input.value.length < 3
            ? ''
            : input.value.replace(mask, '').substr(0, 10))
        }
        id={id}
        type={type}
        placeholder={label}
        className={errorClass}
      />
    </div>
  )
}

class TooglePassword extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      password: true,
    }
  }

  toggle() {
    this.setState({
      password: !this.state.password,
    })
  }

  render() {
    const buttonclass = this.state.password ? 'visibility-off' : 'visibility-on'

    return (
      <div className="toggle-input form__item">
        {this.state.password ? this.props.password : this.props.text}
        <button
          onClick={this.toggle}
          className={`toggle-password ${buttonclass}`}
          type="button"
        >
          Zobrazit
        </button>
      </div>
    )
  }
}

export const renderTooglePassword = ({
  input,
  label,
  id,
  type,
  meta: { touched, error },
}) => {
  const errorClass = touched && error ? 'field-error' : ''

  const password = (
    <input
      {...input}
      id={id}
      type={type}
      placeholder={label}
      className={errorClass}
    />
  )

  const text = (
    <input
      {...input}
      id={id}
      type="text"
      placeholder={label}
      className={errorClass}
    />
  )

  return (
    <div className="form__item">
      {touched && error && (
        <span className="validation--message red">{error}</span>
      )}
      <TooglePassword password={password} text={text} />
    </div>
  )
}
