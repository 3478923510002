import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PaymentInvoiceTypeForm from './payment-invoice-type'

class PaymentSuccessNotificationForm extends Component {
  render() {
    const {
      formData = {},
      changePaymentConfirmingEmail,
      submitPaymentConfirmingEmail,
      content,
      help,
    } = this.props

    return (
      <ul className='list list--action align-center list--payment-setup'>
        <li className='list__row'>
          <div className='list__col list__col--auto'>
            <div className='list__row list__row--col--setting'>
              <div className='list__label list__col list__label--payment-success'>
                <label htmlFor='address_same' className={'checkbox'}>
                  <Field
                    name='address_same'
                    id='address_same'
                    type='checkbox'
                    component='input'
                    onClick={changePaymentConfirmingEmail}
                    checked={formData.sendPaymentConfirmingEmail}
                  />
                  <span className={'checkbox__label'}>
                    Odesílat e-mail s potvrzením o přijaté platbě
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className='list__action list__action--icons list__col'>
            <button
              type='button'
              className='btn btn--secondary btn--next'
              onClick={submitPaymentConfirmingEmail}
            >
              Změnit
            </button>
          </div>
        </li>
      </ul>
    )
  }
}

PaymentSuccessNotificationForm = reduxForm({
  form: 'form_payment_success_notification',
  touchOnBlur: false,
  //validate,
  enableReinitialize: true,
})(PaymentSuccessNotificationForm)

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    help: state.translation.help,
  }
}

export default connect(mapStateToProps, {})(PaymentSuccessNotificationForm)
