import React from 'react'
import { Link } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../../conf/constants'

const News = ({ inbox = { all: [] } }) => {
  return (
    <div className="admin__inner">
      <h2>Novinky a oznámení</h2>

      {inbox.all['1'] &&
        inbox.all['1'].slice(0, 2).map((message_id) => {
          const message = inbox.items[message_id]

          return (
            <article key={message_id}>
              <time>
                {moment(message.created_at.date).format('L')} <br />
                {moment(message.created_at.date).format('H:m')}
              </time>
              <div className="admin__article__inner">
                <div className="inbox__message__header">
                  <div>
                    <Link to={`/ucet/profil/zprava/${message.id}`}>
                      <span className="inbox__message__header__title">
                        {message.subject}
                      </span>
                    </Link>
                    {message.author && (
                      <span className="inbox__message__header__author">
                        {' '}
                        od {message.author}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(message.description, {
                      allowedTags: ALLOWED_TAGS,
                      allowedAttributes: ALLOWED_ATTRIBUTES,
                    }),
                  }}
                />
              </div>
            </article>
          )
        })}

      <div className="text--center footline">
        <Link className="a--arrow--green" to={'/ucet/profil/zpravy'}>
          Starší zprávy
        </Link>
      </div>
    </div>
  )
}

export default News
