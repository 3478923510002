import React, { Component } from 'react'
//import AdminHeader from "../component/admin-header";
import { connect } from 'react-redux'
import { compose } from 'redux'
import ContractHoc from '../hoc/contract-hoc'
import { requestUserInfo } from '../actions/user'
import {
  requestCreateBillingEmail,
  requestCreateContact,
  requestRemoveContact,
  requestUpdateContact,
  requestUpdateContractAddress,
} from '../actions/profile'

import { customerContactValidate } from '../actions/app'

import {
  getContract,
  getContractInfo,
  getContractDevices,
  getContactTypes,
} from '../actions/admin'

import UserBasicInfo from '../component/profile/user-basic-info'
import UserContacts from '../component/profile/user-contacts'
import UserAddresses from '../component/profile/user-addresses'
import ConfirmationNote from '../component/confirmation-note'

import AdminContent from '../layout/admin-content'
//import Loading from '../component/loading'

import DigiModalContext from '../context/digi-modal-context'
import { dtApiText } from '../utils/functions'

import Loading from '../component/loading'

class Profile extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      //modalMessage: null,
      formSuccessMessage: null,
      loading: false,
    }

    this.removeContact = this.removeContact.bind(this)
    this.createContact = this.createContact.bind(this)
    this.updateContact = this.updateContact.bind(this)
    this.updateAddress = this.updateAddress.bind(this)
  }

  validateContact(hash) {
    this.setState({ loading: true })
    this.props
      .customerContactValidate(hash)
      .then((data) => {
        //{"data":{"id":"f9ab6c30-da48-11e9-a7c7-6f5751fe5015","type":"email","subtype":"info","value":"o2@digi2go.cz"}}
        // this.props.startLoading()
        const existingContactIndex = this.props.user.contacts.findIndex(
          (contact) => contact.id === data.id
        )

        let confirmationText = ''

        confirmationText =
          existingContactIndex !== -1
            ? this.props.content[
                'content.profile.personal.data.change.mail.confirm'
              ] &&
              this.props.content[
                'content.profile.personal.data.change.mail.confirm'
              ]
            : this.props.content[
                'content.profile.personal.data.new.mail.confirm'
              ] &&
              this.props.content[
                'content.profile.personal.data.new.mail.confirm'
              ]

        confirmationText = confirmationText
          .replace('%email%', data.value)
          .replace('%type%', dtApiText(data.type))
          .replace('%subtype%', dtApiText(data.subtype).toLowerCase())

        this.props.requestUserInfo()

        this.context.toggleDigiModal(
          <ConfirmationNote
            text={confirmationText}
            close={this.context.toggleDigiModal}
          />
        )

        this.props.history.replace({ ...this.props.history, hash: false })
      })
      .catch((error) => {
        console.error(error)
      })
      .then(() => {
        this.setState({ loading: false })
      })
  }

  componentDidMount() {
    this.props.getContactTypes()
    this.props.contract_id &&
      this.props.getContractInfo(this.props.contract_id).catch((err) => {
        console.log('chybka')
      })

    const locationState = this.props.location.state || {}

    const { hash } = locationState

    if (hash) {
      this.validateContact(hash)
    } else {
      this.props.requestUserInfo().catch((err) => {
        console.log('chybka')
      })
    }
  }

  updateAddress(values, address_type) {
    return this.props
      .requestUpdateContractAddress(
        this.props.contract_id,
        address_type,
        values
      )
      .then(() => {
        this.props.getContractInfo(this.props.contract_id)
        return true
      })
      .catch((err) => {
        console.log('chybka')
        return false
      })
  }

  async removeContact(id, validationPin) {
    try {
      await this.props.requestRemoveContact(id, {
        validationPin: validationPin,
      })
      this.props.contract_id &&
        this.props.getContractInfo(this.props.contract_id)
      return true
    } catch (error) {
      return false
    }
  }

  createContact(values) {
    let doAction

    if ('email' === values.type && 'billing' === values.subtype) {
      doAction = (values) => {
        if (this.props.contract_nr_ids[0]) {
          return this.props.requestCreateBillingEmail(
            this.props.contract_nr_ids[0],
            values
          )
        }
      }
    } else {
      doAction = (values) => {
        return this.props.requestCreateContact(values)
      }
    }
    doAction = doAction.bind(this)
    return doAction(values).then((response) => {
      this.props.requestUserInfo().catch((err) => {
        console.log('chybka')
      })
      return Promise.resolve(response)
    })
  }

  updateContact(values) {
    let doAction

    if ('email' === values.type && 'billing' === values.subtype) {
      doAction = (values) => {
        return this.props.requestCreateBillingEmail(
          this.props.contract_id,
          values
        )
      }
    } else {
      doAction = (values) => {
        return this.props.requestUpdateContact(values)
      }
    }

    return doAction(values).then((response) => {
      this.props.requestUserInfo().catch((err) => {
        console.log('chybka')
      })
      return Promise.resolve(response)
    })
  }

  render() {
    const { content, help } = this.props
    return (
      <AdminContent
        advertisementTarget="profile.personal.data.banner"
        title="Můj profil"
        subtitle={content['content.profile.personal.data.title']}
        help={help['help.profile.personal.data.help']}
        link="/ucet/profil"
      >
        {((this.props.contract_info[this.props.contract_id] &&
          this.props.contract_info[this.props.contract_id].loading) ||
          this.state.loading) && <Loading />}
        <div className="row profile">
          <div className="col col--is--12 col--xl--12">
            <div className="admin__inner">
              <UserBasicInfo user={this.props.user} />
              <h2>Kontaktní informace</h2>
              <div className="row">
                <div className="col col--is--12 col--md--6">
                  <UserContacts
                    content={content}
                    loading={this.props.loading_contacts}
                    contacts={this.props.user && this.props.user.contacts}
                    contract_info={
                      this.props.contract_info[this.props.contract_id]
                    }
                    contactTypes={this.props.contactTypes}
                    current_nr={this.props.current_nr}
                    updateContact={this.updateContact}
                    createContact={this.createContact}
                    remove={this.removeContact}
                  />
                </div>
                <div className="col col--is--12 col--md--6">
                  <UserAddresses
                    ruianAddress={this.state.ruianAddress}
                    content={content}
                    user={this.props.user}
                    contract={this.props.contract_info[this.props.contract_id]}
                    updateAddress={this.updateAddress}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    help: state.translation.help,
    contactTypes: state.app.contactTypes,
    user: state.user,
    contract_info: state.contract_info,
    loading_contacts: state.user.loading_contacts,
    loading_delete_contacts: state.user.loading_delete_contacts,
    current_nr: state.current_nr.value,
    contract_nr_ids:
      state.contract_nr.items && state.contract_nr.items[state.current_nr.value]
        ? state.contract_nr.items[state.current_nr.value]
        : null,
  }
}

export default compose(
  connect(mapStateToProps, {
    getContactTypes,
    requestUserInfo,
    requestCreateContact,
    requestRemoveContact,
    requestUpdateContact,
    getContract,
    getContractInfo,
    requestUpdateContractAddress,
    getContractDevices,
    requestCreateBillingEmail,
    customerContactValidate,
  }),
  ContractHoc
)(Profile)
