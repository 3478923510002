import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Link } from 'react-router-dom'

import { renderField } from './../utils/form'
import Loading from '../component/loading'
import IntroHeader from '../component/intro-header'

const PasswordRecoveryForm = (props) => {
  const { handleSubmit, submitting, error, content } = props
  return (
    <form onSubmit={handleSubmit}>
      {submitting && <Loading />}

      <IntroHeader label={content['content.login.reset.credential']} />

      {content['content.login.reset.credential.description'] && (
        <div className="intro__text">
          <div className="row">
            <div
              className="col"
              dangerouslySetInnerHTML={{
                __html: content['content.login.reset.credential.description'],
              }}
            />
          </div>
        </div>
      )}

      <div className="form__row">
        <div className="form__item">
          <label htmlFor="email">Váš e-mail:</label>
        </div>
        <div className="form__item">
          <Field
            id="email"
            name="email"
            component={renderField}
            type="text"
            placeholder=""
          />
        </div>
      </div>

      {error && (
        <div className="spacer spacer--20 validation--block alert">{error}</div>
      )}

      <div className="intro__buttons intro__buttons--right">
        <button className="btn btn--primary" type="submit">
          Získat nové heslo
        </button>
      </div>

      <div className="intro__links">
        <Link className="a--arrow--back green intro__links__link" to="/">
          Zpět na přihlášení
        </Link>
      </div>
    </form>
  )
}

function validate(values) {
  const errors = {}

  if (!values.email) {
    errors.email = 'Prosím, vyplňte e-mail.'
  }

  return errors
}

export default reduxForm({
  form: 'form_password_recovery',
  touchOnBlur: false,
  validate,
})(PasswordRecoveryForm)
