//export const API_DOMAIN             = 'api.digi2go.cz';

export const APP_URL = process.env.REACT_APP_URL
export const GOPAY_RETURN_URL = `${APP_URL}overeni/platba`
export const GOPAY_NOTIFY_URL = `${APP_URL}overeni/platba`

export const RUIAN_API_URL =
  'https://www.vugtk.cz/euradin/ruian/rest.py/CompileAddress/text'

export const MESSAGE_ERROR = 'message-error'
export const MESSAGE_SUCCESS = 'message-success'
export const MESSAGE_WARNING = 'message-warning'
export const MESSAGE_INFO = 'message-info'

export const APP_TOKEN_KEY = 'aUESAnHp'
export const USER_TOKEN_KEY = '24QOUso5'

export const LOYALTY_ACK_ID = process.env.REACT_APP_LOYALTY_ACK_ID

export const COOKIE_SELECTED_CONTRACT = 'scsc'

export const ALLOWED_TAGS = [
  'b',
  'i',
  'em',
  'strong',
  'a',
  'p',
  'img',
  'ul',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'button',
  'header',
  'section',
  'article',
  'aside',
  'span',
  'div',
  'br',
  'iframe',
]

export const ALLOWED_ATTRIBUTES = {
  a: ['href', 'title', 'class', 'target', 'rel'],
  img: ['src', 'alt'],
  button: ['type'],
  span: ['class'],
  div: ['class'],
}

export const BANNER_REQUIRED_PARAMS = ['content', 'description', 'image', 'image_big', 'show', 'title']
