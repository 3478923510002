import React, { Component } from 'react'
import { connect } from 'react-redux'

import ServiceUpsellForm from '../form/service-upsell-form'
import { resetDeviceTotal } from '../actions/services'

class ServicesActivationStep1 extends Component {
  // constructor(props) {
  //   super(props)

  //   props.resetDeviceTotal()
  // }

  render() {
    const {
      options,
      portfolios,
      toggleModal,
      content,
      selectedPortfolio,
      selectPortfolio,
      handleServices,
      handleInstallation,
      handleSubmitStep1,
      selectedInstallation,
      selectedPackageCore,
      portfolioKey,
      deviceTotal,
    } = this.props

    return (
      <div className="activation activation--1">
        <ServiceUpsellForm
          options={options}
          portfolios={portfolios}
          selectedPortfolio={selectedPortfolio}
          selectPortfolio={selectPortfolio}
          // upsell={contract_upsell[contract.id]}
          handleServices={handleServices}
          handleInstallation={handleInstallation}
          handleSubmitStep1={handleSubmitStep1}
          // resetUpsell={this.props.resetUpsell}
          toggleModal={toggleModal}
          content={content}
          selectedInstallation={selectedInstallation}
          selectedPackageCore={selectedPackageCore}
          portfolioKey={portfolioKey}
          deviceTotal={deviceTotal}
          formData={this.props.formData}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    // contract_upsell: state.contract_upsell,
    content: state.translation.content,
    // deviceTotal: state.contract_new.deviceTotal,
    // selectedPackageCore: state.contract_new.selectedPackageCore,
    formData: (state.form.service_upsell_form &&
      state.form.service_upsell_form.values) || { device: {} },
  }
}

export default connect(mapStateToProps, { resetDeviceTotal })(
  ServicesActivationStep1
)
