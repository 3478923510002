import { connect } from 'react-redux'
import { compose } from 'redux'
import React, { Component } from 'react'

import {
  getContract,
  //setCurrentContractNr
} from '../actions/admin'

// import { requestRewards } from '../actions/loyalty'

// import AdminBlankLayout from '../layout/admin-blank-layout';
import { Redirect } from 'react-router-dom'

let ContractHoc = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: false,
      }
    }

    async componentDidMount() {
      this.setState({ loading: true })

      try {
        this.props.getContract()
        // await this.props.requestRewards()
      } catch (error) {
        console.log('error while getting contracts')
      } finally {
        this.setState({ loading: false })
      }
    }

    render() {
      const { contract_nr } = this.props
      let pathname
      try {
        pathname = this.props.location.pathname
      } catch (e) {
        pathname = window.location.pathname
      }

      if (Object.values(contract_nr.items).length === 0) {
        console.log(this.props)
        if (
          [
            '/ucet/profil',
            '/ucet/profil/nastaveni',
            '/ucet/profil/zpravy',
          ].includes(pathname)
        ) {
          return (
            <WrappedComponent
              key={1}
              {...this.props}
              contract_id={null}
              contract_nr={null}
              contract={null}
            />
          )
        } else {
          return <Redirect to="/registrace/vyber-sluzby" />
        }
      } else {
        return (
          <div>
            {contract_nr.items[contract_nr.selected] &&
            contract_nr.items[contract_nr.selected].map((contract_id) => (
              <WrappedComponent
                key={contract_id}
                {...this.props}
                contract_id={contract_id}
                contract_nr={contract_nr}
                contract={this.props.contracts[contract_id]}
              />
            ))}
          </div>
        )
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contract_nr: state.contract_nr,
    contracts: state.contract.items,
  }
}

export default compose(
  connect(mapStateToProps, {
    getContract,
    // requestRewards,
  }),
  ContractHoc,
)
