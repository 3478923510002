import FileSaver from 'file-saver'
import sanitizeHtml from 'sanitize-html'
import { useSelector } from 'react-redux'
import React from 'react'

export function isSandbox(url) {
  if (!url) return
  return /sandbox/.test(url)
}

export const dtApiText = (value) => {
  const texts = {
    phone: 'Pevná linka',
    email: 'E-mail',
    mobile: 'Mobilní telefon',
    base: 'Kontaktní',
    billing: 'Fakturační',
    sales_offer: 'Obchodní nabídky',
    sales_info: 'Obchodní informace',
    //'Other'         : 'Jiné',
    personal: 'Fyzická osoba',
    company: 'Právnícká osoba',
    telephone: 'Pevná linka',
    fax: 'Fax',
    info: 'Obchodní informace',
    other: 'Jiné',
    paid: 'uhrazeno',
    pending: 'neuhrazeno',
    processing: 'čekající',
    storno: 'storno',
    password: 'heslo',
    'Validation error': 'Chyba validace',
    validationPin: 'Ověřovací PIN kód',
    'Chybný SMS PIN!': 'Ověřovací PIN není správný.',
    'Invalid PIN': 'Ověřovací PIN není správný.',
    'The validation pin must be a string.': 'Ověřovací PIN musí být číslo.',
    'The validation pin field is required.': 'Ověřovací PIN není vyplněn.',
    contract: 'Smlouva',
    'The selected contract is invalid.':
      'Nastal problém při ověřování čísla smlouvy. Prosím kontaktujte zákaznickou linku Telly.',
  }

  return texts[value] ? texts[value] : value
}

export function formatCurrency(price = 0, fractionDigits = 2) {
  return price.toLocaleString('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

export function formatDate(date_string) {
  let date = new Date(date_string)
  return date.toLocaleDateString('cs-CZ')
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24
export function dateDiffInDays(a, b) {
  var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export function formatPhone(number) {
  return number.replace(/([0-9]{3})/gi, '$1 ').trim()
}

export function findContactBaseEmail(contacts = []) {
  const found = contacts.find((item) => {
    return item.type === 'email' && item.subtype === 'base'
  })
  return found ? found.value : null
}

export function findContactBaseMobile(contacts = []) {
  const found = contacts.find((item) => {
    return item.type === 'mobile' && item.subtype === 'base'
  })
  return found ? found.value : null
}

export function downloadFile(file = null) {
  let data_url = 'data:' + file.contentType + ';base64,' + file.base64
  fetch(data_url)
    .then((res) => res.blob())
    .then((blob) => {
      FileSaver.saveAs(blob, file.name)
    })
}

export function isGTMEnabled() {
  return process.env.REACT_APP_USE_GTM === 'true'
}

export function isUpsellEnabled() {
  return process.env.REACT_APP_UPSELL_ENABLED === 'true'
}

export function apiOverrideEnabled() {
  return process.env.REACT_APP_API_URL_OVERRIDE === 'true'
}

export function ParseContent({
  ckey,
  showEmpty = true,
  filterHtml = false,
  allowedAttributes,
  allowedTags,
}) {
  const content = useSelector((state) => state.translation.content)
  let output
  // ckey = `content.${ckey}`

  if (content[ckey] === undefined) {
    if (showEmpty) return <span dangerouslySetInnerHTML={{ __html: ckey }} />
    return null
  }

  if (filterHtml) {
    // let aat = []
    // let ata = []
    // if (
    //   options.hasOwnProperty('allowedAttributes') &&
    //   Array.isArray(options.allowedAttributes)
    // ) {
    //   aat = options.allowedAttributes
    // }
    // if (
    //   options.hasOwnProperty('allowedTags') &&
    //   Array.isArray(options.allowedTags)
    // ) {
    //   ata = options.allowedTags
    // }
    output = sanitizeHtml(content[ckey], {
      allowedAttributes: allowedAttributes,
      allowedTags: allowedTags,
    })
  } else output = content[ckey]

  return <span dangerouslySetInnerHTML={{ __html: output }} />
}

export function parseContent(raw, options = {}) {
  if (raw === undefined) return ''

  if (options.hasOwnProperty('html') && options.html) {
    let aat = {}
    let ata = []
    if (
      options.hasOwnProperty('allowedAttributes') &&
     (typeof options.allowedAttributes === 'object')
    ) {
      aat = options.allowedAttributes
    }
    if (
      options.hasOwnProperty('allowedTags') &&
      Array.isArray(options.allowedTags)
    ) {
      ata = options.allowedTags
    }
    return sanitizeHtml(raw, { allowedAttributes: aat, allowedTags: ata })
  }

  return raw
}

export function writeCookie(cname, cvalue, days) {
  let dt, expires
  dt = new Date()
  dt.setTime(dt.getTime() + days * 24 * 60 * 60 * 1000)
  expires = '; expires=' + dt.toGMTString()
  document.cookie =
    cname +
    '=' +
    cvalue +
    expires +
    '; path=/; domain=' +
    process.env.REACT_APP_DOMAIN
}

export function getCookie(cname) {
  let cookies = document.cookie.split(';')
  cookies = cookies.map((cookie) => {
    cookie = cookie.split('=')
    cookie[0] = cookie[0].trim()
    return cookie
  })
  cookies = cookies.filter((val) => val[0] === cname)

  return cookies.length === 1 && cookies[0][1] !== undefined
    ? cookies[0][1]
    : null
}

export function clearCookie(name) {
  writeCookie(name, '', -1)
}

export function postHeight(selector) {
  const elements = document.querySelectorAll(selector)
  let maxHeight = 0
  elements.forEach((el) => {
    if (el.offsetHeight > 0 && maxHeight < el.offsetHeight) {
      maxHeight = el.offsetHeight
    }
  })
  elements.forEach((el) => {
    el.style.minHeight = maxHeight + 'px'
  })
}
