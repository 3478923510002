//import { connect } from 'react-redux';
//import { compose } from 'redux';
import React, { Component } from 'react'

import Modal from 'react-modal'
import { modalStyleTiny } from '../utils/modal-style'

import ModalLogo from '../component/modal-logo'

let LeavingModalHoc = (WrappedComponent) => {
  let leavingCallback
  let proccessCallback

  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        modal: false,
      }
      this.openLeavingModal = this.openLeavingModal.bind(this)
      this.closeLeavingModal = this.closeLeavingModal.bind(this)
      this.confirmLeave = this.confirmLeave.bind(this)
      this.proccess = this.proccess.bind(this)
    }

    callbacks(lCallback = null, pCallback = null) {
      leavingCallback = lCallback
      proccessCallback = pCallback
    }

    openLeavingModal() {
      this.setState({
        modal: true,
      })
    }

    closeLeavingModal() {
      this.setState({
        modal: false,
      })
    }

    confirmLeave() {
      this.closeLeavingModal()
      if (typeof leavingCallback === 'function') {
        leavingCallback()
      }
    }

    proccess() {
      this.closeLeavingModal()
      if (typeof proccessCallback === 'function') {
        proccessCallback()
      }
    }

    render() {
      return (
        <div>
          <Modal
            appElement={document.getElementById('root')}
            style={{
              ...modalStyleTiny,
              content: {
                ...modalStyleTiny['content'],
                maxWidth: '424px',
              },
            }}
            isOpen={this.state.modal}
          >
            <ModalLogo />
            <button className="button-close" onClick={this.closeLeavingModal}>
              zavřít
            </button>

            <div className="intro__text">
              <div className="row">
                <div className="col text--center">
                  <p>Změny nebyly uloženy.</p>
                  <p>Mají se změny uložit nebo si přejete opustit stránku?</p>
                </div>
              </div>
              <div className="row">
                <div className="col text--center">
                  <button
                    onClick={this.confirmLeave}
                    className="btn btn--secondary btn--prev"
                  >
                    Opustit
                  </button>{' '}
                  <button
                    onClick={this.proccess}
                    className="btn btn--primary btn--next"
                  >
                    Uložit
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <WrappedComponent
            openLeavingModal={this.openLeavingModal}
            confirmLeavingModal={this.confirmLeavingModal}
            leavingCallbacks={this.callbacks}
            canLeave={this.state.canLeave}
            setCanLeave={this.setCanLeave}
            {...this.props}
          />
        </div>
      )
    }
  }
}

export default LeavingModalHoc
//export default connect(null, {})(LeavingModalHoc);
